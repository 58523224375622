import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
const APUBasic = ({permissionFlag, mode, apuTypes, error, crudData, onFieldChange, updateErrorField}) => {
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode !== 'add' || !permissionFlag ? true:false}
          options = {apuTypes}
          getOptionLabel={option => option.name}
          id="apuType"
          value={crudData.apu.apuType ? crudData.apu.apuType:null}
          onChange={(e, value) => onFieldChange(e, 'apuType', value,'apu')}
          renderInput={params => <TextField {...params} label="APU Type" onFocus={() => updateErrorField('apuType', '', 'apuError')} required error={error?.apuType ? true : false} helperText={error?.apuType ? error?.apuType : ''} margin="normal" InputLabelProps={{shrink:true}} fullWidth variant="outlined" />}
        />
      </Grid>
      { mode !== 'add' ?
        <Grid item md={4} xs={12}>
          <TextField
            id="serial_number"
            label='Serial Number'
            fullWidth
            margin="normal"
            disabled={mode === 'view' || !permissionFlag? true:false}
            inputProps={{ maxLength: 10 }}
            InputLabelProps={{ shrink: true }}
            value={crudData.apu.serial_number}
            onChange={(e) => { onFieldChange(e, 'serial_number', e.target.value,'apu')}}
            variant="outlined"
          />
        </Grid>:null
      }
      <Grid item md={4} xs={12}>
        <TextField
          required
          id="hours"
          label='TSN'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={crudData.apu.hours}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'hours', e.target.value,'apu'): e.preventDefault()}}
          error={error?.hours ? true : false}
          helperText={error?.hours ? error?.hours : ''}
          onFocus={() => updateErrorField('hours', '', 'apuError')}
          InputProps={{
            endAdornment: <InputAdornment position="end">APU H</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode === 'view' || !permissionFlag? true:false}
            margin="normal"
            id="utilization_as_of_date"
            label="Utilization as of Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            InputLabelProps={{ shrink: true }}
            minDate={crudData.generic.date_of_manufacture ? moment(crudData.generic.date_of_manufacture) : moment().subtract(25, 'years') }
            maxDate={crudData.generic.report_date ? moment(crudData.generic.report_date) : moment()}
            onChange={(data, value) => onFieldChange(value, 'utilization_as_of_date', data ? moment(data).format(backendDateFormat) : data,'apu')}
            value={crudData.apu.utilization_as_of_date}
            error={error?.utilization_as_of_date ? true : false}
            helperText={error?.utilization_as_of_date ? error?.utilization_as_of_date : ''}
            onFocus={() => updateErrorField('utilization_as_of_date', '','apuError')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Fragment>
  )
}
export default APUBasic;

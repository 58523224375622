import React from 'react';
import moment from 'moment';
import NumericalLabel from 'react-pretty-numbers';
import { Button, Paper, Grid, Tooltip } from '@material-ui/core';
import { DialWidgetCard } from '../../Elements';
import ComponentBreakDown from './ComponentBreakDown';
import { getCurrencyFormat, getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
const HlaOutputwidget = ({ halfLifeResult, halfLifeCrud }) => {
  return (
    <div className="bv-fbv-output">
      <Paper className="output-widget-card">
        <h4 className="flex-centered" style={{ fontSize: '16px', margin:'10px 10px 10px 0px', float:'right' }}>
          Value as of: {moment(halfLifeCrud?.generic?.report_date).format(displayDateFormatShort)}
        </h4>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={halfLifeResult.forecast_date && halfLifeResult.future_base_value ? 6 : 12} xs={12}>
              <DialWidgetCard value={`${halfLifeResult.base_value}`} label="BASE VALUE" unit='m' />
            </Grid>
            {halfLifeResult.forecast_date && halfLifeResult.future_base_value ?
              <Grid item md={6} xs={12}>
                <DialWidgetCard value={`${halfLifeResult.future_base_value}`} label="FUTURE BASE VALUE" unit='m' />
              </Grid> : null
            }
          </Grid>
        </div>
        <div className="widget-inner-wrapper">
          <Grid container spacing={1}>
            <Grid item md={halfLifeResult.forecast_date && halfLifeResult.forecasted_total_adj_hthl ? 6 : 12} xs={12}>
              <DialWidgetCard value={`${halfLifeResult.total_adj_hthl}`} label="MTX ADJ" unit='m' />
            </Grid>
            {halfLifeResult.forecast_date && halfLifeResult.forecasted_total_adj_hthl ?
              <Grid item md={6} xs={12}>
                <DialWidgetCard value={`${halfLifeResult.forecasted_total_adj_hthl}`} label="FUTURE MTX ADJ" unit='m' />
              </Grid> : null
            }
          </Grid>
        </div>
        <div style={{ paddingBottom: '20px', marginBottom: '40px' }}>
          <Grid container spacing={1}>
            <Grid item md={halfLifeResult.forecast_date && halfLifeResult.forecasted_maintenance_adj_bv ? 6 : 12} xs={12}>
              <DialWidgetCard value={`${halfLifeResult.maintenance_adj_bv}`} label="MTX ADJ BASE VALUE" unit='m' />
            </Grid>
            {halfLifeResult.forecast_date && halfLifeResult.forecasted_maintenance_adj_bv ?
              <Grid item md={6} xs={12}>
                <DialWidgetCard value={`${halfLifeResult.forecasted_maintenance_adj_bv}`} label="MTX ADJ FBV" unit='m' />
              </Grid> : null
            }
          </Grid>
        </div>
      </Paper>
      {halfLifeResult.major_assembly && halfLifeResult.major_assembly.length ?
        <Paper className="output-fbv-card flex-centered">
          <div>
            <h4>Component Breakdown</h4>
            <p>
              Total Adjustment Cost:
              <NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{halfLifeResult.major_assembly.reduce((total, mjrComp) => total + parseFloat(mjrComp.cost), 0)}</NumericalLabel> m
            </p>
          </div>
          <ComponentBreakDown halfLifeResult={halfLifeResult} />
        </Paper> : null
      }
    </div>
  )
}
export default HlaOutputwidget;

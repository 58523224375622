import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { checkUserTypeFn, permissionCheckFn } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
import LlpPopUpModal from './LlpPopUpModal';
import { PageLoader } from '../../../shared_elements';
const EngineEvent = ({props, updateEngineField, closeLLLpModal, selectedStaticLLps , pageLoader, error,  item,tabIndex , llpModal, itemIndex, onFieldChange, updateErrorField, crudData, mode, permissionFlag, toggleLLpModal, updateEngineLog }) => {
  return (
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id={`tsn_at_lsv_${itemIndex}`}
          label={`TSN @ Last ${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'}`}
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true : false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.tsn_at_lsv ? item.tsn_at_lsv : ''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'engine', 'engineInfo', itemIndex) : e.preventDefault() }}
          error={error?.tsn_at_lsv ? true : false}
          helperText={error?.tsn_at_lsv ? error?.tsn_at_lsv : ''}
          onFocus={() => updateErrorField('tsn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          required
          id={`csn_at_lsv_${itemIndex}`}
          label={`CSN @ Last ${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'}`}
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true : false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.csn_at_lsv ? item.csn_at_lsv : ''}
          onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'engine', 'engineInfo', itemIndex) : e.preventDefault() }}
          error={error?.csn_at_lsv ? true : false}
          helperText={error?.csn_at_lsv ? error?.csn_at_lsv : ''}
          onFocus={() => updateErrorField('csn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            id={`last_event_date_${itemIndex}`}
            required
            disabled={mode === 'view' || !permissionFlag ? true : false}
            margin="normal"
            label={`Last ${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'} Event Date`}
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={crudData?.airframe?.date_of_manufacture ? moment(crudData.airframe.date_of_manufacture) : moment().subtract(25, 'years')}
            maxDate={item.utilization_as_of_date ? moment(item.utilization_as_of_date) : moment()}
            onChange={(data, value) => onFieldChange(value, 'last_event_date', data ? moment(data).format(backendDateFormat) : data, 'engine', 'engineInfo', itemIndex)}
            value={item.last_event_date ? item.last_event_date : null}
            error={error?.last_event_date ? true : false}
            helperText={error?.last_event_date ? error?.last_event_date : ''}
            onFocus={() => updateErrorField('last_event_date', '')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={4} xs={12}>
        <Autocomplete
          disabled={mode === 'view' || !permissionFlag ? true : false}
          options={item.engine_prsv ? item.engine_prsv : []}
          getOptionLabel={option => option.event}
          id={`last_performed_sv_${itemIndex}`}
          value={item.last_performed_sv ? item.last_performed_sv : null}
          onChange={(e, value) => onFieldChange(e, 'last_performed_sv', value, 'engine', 'engineInfo', itemIndex)}
          renderInput={params => <TextField error={error?.last_performed_sv ? true : false} helperText={error?.last_performed_sv ? error?.last_performed_sv : ''} onFocus={() => updateErrorField('last_performed_sv', '')} {...params} label={`Last Performed ${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'}`} margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
        />
      </Grid>

      {checkUserTypeFn() && permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ?
        <Fragment>
          <Grid item md={4} xs={12}>
            <TextField
              required
              id={`interval_fh_${itemIndex}`}
              label={`${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'} Interval`}
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true : false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.interval_fh ? item.interval_fh : ''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'interval_fh', e.target.value, 'engine', 'engineInfo', itemIndex) : e.preventDefault() }}
              error={error?.interval_fh ? true : false}
              helperText={error?.interval_fh ? error?.interval_fh : ''}
              onFocus={() => updateErrorField('interval_fh', '')}
              InputProps={{
                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              required
              id={`cost_${itemIndex}`}
              label={`${crudData.airframe.aircraftType.has_propeller ? 'OVH' : 'PRSV'} Cost`}
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true : false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.cost ? item.cost : ''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'cost', e.target.value, 'engine', 'engineInfo', itemIndex) : e.preventDefault() }}
              error={error?.cost ? true : false}
              helperText={error?.cost ? error?.cost : ''}
              onFocus={() => updateErrorField('cost', '')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id={`remarks_${itemIndex}`}
              label={`${crudData.airframe.aircraftType.has_propeller ? 'OVH Remarks' : 'Remarks'}`}
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true : false}
              inputProps={{ maxLength: 250 }}
              InputLabelProps={{ shrink: true }}
              value={item.remarks ? item.remarks : ''}
              onChange={(e) => onFieldChange(e, 'remarks', e.target.value, 'engine', 'engineInfo', itemIndex)}
              error={error?.remarks ? true : false}
              helperText={error?.remarks ? error?.remarks : ''}
              onFocus={() => updateErrorField('remarks', '')}
              variant="outlined"
              multiline={true}
            />
          </Grid>
        </Fragment> : null
      }
      { pageLoader ? <PageLoader /> : null }
      <Grid item sm='12'>
        <Button variant="outlined" color="primary" size="small" onClick={() => toggleLLpModal()}>
          {mode == 'edit' ? 'Edit LLP' : 'View LLP'}
        </Button>
      </Grid>
      {
        llpModal ?
        <LlpPopUpModal 
        props={props}
        updateEngineLog={updateEngineLog}
        component='overall'
        closeLLLpModal={closeLLLpModal}
        selectedStaticLLps={selectedStaticLLps}
        hlaLogDetail={crudData}
        updateEngineField={updateEngineField}
        engineIndex={itemIndex}
        enginesEdit={mode == 'edit'? true:false}
        tabIndex={tabIndex}
        engine={item}
        />
        : null
      }
    </Fragment>
  )
}
export default EngineEvent;

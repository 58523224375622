import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey } from '../../utils';
import moment from 'moment';
import { hlaErrorCode, errorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';
// LOG LIST SECTION
export function paymentCheckoutApi(props, paymentMethod, data) {
  let payload = {
    item_details: data.purchaseItems,
    address_id: data.address_id,
    address_detail: data.address_detail,
    id: paymentMethod.id,
    is_address: data.is_address
  };
  globalPostService(`valuation-license/payment-checkout/`, payload)
    .then(response => {
      if (checkApiStatus(response)) {

      }
    })
}
// get log list
export function getValuationLogsApi(props = {}, query = {}, loaderType = 'pageLoader') {
  let queryData = query
  this.setState({ [loaderType]: true });
  globalGetService(`valuation/logs/`, queryData)
    .then(response => {
      this.setState({ [loaderType]: false });
      if (checkApiStatus(response)) {
        delete query.per_page;
        delete query.sort;
        delete query.sort_by;
        delete query.page;
        this.setState({
          valuationLogs: response.data.data,
          filter: query
        })
      }
    })
}
export function getLogsSummaryApi() {
  return new Promise(function (resolve, reject) {
    globalGetService(`valuation-license/account-summary/`)
      .then(response => {
        resolve(response);
      })
  })

}
// get a log
export function getValuationLogApi(props = {}, id, query = {}) {
  return new Promise(
    function (resolve, reject) {
      globalGetService(`valuation/logs/${id}/`, query)
        .then(response => {
          resolve(response);
        })
    }
  )
}
// log lock
export function updateLockApi(props = {}, data = {}) {
  this.setState({ pageLoader: true })
  globalPutService(`valuation/update-lock/`, data)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        if (props.location.pathname.includes('intermediate')) {
          this.getHlaLogApi(props)
        } else if (props.location.pathname.includes('future-value-projection') || props.location.pathname.includes('base-value')) {
          this.getValuationLogApi(props, props.match.params.id)
          window.location.reload(false)
        } else if (props.location.pathname.includes('output')) {
          this.getHlaOutputDataApi(props)
        } else {
          this.getValuationLogsApi(props, {}, 'pageLoader')
        }
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({
          airframeEdit: false,
          enginesEdit: false,
          apuEdit: false,
          landingGearEdit: false,
          forecastEdit: false,
          propellerEdit: false,
          lockEdit: true
        })
        trackActivity(data.is_locked === true ? 'Log Locked' : 'Log Unlocked', { page_title: props.location.pathname.includes('intermediate') ? 'Intermediate' : 'Log Listing', log_id: data.log_id })
      } else {
        trackActivity('Log Lock update Failed', { page_title: props.location.pathname.includes('intermediate') ? 'Intermediate' : 'Log Listing', log_id: data.log_id, permission: 'Denied' })
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
// export log
export function exportLogListApi(props = {}, query = {}) {
  this.setState({ pageLoader: true})
  globalExportService(`valuation/logs/`, { download: query.extension })
    .then(response => {
      this.setState({ pageLoader: false })
      this.props.enqueueSnackbar('Log file will be sent to your registered email', { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      trackActivity('Log Exported', {
        type: 'Logs',
        extension: query.extension
      })
    })
}

// BASE VALUE OR FUTURE VALUE
// Create base value or future value
export function createValuationLogApi(props = {}, data = {}, utilsTitle) {
  let payload = data
  payload = {
    ...payload,
    escalation_percentage: data.escalation_percentage && parseFloat(data.escalation_percentage)
  }
  if (utilsTitle.includes('Future')) {
    payload = {
      ...payload,
      projection_from: moment().format(backendDateFormat)

    }
  }
  this.setState({ pageLoader: true });
  globalPostService(`valuation/initiate/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        if (utilsTitle.includes('Future')) {
          trackActivity('Log Generated', {
            type: 'Future Value',
            log_id: response.data.data.log_id
          })
          props.history.push(`/future-value-projection/${response.data.data.log_id}`);
        } else {
          props.history.push(`/base-value/${response.data.data.log_id}`);
          trackActivity('Log Generated', {
            type: 'Base Value',
            log_id: response.data.data.log_id
          })
        }
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        trackActivity('Log Generation Failed', {
          source: 'backend'
        })
      }
    })
}
//  Update base value  or future value
export function updateValuationLogApi(props = {}, data = {}) {
  let payload = data
  payload = {
    ...payload,
    escalation_percentage: data.escalation_percentage && parseFloat(data.escalation_percentage)
  }
  this.setState({ pageLoader: true });
  globalPutService(`valuation/logs/${this.props.match.params.id}/`, payload)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        if (props.location.pathname.includes('base-value')) {
          props.history.push(`/base-value/${this.props.match.params.id}`);
          trackActivity('Log Edited', {
            type: 'Base Value',
            log_id: props.match.params.id
          })
        } else {
          props.history.push(`/future-value-projection/${this.props.match.params.id}`);
          trackActivity('Log Edited', {
            type: 'Future Value',
            log_id: props.match.params.id
          })
        }
      } else {
        trackActivity('Log Edit Failed', {
          source: 'backend',
        })
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      }
    })
}
// export base value
export function exportBaseValuationLogApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`valuation/logs/${this.props.match.params.id}/`, { download: query.extension, utility_type: 'base_value' })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      trackActivity('Report Exported', {
        type: 'Base Value',
        log_id: this.props.match.params.id,
        file_type: query.extension,
      })
      downloadFileType(response.data, (`${this.props.match.params.id}_Base_Value_${this.state.valuationLog.aircraft_type.name}_${moment().format('YYYY-MM-DD')}`), query.extension);
    })
}
// export future value
export function exportFutureValuationLogApi(props = {}, query = {}) {
  this.setState({ pageLoader: true });
  globalExportService(`valuation/logs/${this.props.match.params.id}/`, { download: query.extension, type: query.key, utility_type: 'future_value' })
    .then(response => {
      this.setState({ pageLoader: false, query: {}, type: '' });
      trackActivity('Report Exported', {
        type: 'Future Value',
        log_id: this.props.match.params.id,
        file_type: query.extension,
        report_type: query.key
      })
      downloadFileType(response.data, (`${this.props.match.params.id}_Future_Value_${this.state.valuationLog.aircraft_type.name}_${query.key}_${moment().format('YYYY-MM-DD')}`), query.extension);
    })
}

// COMMON FUNCTIONS
// get aircraft types
export function getValuationAircraftsApi(props = {}, query = {}) {
  return new Promise(
    function (resolve, reject) {
      globalGetService(`console/aircraft-types/`, { valuation: 'valuation' })
        .then(response => {
          resolve(response);
        })
    }
  )
}
// get Mods
export function getAircraftModsApi(props = {}, query = {}) {
  globalGetService(`valuation/mods/`, query)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({ mods: response.data.data.mods });
      }
    })
}
// get maintenance Events
export function getMntEventsApi(props = {}, aircraftTypeId) {
  globalGetService(`console/aircraft-type/${aircraftTypeId}/events/`, { module: 'valuation' })
    .then(response => {
      if (checkApiStatus(response)) {
        let maintanenceList = [];
        const data = response.data.data;
        Object.keys(data).map((item, index) => {
          maintanenceList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
        });
        this.setState({ mntGroups: maintanenceList });
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}

export function getEnginePRSVEventsApi(props, aircraftTypeId, engineTypeId){
  return new Promise(function(resolve, reject){
    globalGetService(`valuation/get-engine-sv-events/${aircraftTypeId}/${engineTypeId}/`)
    .then(response => {
      resolve(response)
    })
  })

}
// get seating configuration
export function getSeatingConfigApi() {
  globalGetService('console/get-constants/?constant_type=seating_configuration ')
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState({
          seatingConfigList: response.data.data
        })
      }
    })
}


// MAINTENANCE ADJUSTED BASE VALUE
// Create HTHL log
export function generateHlaLogApi(props = {}, hlaCrud = {}, engineInfoNo) {
  const { futureValueFlag } = this.state
  let data = Object.assign({}, hlaCrud)
  let validationInputs = {}
  // delete data.engine.engineInfo[3]
  // delete data.engine.engineInfo[2]
  if (data.airframe) {
    validationInputs = {
      ...validationInputs,
      serial_number: hlaErrorCode['serial_number'][fieldValidation({ ...hlaErrorCode['serial_numberObj'], fieldval: data.airframe.serial_number })],
      aircraft_type: hlaErrorCode['aircraft_type'][fieldValidation({ ...hlaErrorCode['aircraft_typeObj'], fieldval: data.airframe.aircraftType })],
      maintenance_program: hlaErrorCode['maintenance_program'][fieldValidation({ ...hlaErrorCode['maintenance_programObj'], fieldval: data.airframe.maintenance_program })],
      date_of_manufacture: hlaErrorCode['date_of_manufacture'][fieldValidation({ ...hlaErrorCode['date_of_manufactureObj'], fieldval: data.generic.date_of_manufacture })],
      report_date: hlaErrorCode['report_date'][fieldValidation({ ...hlaErrorCode['report_dateObj'], fieldval: data.generic.report_date })],
      mtow: hlaErrorCode['mtow'][fieldValidation({ ...hlaErrorCode['mtowObj'], fieldval: data.generic.mtow })],
      mtow_kgs: hlaErrorCode['mtow_kgs'][fieldValidation({ ...hlaErrorCode['mtow_kgsObj'], fieldval: data.generic.mtow_kgs })],
      utilization_as_of_dateAircraft: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: data.airframe.utilization_as_of_date })],
      tsnAircraft: hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: data.airframe.tsn })],
      csnAircraft: hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: data.airframe.csn })],
    }
    if (futureValueFlag) {
      validationInputs = {
        ...validationInputs,
        forecast_date: data.generic.report_date >= data.generic.forecast_date ? "Please enter Forecast Date greater than Report Date" : hlaErrorCode['forecast_date'][fieldValidation({ ...hlaErrorCode['forecast_dateObj'], fieldval: data.generic.forecast_date })],
      }
    }
    if (data.airframe.utilization_type == 2) {
      validationInputs = {
        ...validationInputs,
        planned_fh: hlaErrorCode['planned_fh'][fieldValidation({ ...hlaErrorCode['planned_fhObj'], fieldval: data.airframe.planned_fh })],
        planned_fc: hlaErrorCode['planned_fc'][fieldValidation({ ...hlaErrorCode['planned_fcObj'], fieldval: data.airframe.planned_fc })],

      }
    }
  }
  if (data.engine) {
    validationInputs = {
      ...validationInputs,
      engine_type: hlaErrorCode['engine_type'][fieldValidation({ ...hlaErrorCode['engine_typeObj'], fieldval: data.engine.engineType })],

    }
    data.engine.engineInfo.map((info, index) => {
      if (index < engineInfoNo) {
        if (data.engine.identical_data || data.engine.no_of_engines == 1) {
          validationInputs = {
            ...validationInputs,
            engine0: engineInitiateValidatioFn(data.engine.engineInfo[0], index)
          }
        } else {
          validationInputs = {
            ...validationInputs,
            engine0: engineInitiateValidatioFn(data.engine.engineInfo[0], index),
            engine1: engineInitiateValidatioFn(data.engine.engineInfo[1], index)
          }
        }
      }
    })
  }
  if (Object.keys(data.apu).length) {
    validationInputs = {
      ...validationInputs,
      apu_hours: hlaErrorCode['apu_hours'][fieldValidation({ ...hlaErrorCode['apu_hoursObj'], fieldval: data.apu.hours })],
      utilization_as_of_dateApu: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: data.apu.utilization_as_of_date })]
    }
  }
  if (data.landing_gear) {
    if (data.landing_gear.lgInfo) {
      if (data.landing_gear.lg_utilization_flag) {
        validationInputs = {
          ...validationInputs,
          0: lgInitiateValidationFn(data.landing_gear.lgInfo[0], 0)
        }
      } else {
        if (data.landing_gear.mlg_utilization_flag) {
          validationInputs = {
            ...validationInputs,
            0: lgInitiateValidationFn(data.landing_gear.lgInfo[0], 0),
            1: lgInitiateValidationFn(data.landing_gear.lgInfo[1], 1),
          }
        } else {
          data.landing_gear.lgInfo.map((info, index) => {
            validationInputs = {
              ...validationInputs,
              [index]: lgInitiateValidationFn(info, index)
            }
          }
          )
        }
      }

    }
  }
  if (data.airframe.aircraftType.has_propeller && Object.keys(data.propeller).length) {
    if (data.propeller.identical_data || data.engine.no_of_engines == 1) {
      validationInputs = {
        ...validationInputs,
        propeller0: propellerInitiateValidatioFn(data.propeller.propellerInfo[0], "index")
      }
    } else {
      validationInputs = {
        ...validationInputs,
        propeller0: propellerInitiateValidatioFn(data.propeller.propellerInfo[0], "index"),
        propeller1: propellerInitiateValidatioFn(data.propeller.propellerInfo[1], "index")
      }
    }
  }
  if ((data.engine && data.engine.engineInfo) && (data.landing_gear && data.landing_gear.lgInfo)) {
    if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
      let payload = Object.assign({}, data)
      // airframe
      if (payload.airframe) {
        payload = {
          ...payload,
          airframe: {
            ...payload.airframe,
            maintenance_program: payload.airframe.maintenance_program ? payload.airframe.maintenance_program.value : '',
            tsn: payload.airframe.tsn ? parseFloat(payload.airframe.tsn) : 0,
            csn: payload.airframe.csn ? parseInt(payload.airframe.csn) : 0,
          },
        }
        if (data.airframe.utilization_type == 2) {
          payload = {
            ...payload,
            airframe: {
              ...payload.airframe,
              planned_fc: parseInt(data.airframe.planned_fc),
              planned_fh: parseFloat(data.airframe.planned_fh)
            }
          }
        }
        if (data.airframe.utilization_type == 1) {
          payload = {
            ...payload,
            airframe: {
              ...payload.airframe,
              planned_fc: null,
              planned_fh: null
            }
          }
        }
        if (payload.airframe.seating_configuration) {
          payload = {
            ...payload,
            airframe: {
              ...payload.airframe,
              seating_configuration: payload.airframe.seating_configuration.value
            }
          }
        }
      }
      // generic
      if (payload.generic) {
        payload = {
          ...payload,
          generic: {
            ...payload.generic,
            mods: payload.generic.mods && payload.generic.mods.length ? payload.generic.mods.map(item => item.id) : [],
            mtow: payload.generic.mtow ? parseFloat(payload.generic.mtow) : '',
          }
        }
        if (payload.generic.lessee_id) {
          payload = {
            ...payload,
            generic: {
              ...payload.generic,
              lessee_id: payload.generic.lessee_id.id
            }
          }
        }
        delete payload['generic'].mtow_kgs
      }
      // engine
      if (payload.engine) {
        if (payload.engine.identical_data) {
          payload = {
            ...payload,
            engine: {
              ...payload.engine,
              engineInfo: payload.engine.engineInfo.filter(item => item.id == 1 ? {
                ...item,
                tsn: parseFloat(item.tsn),
                csn: parseInt(item.csn),
              } : item)
            }
          }
        } else {
          payload = {
            ...payload,
            engine: {
              ...payload.engine,
              engineInfo: payload.engine.engineInfo.filter(item => item.id <= engineInfoNo ? { ...item, tsn: parseFloat(item.tsn), csn: parseInt(item.csn) } : item)
            }
          }
        }
      }
      // apu
      if (Object.keys(payload.apu).length) {
        payload = {
          ...payload,
          apu: {
            ...payload.apu,
            hours: payload.apu.hours ? parseFloat(payload.apu.hours) : 0
          }
        }
      }
      if (!data.airframe.aircraftType.has_propeller) {
        delete payload.propeller
      }
      this.setState({ pageLoader: true })
      globalPostService(`/valuation/half-life-adjustment/initiate/`, payload)
        .then(response => {
          this.setState({ pageLoader: false })
          if (checkApiStatus(response)) {
            props.history.push(`/half-life/intermediate/${response.data.data.id}`)
            trackActivity('Log Generated', {
              type: 'Half Life Adjustment',
              log_id: response.data.data.log_id
            })
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
            trackActivity('Log Generation Failed', {
              source: 'backend'
            })
          }
        })
    } else {
      this.setState({ error: validationInputs })
    }
  } else {
    this.props.enqueueSnackbar('Something went wrong. Please try again )', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
  }
}
// get default value for planned utilization
export function getDefaultValueApi(hlaCrud) {
  globalGetService('valuation/annual-utilization/')
    .then(response => {
      if (checkApiStatus(response)) {
        let data = response.data.data.map(item => item.aircraft_type.aircraft_type_id == hlaCrud.airframe.aircraftType.id ? ({ planned_fc: item.annual_fc_cost, planned_fh: item.annual_fh_cost }) : {})
        if (data.length && data.find(item => Object.keys(item).length)) {
          let filterData = data.find(item => Object.keys(item).length)
          this.setState(prevState => ({
            ...prevState,
            hlaCrud: {
              ...prevState.hlaCrud,
              airframe: {
                ...prevState.hlaCrud.airframe,
                planned_fc: filterData.planned_fc,
                planned_fh: filterData.planned_fh
              }
            }
          }))
        }
      }
    })
}
// get hla log - intermediate
export function getHlaLogApi(props = {}, query = {}) {
  if (query.validatorConstant == 1) {
    this.setState({ pageLoader: true })
    globalGetService(`valuation/half-life-adjustment/logs/${props.match.params.id}/?edit=${query.validatorConstant}`)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          let data = response.data.data
          data = {
            ...data,
            airframe: {
              ...data.airframe,
              maintenance_events: data.airframe.maintenance_events.map(event => ({ ...event, airframeRemarksFlag: false }))
            },
            engine: {
              ...data.engine,
              engineInfo: data.engine.engineInfo.map(item => ({ ...item, engineRemarksFlag: false, engineHsiRemarksFlag: false, llp_life_percentage: item.llp_life_percentage ? item.llp_life_percentage : '' }))
            },
            landing_gear: data.landing_gear.map(item => ({ ...item, landingGearRemarksFlag: false })),
            generic: {
              ...data.generic,
              mtow_kgs: parseFloat(data.generic.mtow * 0.4535970244).toFixed(4),
              mtow: parseFloat(data.generic.mtow).toFixed(4)
            },
          }
          if (Object.keys(response.data.data.propeller).length) {
            data = {
              ...data,
              propeller: data.propeller.map(item => ({ ...item, propellerRemarksFlag: false }))
            }
          }
          if (response.data.data.error) {
            this.setState({
              error: response.data.data.error,
            })
          }
          this.setState({
            hlaLogDetail: data,
          })
          if (data.generic.forecast_date) {
            this.setState({
              forecastDate: data.generic.forecast_date
            })
          }
          if (response.data.data && response.data.data.error && response.data.data.error.airframe) {
            this.setState({ airframeEdit: true })
          }
          if (response.data.data && response.data.data.error && response.data.data.error.engine) {
            this.setState({ enginesEdit: true })
          }
          if (response.data.data && response.data.data.error && response.data.data.error.apu) {
            this.setState({ apuEdit: true })
          }
          if (response.data.data && response.data.data.error && response.data.data.error.lg) {
            this.setState({ lgEdit: true })
          }
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.props.history.push('/logs')
        }
      })
  } else {
    this.setState({ pageLoader: true })
    globalGetService(`valuation/half-life-adjustment/logs/${props.match.params.id}/`)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          let data = response.data.data
          data = {
            ...data,
            airframe: {
              ...data.airframe,
              maintenance_events: data.airframe.maintenance_events.map(item => ({ ...item, airframeRemarksFlag: false }))
            },
            engine: {
              ...data.engine,
              engineInfo: data.engine.engineInfo.map(item => ({ ...item, engineRemarksFlag: false }))
            },
            landing_gear: data.landing_gear.map(item => ({ ...item, landingGearRemarksFlag: false })),
            generic: {
              ...data.generic,
              mtow_kgs: parseFloat(data.generic.mtow * 0.4535970244).toFixed(4),
              mtow: parseFloat(data.generic.mtow).toFixed(4)
            }
          }
          if (Object.keys(response.data.data.propeller).length) {
            data = {
              ...data,
              propeller: data.propeller.map(item => ({ ...item, propellerRemarksFlag: false })),
            }
          }
          this.setState({
            hlaLogDetail: data
          })
          if (data.generic.forecast_date) {
            this.setState({
              forecastDate: data.generic.forecast_date
            })
          }
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.props.history.push('/logs')
        }
      })
  }
}
// update forecast date
export function updateForecastDateApi(props = {}, data = {}) {
  const { hlaLogDetail } = this.state
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    forecast_date: hlaLogDetail.generic.report_date >=
      data.forecast_date ? "Please enter Forecast Date greater than Report Date" : hlaErrorCode['forecast_date'][fieldValidation({ ...hlaErrorCode['forecast_dateObj'], fieldval: data.forecast_date })],
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    this.setState({ pageLoader: true })
    globalPutService(`valuation/half-life-adjustment/generic/${props.match.params.id}/`, data)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          this.setState({ forecastEdit: false })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        generic: validationInputs

      }
    }))
  }


}

// update Airframe log
export function updateAirframeLogApi(props = {}, airframe = {}, generic = {}) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    mtow: hlaErrorCode['mtow'][fieldValidation({ ...hlaErrorCode['mtowObj'], fieldval: generic.mtow })],
    mtow_kgs: hlaErrorCode['mtow_kgs'][fieldValidation({ ...hlaErrorCode['mtow_kgsObj'], fieldval: generic.mtow_kgs })],
    tsnAirframe: hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: airframe.tsn })],
    csnAirframe: hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: airframe.csn })],
    utilization_as_of_date_airframe: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: airframe.utilization_as_of_date })],
  }
  if (airframe.utilization_type == 2) {
    validationInputs = {
      ...validationInputs,
      planned_fh: hlaErrorCode['planned_fh'][fieldValidation({ ...hlaErrorCode['planned_fhObj'], fieldval: airframe.planned_fh })],
      planned_fc: hlaErrorCode['planned_fc'][fieldValidation({ ...hlaErrorCode['planned_fcObj'], fieldval: airframe.planned_fc })],
    }
  }
  airframe.maintenance_events.map((event, index) => {
    validationInputs = {
      ...validationInputs,
      [index]: airframeIntermediateValidationFn(event)
    }
  })
  let payload = {
    log_id: parseInt(props.match.params.id),
    generic,
    airframe
  }
  payload = {
    ...payload,
    airframe: {
      ...payload.airframe,
      csn: payload.airframe.csn ? parseInt(payload.airframe.csn) : 0,
      planned_fc: payload.airframe.planned_fc ? parseInt(payload.airframe.planned_fc) : 0,
      tsn: payload.airframe.tsn ? parseFloat(payload.airframe.tsn) : 0,
      planned_fh: payload.airframe.planned_fh ? parseFloat(payload.airframe.planned_fh) : 0,
      maintenance_events: payload.airframe.maintenance_events.map(item => ({
        ...item,
        tsn_at_lsv: item.tsn_at_lsv ? parseFloat(item.tsn_at_lsv) : 0,
        csn_at_lsv: item.csn_at_lsv ? parseInt(item.csn_at_lsv) : 0,
        lsv_date: item.lsv_date ? item.lsv_date : null
      }))
    }
  }
  if (payload.generic.mods && payload.generic.mods.length) {
    payload = {
      ...payload,
      generic: {
        ...payload.generic,
        mods: payload.generic.mods.map(item => item.id)
      }
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/airframe/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          trackActivity('Airframe Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          this.setState({
            airframeEdit: false
          })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        airframe: validationInputs
      }
    }))
  }
}
// update Engine log
export function updateEngineLogApi(props = {}, engine = {}, dom = null, validationFlag = false) {
  let validationInputs = {}
  if (!validationFlag) {
    engine.engineInfo.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: engineIntermediateValidateFn(item, dom),
      }
    })
  }
  let payload = {
    log_id: parseInt(props.match.params.id),
    engine: engine.engineInfo
  }
  payload = {
    ...payload,
    engine: engine.engineInfo.map((item, index) => ({
      ...item,
      tsn: item.tsn ? parseFloat(item.tsn) : 0,
      tsn_at_lsv: item.tsn_at_lsv ? parseFloat(item.tsn_at_lsv) : 0,
      csn: item.csn ? parseInt(item.csn) : 0,
      csn_at_lsv: item.csn_at_lsv ? parseInt(item.csn_at_lsv) : 0,
      last_performed_sv: item.last_performed_sv ? item.last_performed_sv.id : null,
      cost: item.cost ? parseFloat(item.cost) : 0,
      cost_hsi: item.cost_hsi ? parseFloat(item.cost_hsi) : 0,
      interval_fh: item.interval_fh ? parseFloat(item.interval_fh) : 0,
      interval_fh_hsi: item.interval_fh_hsi ? parseFloat(item.interval_fh_hsi) : 0,
      csn_as_of_llp: item.csn_as_of_llp ? parseInt(item.csn_as_of_llp) : 0,
      llp: null
    }))
  }
  if (Object.keys(removeEmptyKey(validationInputs)).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/engine/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          trackActivity('Engine Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          if (!validationFlag) {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          }
          this.setState({ enginesEdit: false, engineTabColor: 'primary' })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      engineTabColor: 'secondary',
      error: {
        ...prevState.error,
        engine: validationInputs,
      }
    }))
    if (validationInputs && validationInputs[0] && Object.keys(validationInputs[0]).length) {
      this.setState({ tabIndex: 0 })
    }
    if (validationInputs && validationInputs[1] && Object.keys(validationInputs[1]).length) {
      this.setState({ tabIndex: 1 })
    }
  }
}
// update Engine HSI Component
export function updateEngineHsiLogApi(props = {}, engine = {}, dom = null, validationFlag = false) {
  let validationInputs = {}
  if (!validationFlag) {
    engine.engineInfo.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: engineHsiIntermediateValidateFn(item, dom),
      }
    })
  }
  let payload = {
    log_id: parseInt(props.match.params.id),
    engine: engine.engineInfo
  }
  payload = {
    ...payload,
    engine: engine.engineInfo.map((item, index) => ({
      ...item,
      tsn: item.tsn ? parseFloat(item.tsn) : 0,
      csn: item.csn ? parseInt(item.csn) : 0,
      tsn_at_hsi: item.tsn_at_hsi ? parseFloat(item.tsn_at_hsi) : 0,
      csn_at_hsi: item.csn_at_hsi ? parseInt(item.csn_at_hsi) : 0,
      last_performed_hsi: item.last_performed_hsi ? item.last_performed_hsi.id : null,
      interval_fh_hsi: item.interval_fh_hsi ? item.interval_fh_hsi : null,
      cost_hsi: item.cost_hsi ? parseFloat(item.cost_hsi) : 0,
      tsn_at_lsv: item.tsn_at_lsv ? parseFloat(item.tsn_at_lsv) : 0,
      csn_at_lsv: item.csn_at_lsv ? parseInt(item.csn_at_lsv) : 0,
      last_performed_sv: item.last_performed_sv ? item.last_performed_sv.id : null,
      interval_fh: item.interval_fh ? parseFloat(item.interval_fh) : 0,
      cost: item.cost ? parseFloat(item.cost) : 0,
      csn_as_of_llp: item.csn_as_of_llp ? parseInt(item.csn_as_of_llp) : 0,
      llp: null,
    }))
  }
  if (Object.keys(removeEmptyKey(validationInputs)).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/engine/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          trackActivity('Engine HSI Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          if (!validationFlag) {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          }
          this.setState({ enginesEdit: false, engineTabColor: 'primary' })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      engineTabColor: 'secondary',
      error: {
        ...prevState.error,
        engine: validationInputs,
      }
    }))
    if (validationInputs && validationInputs[0] && Object.keys(validationInputs[0]).length) {
      this.setState({ tabIndex: 0 })
    }
    if (validationInputs && validationInputs[1] && Object.keys(validationInputs[1]).length) {
      this.setState({ tabIndex: 1 })
    }
  }
}
// Get Engine LLP List
export function getEngineLLpListApi(props, engine, engineIndex, component, loaderLLpUpdates = false) {
   
  if (!loaderLLpUpdates) {
    this.setState({ pageLoader: true })
  }
  if (loaderLLpUpdates) {
    this.setState({ checkBoxLoader: true })
  }
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}`)
    .then(response => {
      this.setState({ pageLoader: false })
      this.setState({ checkBoxLoader: false })
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          llpModal: true,
          logDetailCopy: prevState.hlaLogDetail,
          secondaryLLpEnableFlag: response.data.data.secondary && response.data.data.secondary.length && response.data.data.secondary.every(llpItem => !llpItem.status) ? false : true,
          engineLLP: {
            ...response.data.data,
            primary:
              component == "overall" ?
                response.data.data.primary && response.data.data.primary.length ?
                  !engine.llp_csn_flag ?
                    response.data.data.primary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false }))
                    : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, status: response.data.data.is_wide_body ? true : llpItem.status })) : []
                :
                response.data.data.primary && response.data.data.primary.length ?
                  !engine.llp_csn_flag ?
                    response.data.data.primary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false }))
                    : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, status: response.data.data.is_wide_body ? true : llpItem.status })) : [],
            secondary:
              component == "overall" ?
                response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : []
                : response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
            static:
              component == "overall" ?
                response.data.data.static && response.data.data.static.length ? response.data.data.static.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false })) : []
                : response.data.data.static && response.data.data.static.length ? response.data.data.static.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false })) : []
          },
          engineLlpList: response.data.data.primary && response.data.data.primary.length ? !engine.llp_csn_flag ? response.data.data.primary.map(llpItem => ({ ...llpItem, csn: engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false })) : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
          hlaLogDetail: {
            ...prevState.hlaLogDetail,
            engine: {
              ...prevState.hlaLogDetail.engine,
              engineInfo: prevState.hlaLogDetail.engine.engineInfo.map((item, index) =>
                component == "overall" ?
                  index === engineIndex && !item.llp_csn_flag ?
                    {
                      ...item,
                      tsn_as_of_llp: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.tsn_at_lsv : item.tsn,
                      csn_as_of_llp: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.csn_at_lsv : item.csn,
                      llp_status_as_of_date: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.last_event_date : item.utilization_as_of_date,
                    } : item
                  :
                  {
                    ...item,
                    tsn_as_of_llp: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.tsn_at_lsv : item.tsn_at_hsi : item.tsn,
                    csn_as_of_llp: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.csn_at_lsv : item.csn_at_hsi : item.csn,
                    llp_status_as_of_date: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.last_event_date : item.last_hsi_event_date : item.utilization_as_of_date,
                  }
              )
            }
          }
        }))
      }
    })
}
// get Secondary LLP list
export function getSecondaryLLpListApi(props, engine, engineIndex, component) {
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}&llp=3`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          secondaryLLpEnableFlag: response.data.data.secondary && response.data.data.secondary.length && response.data.data.secondary.every(llpItem => !llpItem.status) ? false : true,
          engineLLP: {
            ...prevState.engineLLP,
            secondary: response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
          }
        }))
      }
    })
}
// get Static LLP List
export function getStaticLLpListApi(props, engine, engineIndex, component, llpId) {
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}&llp=2`)
    .then(response => {
      if (checkApiStatus(response)) {
        response.data.data.static.map((stat, index) => {
          if (stat.id == llpId) {
            this.setState(prevState => ({
              ...prevState,
              engineLLP: {
                ...prevState.engineLLP,
                static: prevState.engineLLP.static && prevState.engineLLP.static.length ? prevState.engineLLP.static.map(llpItem => llpItem.id == llpId ? ({ ...stat, csn: stat.csn ? stat.csn : engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false }) : llpItem) : []
              }
            }))
          }
        })
      }
    })
}
// Import LLP
export function importLLPApi(file, engine, engineIndex, props, component) {
  let formData = new FormData()
  formData.append('file', file[0])
  this.setState({ pageLoader: true });
  globalPostService(`valuation/half-life-adjustment/engine/${engine.id}/llp/import/`, formData)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ confirmationModal: false })
        this.getEngineLLpListApi(props, engine, engineIndex, component)
        trackActivity('Imported Engine LLP', {
          type: 'Engine LLP',
          log_id: props.match.params.id
        })
      } else {
        trackActivity('Import Engine LLP Failed', {
          type: 'Engine LLP',
          log_id: props.match.params.id
        })
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ confirmationModal: false })
      }
    })
}
// Export LLP
export function exportLLPApi(props = {}, model = {}, engine, query = {}) {
  this.setState({ pageLoader: true })
  globalExportService(`valuation/half-life-adjustment/engine/${engine.id}/llp/export/`, { download: query.extension })
    .then(response => {
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`LOG_ID_${props.match.params.id}_HTHL_${model.aircraftType.name}_LLP_${moment().format(backendDateFormat)}`), query.extension);
      trackActivity('Exported Engine LLP', {
        type: 'Engine LLP',
        log_id: props.match.params.id
      })
    })
}
// update LLP
export function updateLLPApi(props, engine, llps, hlaLogDetail) {
  let payload = {}
  payload = {
    engineLLP: {
      ...llps,
      eng_log_id: engine.id,
      primary: llps.primary.map(llp => ({ ...llp, cycles_limit: parseInt(llp.cycles_limit), csn: parseInt(llp.csn), cost: parseFloat(llp.cost) }))
    }
  }
  this.setState({ pageLoader: true })
  globalPutService('valuation/half-life-adjustment/engine/llps/', payload)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          llpTabIndex: 0
        }))
        trackActivity('Engine LLps updated', {
          type: 'Half Life Adjustment',
          log_id: props.match.params.id,
          engine_log_id: engine.id
        })
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState({ llpModal: false })
        if (hlaLogDetail.generic.propeller_aircraft) {
          this.updateEngineHsiLogApi(props, hlaLogDetail.engine, null, true)
        } else {
          this.updateEngineLogApi(props, hlaLogDetail.engine, null, true)
        }

      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
// Update secondaryLLP
export function secondaryllpUpdateApi(props, engine, engineIndex, component, data) {
  globalPutService(`valuation/half-life-adjustment/engine/llp_status/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState({ secondaryLLpEnableFlag: data.secondary })
        this.getSecondaryLLpListApi(props, engine, engineIndex, component)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
// Update Static LLP
export function staticLLpUpdateApi(props, engine, engineIndex, component, data) {
  globalPutService(`valuation/half-life-adjustment/engine/llp_status/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.getStaticLLpListApi(props, engine, engineIndex, component, data.static_id)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}


// apu
export function updateApuLogApi(props = {}, apu = {}, dom = null) {
  let validationInputs = {}
  validationInputs = {
    hours: errorCode['hours'][fieldValidation({ ...errorCode['hoursObj'], fieldval: apu.hours })],
    utilization_as_of_date_apu: errorCode['utilization_as_of_date'][fieldValidation({ ...errorCode['utilization_as_of_dateObj'], fieldval: apu.utilization_as_of_date })],
    serial_number_apu: errorCode['serial_number'][fieldValidation({ ...errorCode['serial_numberObj'], fieldval: apu.serial_number })],

  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      interval_apu: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: apu.interval_fh })],
      cost_apu: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: apu.cost })],
    }
    if (apu.apuRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_apu: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: apu.remarks })],
      }
    }

  }
  if (apu.hours_at_lsv || apu.cycles_at_lsv || apu.lsv_date) {
    validationInputs = {
      ...validationInputs,
      apu_hours_at_lsv: hlaErrorCode['apu_hours_lsv'][fieldValidation({ ...hlaErrorCode['apu_hours_lsvObj'], fieldval: apu.hours_at_lsv })],
    }
  }
  let payload = {
    log_id: parseInt(props.match.params.id),
    apu: apu
  }
  payload = {
    ...payload,
    apu: {
      ...payload.apu,
      hours: payload.apu.hours ? parseFloat(payload.apu.hours) : 0,
      hours_at_lsv: payload.apu.hours_at_lsv ? parseFloat(payload.apu.hours_at_lsv) : 0,
      cycles: payload.apu.cycles ? parseInt(payload.apu.cycles) : 0,
      cycles_at_lsv: payload.apu.cycles_at_lsv ? parseInt(payload.apu.cycles_at_lsv) : 0,
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/apu/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          trackActivity('APU Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.setState({ apuEdit: false })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        apu: validationInputs
      }
    }))
  }
}
// propeller
export function updatePropellerLogApi(props = {}, propeller = {}) {
  let validationInputs = {}
  propeller.map((item, index) => {
    validationInputs = {
      ...validationInputs,
      [index]: propellerIntermediateValidationFn(item)
    }
  })
  let payload = {
    log_id: parseInt(props.match.params.id),
    propeller: propeller
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    payload = {
      ...payload,
      propeller: payload.propeller.map(item => ({
        ...item,
        tsn_at_lsv: item.tsn_at_lsv ? item.tsn_at_lsv : 0
      }))
    }
    this.setState({ pageLoader: true })
    globalPutService(`valuation/half-life-adjustment/propeller/`, payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          trackActivity('Propeller Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.setState({ propellerEdit: false })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      tabColor: "secondary",
      error: {
        ...prevState.error,
        propeller: validationInputs
      }
    }))
    if (propeller.length > 1) {
      if (Object.keys(validationInputs[0]).length) {
        this.setState({ propellerTabIndex: 0 })
      }
      if (Object.keys(validationInputs[1]).length) {
        this.setState({ propellerTabIndex: 1 })
      }
    }

  }
}
export function updateLgLogApi(props = {}, lg = {}, dom = {}) {
  let validationInputs = {}
  lg.map((item, index) => {
    validationInputs = {
      ...validationInputs,
      [index]: lgIntermediateValidationFn(item)
    }
  })
  let payload = {
    log_id: parseInt(props.match.params.id),
    landing_gear: lg,
  }
  payload = {
    ...payload,
    landing_gear: payload.landing_gear.map((lg, index) => ({ ...lg, tsn: lg.tsn ? parseFloat(lg.tsn) : 0, tsn_at_lsv: lg.tsn_at_lsv ? parseFloat(lg.tsn_at_lsv) : 0, csn_at_lsv: lg.csn_at_lsv ? parseInt(lg.csn_at_lsv) : 0 }))
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/landing_gear/', payload)
      .then(response => {
        this.setState({ pageLoader: false, tabColor: 'primary' })
        if (checkApiStatus(response)) {
          this.getHlaLogApi(props)
          trackActivity('Landing Gear Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          this.setState({ lgEdit: false })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      tabColor: "secondary",
      error: {
        ...prevState.error,
        lg: validationInputs
      }
    }))
    if (Object.keys(validationInputs[0]).length) {
      this.setState({ lgTabIndex: 0 })
    }
    if (Object.keys(validationInputs[1]).length) {
      this.setState({ lgTabIndex: 1 })
    }
    if (Object.keys(validationInputs[2]).length) {
      this.setState({ lgTabIndex: 2 })
    }
  }
}
export function validateIntermediateApi(props = {}, query = {}) {
  this.setState({ pageLoader: true, tabIndex: 0 })
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1) {
    if (query.validatorConstant == 1) {
      globalGetService(`valuation/half-life-adjustment/validate-log/${props.match.params.id}/?validator=${query.validatorConstant} `)
        .then(response => {
          this.setState({ pageLoader: false })
          if (response.data.statusCode == 200) {
            trackActivity('Log Edited', {
              type: 'Half Life Adjustment',
              log_id: props.match.params.id
            })
            props.history.push(`/half-life/output/${props.match.params.id}`)
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
            this.setState(prevState => ({
              ...prevState,
              error: response.data.data.error,
              validatorConstant: 0,
              validatorModal: true

            }))
            if (response.data.data && response.data.data.error && response.data.data.error.airframe) {
              this.setState({ airframeEdit: true, airframeWarningText: 'Aircraft has consumed the expected MTBR interval. Update if applicable.' })
            }
            if (response.data.data && response.data.data.error && response.data.data.error.propeller) {
              this.setState({ propellerEdit: true, propellerWarningText: ' Propeller has consumed the expected MTBR interval. Update if applicable.' })
            }
            if (response.data.data && response.data.data.error && response.data.data.error.engine) {
              this.setState({ enginesEdit: true, engineWarningText: ' Engine has consumed the expected MTBR interval. Update if applicable.' })
              if (response.data.data && response.data.data.error && response.data.data.error.engine && response.data.data.error.engine.engine_1 && response.data.data.error.engine.engine_1[0]) {
                this.setState({ tabIndex: 0 })
              }
              if (response.data.data && response.data.data.error && response.data.data.error.engine && response.data.data.error.engine.engine_2 && response.data.data.error.engine.engine_2[0]) {
                this.setState({ tabIndex: 1 })
              }
            }
            if (response.data.data && response.data.data.error && response.data.data.error.propeller) {
              this.setState({ propellerEdit: true })
              if (response.data.data && response.data.data.error && response.data.data.error.lg && response.data.data.error.propeller[0]) {
                this.setState({ propellerTabIndex: 0 })
              }
              if (response.data.data && response.data.data.error && response.data.data.error.lg && response.data.data.error.propeller[1]) {
                this.setState({ propellerTabIndex: 1 })
              }
            }

            if (response.data.data && response.data.data.error && response.data.data.error.apu) {
              this.setState({ apuEdit: true, apuWarningText: 'APU has consumed the expected MTBR interval. Update if applicable.' })
            }
            if (response.data.data && response.data.data.error && response.data.data.error.lg) {
              this.setState({ lgEdit: true })
              if (response.data.data && response.data.data.error && response.data.data.error.lg && response.data.data.error.lg[0]) {
                this.setState({ lgTabIndex: 0, lgWarningText: 'LG has consumed the expected MTBR interval. Update if applicable.' })
              }
              if (response.data.data && response.data.data.error && response.data.data.error.lg && response.data.data.error.lg[1]) {
                this.setState({ lgTabIndex: 1 })
              }
              if (response.data.data && response.data.data.error && response.data.data.error.lg && response.data.data.error.lg[2]) {
                this.setState({ lgTabIndex: 2 })
              }
            }
          }
        })
    } else {
      globalGetService(`valuation/half-life-adjustment/validate-log/${props.match.params.id}/`)
        .then(response => {
          this.setState({ pageLoader: false })
          if (checkApiStatus(response)) {
            props.history.push(`/half-life/output/${props.match.params.id}`)
            trackActivity('Log Edited', {
              type: 'Half Life Adjustment',
              log_id: props.match.params.id
            })
          } else {
            this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          }
        })
    }
  } else {
    props.history.push(`/half-life/output/${props.match.params.id}`)
  }

}
// output
export function getHlaOutputDataApi(props = {}) {
  this.setState({ pageLoader: true })
  globalGetService(`valuation/half-life-adjustment/result/${props.match.params.id}/`)
    .then(response => {
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        this.setState({
          hlaOutputData: response.data.data
        })
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function exportResultApi(props, query = {}) {
  this.setState({ exportLoader: true })
  globalExportService(`valuation/half-life-adjustment/result/${props.match.params.id}/`, { download: query.extension })
    .then(response => {
      trackActivity('Exported Result', {
        type: "Half Life Adjustment",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ exportLoader: false });
      downloadFileType(response.data, (`LogId_${this.props.match.params.id}_Maintenance_Adjusted_Base_Value_${moment().format(displayDateFormatShort)}`), query.extension);
    })
}
export function exportLLpStackApi(props, query = {}) {
  this.setState({ exportLoader: true })
  globalExportService(`valuation/half-life-adjustment/result/${props.match.params.id}/`, { download: query.extension, engine: query.engine })
    .then(response => {
      trackActivity('Exported LLP Stacks', {
        type: "Half Life Adjustment",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ exportLoader: false });
      downloadFileType(response.data, (`Log ${this.props.match.params.id} Engine #${query.engine} LLP Breakdown`), query.extension);
    })
}
//validation

export function engineInitiateValidatioFn(info, index) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    tsnEngine: hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: info.tsn })],
    csnEngine: hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: info.csn })],
    utilization_as_of_date_engine: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: info.utilization_as_of_date })]
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ''
  } else {
    return validationInputs
  }
}
export function propellerInitiateValidatioFn(info, index) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    tsnPropeller: hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: info.tsn })],
  }
  validationInputs = {
    ...validationInputs,
    utilization_as_of_date_propeller: errorCode['utilization_as_of_date'][fieldValidation({ ...errorCode['utilization_as_of_dateObj'], fieldval: info.utilization_as_of_date })]
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ''
  } else {
    return validationInputs
  }
}
export function lgInitiateValidationFn(info, index) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    csnLg: hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: info.csn })],
    utilization_as_of_dateLg: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: info.utilization_as_of_date })]
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ''
  } else {
    return validationInputs
  }
}

export function engineIntermediateValidateFn(item, dom = null) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    serial_number_engine: hlaErrorCode['serial_number'][fieldValidation({ ...hlaErrorCode['serial_numberObj'], fieldval: item.serial_number })],
    tsnEngine: item.tsn === 0 ? '' : hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: item.tsn })],
    csnEngine: item.csn === 0 ? '' : hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: item.csn })],
    utilization_as_of_date_engine: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],
    tsn_at_prsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_prsv'][fieldValidation({ ...hlaErrorCode['tsn_at_prsvObj'], fieldval: item.tsn_at_lsv })],
    last_prsv_event_date: hlaErrorCode['last_prsv_event_date'][fieldValidation({ ...hlaErrorCode['last_prsv_event_dateObj'], fieldval: item.last_event_date })],

  }
  if (item.csn_at_lsv || item.last_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_prsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_prsv'][fieldValidation({ ...hlaErrorCode['tsn_at_prsvObj'], fieldval: item.tsn_at_lsv })],
    }
  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      interval_engine: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh })],
      cost_engine: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: item.cost })],
    }
    if (item.engineRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }

    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function engineHsiIntermediateValidateFn(item, dom = null) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    serial_number_engine: hlaErrorCode['serial_number'][fieldValidation({ ...hlaErrorCode['serial_numberObj'], fieldval: item.serial_number })],
    tsnEngine: item.tsn === 0 ? '' : hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: item.tsn })],
    csnEngine: item.csn === 0 ? '' : hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: item.csn })],
    utilization_as_of_date_engine: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],
  }
  if (item.csn_at_hsi || item.last_hsi_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_hsi: item.tsn_at_hsi === 0 ? '' : hlaErrorCode['tsn_at_hsi'][fieldValidation({ ...hlaErrorCode['tsn_at_hsiObj'], fieldval: item.tsn_at_hsi })],
    }
  }
  if (item.csn_at_lsv || item.last_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_lsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_ovh'][fieldValidation({ ...hlaErrorCode['tsn_at_ovhObj'], fieldval: item.tsn_at_lsv })],
    }
  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      interval_fh_hsi: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh_hsi })],
      cost_engine_hsi: hlaErrorCode['cost_hsi'][fieldValidation({ ...hlaErrorCode['cost_hsiObj'], fieldval: item.cost_hsi })],
      interval_fh: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh })],
      cost_engine: hlaErrorCode['cost_hsi'][fieldValidation({ ...hlaErrorCode['cost_hsiObj'], fieldval: item.cost })],
    }
    if (item.engineRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }
    }
    if (item.engineHsiRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine_hsi: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks_hsi })]
      }
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function airframeIntermediateValidationFn(event) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    lsv_date: hlaErrorCode['airframe_lsv_date'][fieldValidation({ ...hlaErrorCode['airframe_lsv_dateObj'], fieldval: event.lsv_date })],
  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      cost_airframe: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: event.cost })],
    }
    if (event.airframeRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_airframe: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: event.remarks })],
      }
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ""
  } else {
    return validationInputs
  }
}
export function propellerIntermediateValidationFn(item) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    tsn: hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: item.tsn })],
    utilization_as_of_date_propeller: moment(item.utilization_as_of_date) > moment() ? `Please enter Utilization as of date less than or equal to ${moment().format(displayDateFormatShort)}` : hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],

  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != 1) {
    validationInputs = {
      ...validationInputs,
      interval_fc_propeller: hlaErrorCode['interval_fh'][fieldValidation({ ...hlaErrorCode['interval_fhObj'], fieldval: item.interval_fh })],
      interval_mo_propeller: hlaErrorCode['interval_mo'][fieldValidation({ ...hlaErrorCode['interval_moObj'], fieldval: item.interval_mo })],
      cost_propeller: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: item.cost })],
    }
    if (item.propellerRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_propeller: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }
    }
  }
  if (item.tsn_at_lsv || item.lsv_date) {
    validationInputs = {
      ...validationInputs,
      propeller_tsn_at_lsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_lsv'][fieldValidation({ ...hlaErrorCode['tsn_at_lsvObj'], fieldval: item.tsn_at_lsv })],
      prop_lsv_date: moment(item.lsv_date) > moment() ? `Please enter LSV Date less than or equal to ${moment().format(displayDateFormatShort)}` : hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.lsv_date })],
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ""
  } else {
    return validationInputs
  }
}
export function lgIntermediateValidationFn(item) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    csn: errorCode['csn'][fieldValidation({ ...errorCode['csnObj'], fieldval: item.csn })],
    utilization_as_of_date_lg: errorCode['utilization_as_of_date'][fieldValidation({ ...errorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],

  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      interval_fc_lg: hlaErrorCode['interval_fc'][fieldValidation({ ...hlaErrorCode['interval_fcObj'], fieldval: item.interval_fc })],
      interval_mo_lg: hlaErrorCode['interval_mo'][fieldValidation({ ...hlaErrorCode['interval_moObj'], fieldval: item.interval_mo })],
      cost_lg: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: item.cost })],
    }
    if (item.landingGearRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_lg: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }
    }
  }
  if ((item.csn_at_lsv || item.lsv_date)) {
    validationInputs = {
      ...validationInputs,
      lg_csn_at_lsv: item.csn_at_lsv === 0 ? '' : hlaErrorCode['csn_at_lsv'][fieldValidation({ ...hlaErrorCode['csn_at_lsvObj'], fieldval: item.csn_at_lsv })],
      lg_lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.lsv_date })],
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return ""
  } else {
    return validationInputs
  }
}

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { InputAdornment, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Tooltip } from '@material-ui/core';
import { globalGetService, globalPostService, globalPutService } from '../../../utils/globalApiServices';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import { regexConstants } from '../../../constants/regEx';
import { checkApiStatus } from '../../../utils';
const AddEditAsset = ({addEditAsset, aircraftTypes, toggleModalFn, getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState(addEditAsset.data);
    const [engineTypes, setEngineTypes] = useState([]);
    const [error, setError] = useState({});
    const onFieldChange = (key,value) => {
        if(key === 'aircraft_type'){
            if(value){
                getEngineTypes({aircraft_type:value.id,valuation:'valuation'});
            }else{
                setEngineTypes([]);
            }
            setFormData({...formData,[key]:value, 'engine_type':null});
        }else if (key === 'mtow' || key === 'mtow_kgs' && value >= 0) {
            if (key == 'mtow_kgs') {
                setFormData({...formData,[key]:value, 'mtow': (value * 2.2046).toFixed(4)});
            } else {
                setFormData({...formData,[key]:value, 'mtow_kgs': (value * 0.4535970244).toFixed(4)});
            }
        } else {
            setFormData({...formData,[key]:value});    
        }
    }
    const getEngineTypes = (query) => {
        globalGetService(`console/engine-types/`,query)
        .then(response => {
            if(checkApiStatus(response)){
                setEngineTypes(response.data.data.engineTypes)
            }
        })
    }
    const onAddEditAsset = () => {
        console.log(formData,'formData')
         
        let validationInputs = {
            serial_number: formData?.serial_number?.trim() ? '':'Please enter Serial Number',
            aircraft_type: formData?.aircraft_type ? '':'Please select Aircraft Type',
            engine_type: formData?.engine_type ? '':'Please select Engine Type',
            date_of_manufacture: formData?.date_of_manufacture ? '':'Please select Date of Manufacture',
            mtow: formData?.mtow?.toString().trim() ? '':'Please enter MTOW',
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true)
            if(formData.id){
                globalPutService(`valuation/fleet/${formData.id}/`, formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();
                        getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }else{
                globalPostService(`valuation/fleet/`, formData)
                .then(response => {
                    if(checkApiStatus(response)){
                        toggleModalFn();
                        getResponseBack();
                        enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }else{
                        enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}});
                    }
                    setLoading(false)
                })
            }
        }else{
            setError(validationInputs);
        }
    }
    return(
        <Dialog
            open={addEditAsset.modal}
            onClose={toggleModalFn}
            maxWidth='md'
        >
            <DialogTitle id="scroll-dialog-title">
            {addEditAsset.data.id ? 'Edit':'Add'} Fleet
            </DialogTitle>
            <DialogContent dividers={true} className="add-portfolio-dialog">
                <Grid container spacing={1}>
                    <Grid item md={6}>
                        <TextField
                            required
                            id="serial_number"
                            label="Serial Number"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 50 }}
                            value={formData?.serial_number||''}
                            error={error?.serial_number}
                            helperText={error?.serial_number||''}
                            InputLabelProps={{shrink: true}}
                            onFocus={()=>setError({...error, 'serial_number':''})}
                            onChange={(e) => onFieldChange('serial_number', e.target.value)}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={aircraftTypes}
                            getOptionLabel={option => option.name}
                            id="engine_type"
                            value={formData?.aircraft_type||null}
                            onChange={(e, value) => { onFieldChange('aircraft_type', value);}}
                            renderInput={params => <TextField required error={error?.aircraft_type} helperText={error?.aircraft_type || ''} onFocus={() => setError({'aircraft_type':''})} {...params} label="Aircraft Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <Autocomplete
                            options={engineTypes}
                            getOptionLabel={option => option.name}
                            id="engine_type"
                            value={formData?.engine_type||null}
                            onChange={(e, value) => { onFieldChange('engine_type', value);}}
                            renderInput={params => <TextField required error={error?.engine_type} helperText={error?.engine_type || ''} onFocus={() => setError({'engine_type':''})} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                required
                                margin="normal"
                                id="date_of_manufacture"
                                label="Date of Manufacture"
                                format={fieldDateFormat}
                                placeholder="DD-MM-YYYY"
                                fullWidth
                                disableFuture
                                minDate={moment().subtract('years',25)}
                                InputLabelProps={{shrink: true}}
                                value={formData?.date_of_manufacture||null}
                                onChange={(data, value) => onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat))}
                                onFocus={(e) => setError({...error, 'date_of_manufacture':''})}
                                error={error?.date_of_manufacture}
                                helperText={error?.date_of_manufacture||''}
                                inputVariant="outlined"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            id="mtow"
                            label="MTOW"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 50 }}
                            value={formData?.mtow||''}
                            InputLabelProps={{shrink: true}}
                            error={error?.mtow}
                            helperText={error?.mtow||''}
                            onFocus={()=>setError({...error, 'mtow':''})}
                            onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange('mtow', e.target.value) : e.preventDefault()}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Lbs</InputAdornment>,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            required
                            id="mtow_kgs"
                            label="MTOW"
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 50 }}
                            value={formData?.mtow_kgs||''}
                            InputLabelProps={{shrink: true}}
                            onChange={(e) => { regexConstants.numberWithDotUpto4.test(e.target.value) ? onFieldChange('mtow_kgs', e.target.value) : e.preventDefault()}}
                            onFocus={()=>setError({...error, 'mtow':''})}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">Kgs</InputAdornment>,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button disabled={isLoading} onClick={onAddEditAsset} color='primary' size='small' variant='contained'>
                    {isLoading ? <CircularProgress size={24} />:'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default AddEditAsset;
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Button, Tabs, Tab, Paper, Grid, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ValuationOutPlaceHolder } from '../../Elements'
import { PageLoader } from '../../../shared_elements';
import { EventWrapper, EventsTab, HlaOutputwidget, AirframeBasic, AirframeEvent, APUBasic, ApuEvent, EngineBasic, EngineHSIEvent, EngineEvent, PropellarBasic, PropellarEvent, LgBasic, LgEvent, HlaBreadCrumbs } from '../components';
import EditIcon from '@material-ui/icons/Edit';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc, getAPUTypesAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators'; // shared apis
import { getValuationHlaApi, getValuationHlaResultApi, lockUnlockHlaApi, exportHlaReportApi } from '../apiServices'
import { getAircraftModsApi, getMntEventsApi } from '../../Elements/apiServices'
import { checkApiStatus, permissionCheckFn } from '../../../utils';
import { airframeErrorCode, hlaErrorCode, lgTypesObj } from '../'
import { fieldValidation } from '../../../utils/formValidation';
import { globalPutService } from '../../../utils/globalApiServices';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
class HlaOutput extends Component{
  constructor(props){
    super(props);
    this.state = {
      pageLoader:false,
      skeletonLoader: true,
      mode:'view',
      airframeIndex:0,
      engineIndex:0,
      propellerIndex:0,
      lgIndex:0,
      mods:[],
      mntGroups:[],
      halfLifeCrud:{},
      halfLifeResult:{},
      error:{}
    };
    this.getValuationHlaApi = getValuationHlaApi.bind(this);
    this.getValuationHlaResultApi = getValuationHlaResultApi.bind(this);
    this.lockUnlockHlaApi = lockUnlockHlaApi.bind(this);
    this.exportHlaReportApi = exportHlaReportApi.bind(this);
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getMntEventsApi = getMntEventsApi.bind(this);
  }
  componentDidMount(){
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList();
    this.props.getPlatformConstants();
    this.getValuationHlaApi(this.props);
    this.getValuationHlaResultApi(this.props)
    .then(response => {
      this.setState({skeletonLoader:false});
      if(checkApiStatus(response)){
        let payout = response.data.data;
        this.setState({halfLifeResult:payout});
        this.getAircraftModsApi(this.props, { aircraft_type: payout.aircraft_type.id })
        .then(response => {
          this.setState({mods:response});
        });
        this.getMntEventsApi(this.props, payout.aircraft_type.id)
        .then(response => {
          this.setState({mntGroups:response});
        });
        this.props.getAPUTypes({'aircraft-type': payout.aircraft_type.id });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': payout.aircraft_type.id });
      }
    });
  }
  handleTabChange = (event, newValue, tabType) => {
    this.setState({[tabType]:newValue});
  }
  toggleLLpModal = (engineIndex, component) => {
     
    const { halfLifeCrud } = this.props
    this.setState({llpModal:true})
    // this.getEngineLLpListApi(this.props, halfLifeCrud.engine.engineInfo[engineIndex], engineIndex, component)
}
  onFieldChange = (e, keyParam, value, assembly, objectKey, index) => {
    const { halfLifeCrud } = this.state;
    if (assembly == 'generic') {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          generic: {
            ...prevState.halfLifeCrud.generic,
            [keyParam]: value
          }
        }
      }))
    }
    if((assembly && objectKey) || assembly === 'landing_gear'){
      if(assembly === 'airframe' || assembly === 'engine'){
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            [assembly]:{
              ...prevState.halfLifeCrud[assembly],
              [objectKey]: prevState.halfLifeCrud[assembly][objectKey].map((item, itemIndex) => itemIndex === index ? {
                ...item,
                [keyParam]:value
              } :item )
            }
          }
        }))
      }
      if(assembly === 'propeller' || assembly === 'landing_gear'){
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            [assembly]:prevState.halfLifeCrud[assembly].map((item, itemIndex) => index===itemIndex ? {
              ...item,[keyParam]:value
            } :item)
          }
        }))
      }
      if(assembly === 'apu'){
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            apu:{
              ...prevState.halfLifeCrud.apu,
              [keyParam]:value
            }
          }
        }))
      }
    }
    else{
      this.updateFormFields(keyParam, value, assembly)
    }
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2), 'generic');
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2), 'generic');
      }
    }
    // if(keyParam === 'no_of_engines'){
    //   this.updateAssemblyInfo('engine', 'no_of_engines', value);
    // }
   
  }
  updateFormFields = (keyParam, value, objectKey) => {
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        [objectKey]: {
          ...prevState.halfLifeCrud[objectKey],
          [keyParam]: value
        }
      }
    }))
  }
  updateErrorField = (module, key, tabIndex, ) => {
     
    const {error}= this.state
    if(Object.keys(error).length){
    if(['landing_gear','engine','propeller'].includes(module) ){
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          [module]:{
            ...prevState.error[module],
            [tabIndex]:{
              ...prevState.error[module][tabIndex],
              [key]:''
            }
          }
        }
      }))
    }
    else if(module == 'airframe'){
      if(tabIndex){
        this.setState(prevState=>({
          ...prevState,
          error:{
            ...prevState.error,
            airframe:{
              ...prevState.error.airframe,
              maintenance_events:{
                ...prevState.error.airframe.maintenance_events,
                [tabIndex]:{
                  ...prevState.error.airframe.maintenance_events[tabIndex],
                  [key]:''
                }
              }
            }
          }
        }))
      }
      else{
        this.setState(prevState=>({
          ...prevState,
          error:{
            ...prevState.error,
            airframe:{
              ...prevState.error.airframe,
              [key]:''
            }
          }
        }))
      }
    }
    else{
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          apu:{
            ...prevState.error.apu,
            [key]:''
          }
        }
      }))
    }
    }
    // this.setState(prevState => ({
    //   ...prevState,
    //   error: {
    //     ...prevState.error,
    //     [keyParam]: message
    //   }
    // }))
  }
  updateAssemblyInfo = (assemblyType, keyParam, value) => {
    const { halfLifeCrud } = this.state;
    if(keyParam === 'no_of_engines'){
      let engineList = halfLifeCrud.engine.engineInfo.length;
      if(engineList < value){
        let newEgines = [], count = value - engineList;
        for(let i=0;i<count;i++){
          newEgines.push({id:'',tsn:'', csn:'', utilization_as_of_date:null})
        }
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            engine:{
              ...prevState.halfLifeCrud.engine,
              engineInfo:[...prevState.halfLifeCrud.engine.engineInfo,...newEgines]
            },
            propeller:{
              ...prevState.halfLifeCrud.propeller,
              propellerInfo:[...prevState.halfLifeCrud.propeller.propellerInfo, ...newEgines]
            }
          }
        }))
      }else{
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            engine:{
              ...prevState.halfLifeCrud.engine,
              engineInfo:prevState.halfLifeCrud.engine.engineInfo.slice(0, value)
            },
            propeller:{
              ...prevState.halfLifeCrud.propeller,
              propellerInfo:prevState.halfLifeCrud.propeller.propellerInfo.slice(0, value)
            }
          }
        }))
      }
    }
  }
  updateEngineField = (event, keyParam, value, engineIndex, prIndex) => {
     
    const { halfLifeCrud } = this.state
    if (keyParam == "last_event_date" && value == halfLifeCrud.airframe.date_of_manufacture) {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          engine: {
            ...prevState.halfLifeCrud.engine,
            engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
              {
                ...item,
                last_event_date: value,
                tsn_at_lsv: 0,
                csn_at_lsv: 0
              } : item
            )
          }
        }
      }))
    }
    if ((keyParam == 'last_performed_sv' && value)) {
      if (value == "None") {
      } else if (value && value.event) {
        let data = value
        data = {
          ...data,
          name: value.event
        }
        if (value.event == "Mature Run") {
          this.setState(prevState => ({
            ...prevState,
            halfLifeCrud: {
              ...prevState.halfLifeCrud,
              engine: {
                ...prevState.halfLifeCrud.engine,
                engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: data.interval,
                    cost: data.cost,
                  } : item
                )
              }
            }
          }))
        } else {
          this.setState(prevState => ({
            ...prevState,
            halfLifeCrud: {
              ...prevState.halfLifeCrud,
              engine: {
                ...prevState.halfLifeCrud.engine,
                engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    // interval_fh_hsi: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    cost: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].cost,
                    // cost_hsi:prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].cost
                  } : item)
              }
            }
          }))
        }
      }
    } else {
      // if (keyParam == "csn_as_of_llp") {
      //   this.updateLlpCsn(value, engineIndex)
      // }
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          engine: {
            ...prevState.halfLifeCrud.engine,
            engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ? { ...item, [keyParam]: value } : item)
          }
        }
      }))
    }
  }
  updateLgType = (flag) => {
    const { halfLifeCrud } = this.state;
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud:{
        ...prevState.halfLifeCrud,
        landing_gear:{
          ...prevState.halfLifeCrud.landing_gear,
          lgInfo: flag ? prevState.halfLifeCrud.landing_gear.lgInfo.slice(0,1):lgTypesObj[halfLifeCrud.airframe.aircraftType.no_of_landing_gears]
        }
      }
    }))
  }
  updateHlaOutput =()=>{
    let data = this.state.halfLifeCrud
     
    let validationInput={}
     
    validationInput={
      ...validationInput,
      airframe:{
        mtow: airframeErrorCode['mtow'][fieldValidation({ ...airframeErrorCode['mtowObj'], fieldval: data.generic.mtow })],
        tsn: data.airframe?.tsn == '' ? 'Please enter TSN':'',
        csn: data.airframe?.csn == '' ? 'Please enter CSN':'',
        utilization_as_of_date: data.airframe.utilization_as_of_date ? '':'Please enter Utilization Date',
        // maintenance_events: data.airframe.maintenance_events.map((item,index)=>({
        //   lsv_date: hlaErrorCode['airframe_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.lsv_date })],
        //   cost: item?.cost ? '' : "Please enter Cost"
        // }))
      },
      engine: data?.engine?.engineInfo.map((item, index)=>({
        // last_event_date: hlaErrorCode['last_event_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.last_event_date })],
        // tsn_at_lsv: item?.tsn_at_lsv ? '': "Please enter TSN at LSV",
        // csn_at_lsv: item?.csn_at_lsv ? '': "Please enter CSN at LSV",
        // interval_fh: item?.interval_fh ?'': "Please Enter Interval",
        tsn: item.tsn == '' ? 'Please enter TSN':'',
        csn: item.csn == '' ? 'Please enter CSN':'',
        as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
        // cost: item?.cost ?'': "Please Enter Cost",
      }))
      ,
      // landing_gear: data.landing_gear.map((item, index )=>({
      //   csn_at_lsv: item?.csn_at_lsv ? '' : 'Please enter CSN at LSV',
      //   lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.lsv_date })],
      // })),  
    }
    if(data.airframe.utilization_type && data.airframe.utilization_type === 2){
      validationInput = {
        ...validationInput,
        airframe:{
          ...validationInput.airframe,
          planned_fh:data.airframe?.planned_fh?.trim().length ? '':'Please enter Planned FH',
          planned_fc:data.airframe?.planned_fc?.trim().length ? '':'Please enter Planned FC',
        }
      }
    }
    if (data?.propeller && Object.keys(data?.propeller).length) {
      validationInput={
        ...validationInput,
        engine: data?.engine?.engineInfo.map((item, index)=>({
          // last_event_date: hlaErrorCode['last_event_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.last_event_date })],
          // tsn_at_lsv: item?.tsn_at_lsv ? '': "Please enter TSN at LSV",
          // csn_at_lsv: item?.csn_at_lsv ? '': "Please enter CSN at LSV",
          // interval_fh: item?.interval_fh ?'': "Please Enter Interval",
          tsn: item.tsn == '' ? 'Please enter TSN':'',
          csn: item.csn == '' ? 'Please enter CSN':'',
          as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
          // cost: item?.cost ?'': "Please Enter Cost",     
          // interval_fh_hsi: item?.interval_fh_hsi ?'': 'Please Enter HSI Interval',
          // cost_hsi: item?.cost_hsi ?'': 'Please Enter HSI Cost',
          // last_hsi_event_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.last_hsi_event_date })],
        }))
      }
    }
    // if (data?.apu && Object.keys(data?.apu).length) {
    //   validationInput = {
    //     ...validationInput,
    //     apu:{
    //       interval_fh: data?.apu?.interval_fh? '': "Please enter Interval ",
    //       lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: data?.apu?.lsv_date })],
    //     }
    //     }
    // }
    
    if (data?.propeller && Object.keys(data?.propeller).length) {
      validationInput = {
        ...validationInput,
        propeller: data?.propeller.map((item, index) => ({
          // interval_fh: item?.interval_fh ? '' : "Please enter Interval",
          proptsn: item.tsn === '' ? 'Please enter TSN':'',
          // interval_mo: item?.interval_mo ? '' : 'Please enter Interval',
          // cost: item?.cost ? '' : 'Please enter Cost',
          // lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.lsv_date })],
        }))
      }
    }
    // let checkAirframeError = validationInput?.airframe?.maintenance_events.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''})).includes(false) ? false : true
    let checkEngineError = validationInput?.engine.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) ).includes(false) ? false : true
    // let checkApuError = data?.apu && Object.keys(data?.apu).length ?Object.keys(validationInput?.apu).every((k)=> {return validationInput?.apu[k] ===''}): true;
    // let checkLgError = validationInput?.landing_gear.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) ).includes(false) ? false : true
    let checkPropError = data?.propeller && Object.keys(data?.propeller).length ? validationInput?.propeller.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) ).includes(false) ? false : true : true

    if([ checkEngineError, checkPropError].includes(false)){
      this.setState({error:validationInput})
      this.props.enqueueSnackbar('Please fill all the required fields', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      // if(checkAirframeError == false){
      //   let errorIndex =validationInput?.airframe?.maintenance_events.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}))
      //   this.handleTabChange('event', errorIndex.indexOf(false), 'airframeIndex')  
      // }
      // if(checkEngineError == false){
      //   let errorIndex =validationInput?.engine.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) )
      //   this.setState(prevState=>({...prevState,flag:{...prevState.flag, engine:true }}))
      //   this.handleTabChange('event', errorIndex.indexOf(false), 'engineIndex')  
      // }
      // if(checkPropError == false){
      //   let errorIndex =validationInput?.propeller.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) )
      //   this.setState(prevState=>({...prevState,flag:{...prevState.flag, propeller:true }}))
      //   this.handleTabChange('event', errorIndex.indexOf(false), 'propellerIndex')  
      // }
      // if(checkLgError == false){
      //   let errorIndex =validationInput?.landing_gear.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) )
      //   this.setState(prevState=>({...prevState,flag:{...prevState.flag, lg:true }}))
      // this.handleTabChange('event', errorIndex.indexOf(false), 'lgIndex')  
      // }
      // if(checkApuError == false){
      //   this.setState(prevState=>({...prevState,flag:{...prevState.flag, apu:true }}))
      // }
    }
    else{
      let payload = Object.assign({},data)
      payload={
        ...payload,
        engine:payload?.engine?.engineInfo 
      }
      this.setState({pageLoader:true})
      globalPutService(`valuation/v2/half-life-adjustment/${this.props.match.params.slug}/`,payload)
      .then(response=>{
        this.setState({pageLoader:false})
        if(checkApiStatus(response)){
          this.getValuationHlaApi(this.props);
          this.setState({ mode: 'view', error: {} })
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        else{
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
    }
  }
  updateIndexedAssembly = (e, keyParam, value, assembly, objectKey , index) => {
    if(assembly==  'landing_gear'){
      this.setState(prevState=>({
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          landing_gear:prevState.halfLifeCrud.landing_gear.map((item, itemIndex)=>
              itemIndex === index ? 
              {...item, [keyParam]:value}
               :item)
        }
      }))
    }
    else if(assembly==  'propeller'){
      this.setState(prevState=>({
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          propeller:prevState.halfLifeCrud.propeller.map((item, itemIndex)=>
              itemIndex === index ? 
              {...item, [keyParam]:value}
               :item)
        }
      }))
    }
    else{
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          [assembly]:{
            ...prevState.halfLifeCrud[assembly],
            [objectKey]:prevState.halfLifeCrud[assembly][objectKey].map((item, assemblyIndex) => assemblyIndex === index ? {
              ...item, [keyParam]:value
            } :item)
          }
        }
      }))
    }
  }
  render(){
    const { skeletonLoader, pageLoader, mode, error, airframeIndex, engineIndex, propellerIndex, lgIndex, mods, mntGroups, halfLifeCrud, halfLifeResult } = this.state;
    const { aircraftTypes, engineTypes, apuTypes, lessee, pltConstants } = this.props;
    const editPermission = permissionCheckFn({secondaryKey:'hthl', keyIndex:'U'});
    console.log('halfLifeCrud',halfLifeCrud);
    return(
      <section className="valuation-app-section">
        { skeletonLoader ? <Skeleton variant="text" />:
          <HlaBreadCrumbs
            logResult={halfLifeResult}
            changeLogStatus={(data) => this.lockUnlockHlaApi(this.props, data)}
            mode={mode}
            exportValuationLog={(file)=>this.exportHlaReportApi(this.props, file)}
          />
        }
        <div className="hla-section">
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <div style={{ overflow:'scroll'}}> 
              {skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={540} /> :
                <Paper elevation={1} className="assembly-sections">
                  <div style={{maxHeight:'70vh', overflow:'scroll'}}>
                  <div style={{ textAlign: 'right', marginBottom: '10px' }}>
                    {editPermission && !halfLifeResult.lock_status ?
                      mode == 'edit' ?
                        <>
                          <Button style={{ marginRight: "10px" }} variant='outlined' color='primary' size='small' onClick={() => this.setState({ mode: 'view', error: {} })} > Cancel </Button>
                          <Button variant='contained' color='primary' size='small' onClick={() => this.updateHlaOutput()} > Save </Button>
                        </> :
                        <Button variant='contained' color='primary' size='small' onClick={() => this.setState({ mode: 'edit', error: {} })} > Edit </Button>
                      : null}
                  </div>
                  { halfLifeCrud.airframe && Object.keys(halfLifeCrud.airframe).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Airframe Details" flag={true}>
                        <Grid container spacing={1}>
                          <AirframeBasic
                            lessee={lessee}
                            aircraftTypes={aircraftTypes}
                            mods={mods}
                            mntGroups={mntGroups}
                            seatConfigs={pltConstants.filter(item => item.type === 'seating_configuration')}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={halfLifeCrud}
                            error={error?.airframe ? error?.airframe :{}}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={(key)=>this.updateErrorField('airframe', key, '')}
                          />
                        </Grid>
                        <EventsTab
                          tabIndex={airframeIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'airframeIndex')}
                          tabs={halfLifeCrud.airframe.maintenance_events.map(item => `${item.event} Year Check`)}
                        />
                        {halfLifeCrud.airframe.maintenance_events.map((mntProgram, index) =>
                          index === airframeIndex ?
                          <Grid container spacing={1}>
                            <AirframeEvent
                              item={mntProgram}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={halfLifeCrud}
                              error={{}}
                              // error={error?.airframe?.maintenance_events[index]}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('airframe', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.engine && Object.keys(halfLifeCrud.engine).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Engine Details" flag={false}>
                        <EventsTab
                          tabIndex={engineIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'engineIndex')}
                          tabs={halfLifeCrud.engine.engineInfo.map((engine, index) => `Engine #${index+1}`)}
                        />

                        { halfLifeCrud.engine.engineInfo.map((item, index) =>
                          index === engineIndex ?
                          <Fragment>
                            <Grid container spacing={1}>
                              <EngineBasic
                                engineTypes={engineTypes}
                                mode={mode}
                                permissionFlag={editPermission && !halfLifeResult.lock_status}
                                crudData={halfLifeCrud}
                                error={error?.engine ? error?.engine :{}}
                                item={item}
                                itemIndex={index}
                                updateIndexedAssembly={this.updateIndexedAssembly}
                                updateErrorField={(key)=>this.updateErrorField('engine', key, index)}
                              />
                            </Grid>
                            { item.engine_hsi && halfLifeCrud.airframe.aircraftType.has_propeller ?
                              <Grid container spacing={1}>
                                <EngineHSIEvent
                                  item={item}
                                  itemIndex={index}
                                  mode={mode}
                                  permissionFlag={editPermission && !halfLifeResult.lock_status}
                                  crudData={{}}
                                  // error={error?.engine && error?.engine[index]}
                                  error={{}}
                                  onFieldChange={this.onFieldChange}
                                  updateErrorField={(key)=>this.updateErrorField('engine', key, index)}
                                />
                              </Grid>:null
                            }
                            <Grid container spacing={1}>
                              <EngineEvent
                                props={this.props}
                              updateEngineField={this.updateEngineField}
                              closeLLLpModal={() => this.setState({ llpModal: false, engineLLP: { primary: [], secondary: [], static: [] }, checkedA: false, checkedB: false })}
                               toggleLLpModal={this.toggleLLpModal}
                                item={item}
                                tabIndex={engineIndex}
                                itemIndex={index}
                                mode={mode}
                                llpModal={this.state.llpModal}
                                permissionFlag={editPermission && !halfLifeResult.lock_status}
                                crudData={halfLifeCrud}
                                error={{}}
                                // error={error?.engine && error?.engine[index]}
                                onFieldChange={this.onFieldChange}
                                updateErrorField={(key)=>this.updateErrorField('engine', key, index)}
                                updateEngineLog={() => this.updateEngineLogApi(this.props, halfLifeCrud?.engine, halfLifeCrud?.airframe?.date_of_manufacture)}
                              />
                            </Grid>
                          </Fragment>
                          :null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.propeller && Object.keys(halfLifeCrud.propeller).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Propellar Details" flag={false}>
                        <EventsTab
                          tabIndex={propellerIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'propellerIndex')}
                          tabs={halfLifeCrud.propeller.map((propeller) => `Propellar #${propeller.position}`)}
                        />
                        { halfLifeCrud.propeller.map((item, index) =>
                          propellerIndex === index ?
                          <Grid container spacing={1}>
                            <PropellarBasic
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={item}
                              error={error?.propeller ? error?.propeller : {}}
                              item={item}
                              itemIndex={index}
                              updateIndexedAssembly={this.updateIndexedAssembly}
                              updateErrorField={(key)=>this.updateErrorField('propeller', key, index)}
                            />
                            <PropellarEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={{}}
                              // error={error?.propeller && error?.propeller[index]}
                              error={{}}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('propeller', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.apu && Object.keys(halfLifeCrud?.apu).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="APU Details" flag={false}>
                        <Grid container spacing={1}>
                          <APUBasic
                            apuTypes={apuTypes}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={halfLifeCrud}
                            error={error?.apu}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={(key)=>this.updateErrorField('apu', key, '')}
                          />
                          <ApuEvent
                            item={halfLifeCrud.apu}
                            mode={mode}
                            permissionFlag={editPermission && !halfLifeResult.lock_status}
                            crudData={{}}
                            error={{}}
                            // error={error?.apu}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={(key)=>this.updateErrorField('apu', key, '')}
                          />
                        </Grid>
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.landing_gear && Object.keys(halfLifeCrud.landing_gear.length) ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Landing Gear Details" flag={false}>
                        <EventsTab
                          tabIndex={lgIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'lgIndex')}
                          tabs={halfLifeCrud.landing_gear.map((item) => item.position == 1 ? 'NLG' : item.position == 2 ? "LH MLG" : item.position == 3 ? "RH MLG" : "")}
                        />
                        { halfLifeCrud.landing_gear.map((item, index) =>
                          lgIndex === index ?
                          <Grid container spacing={1}>
                            <LgBasic
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={halfLifeCrud}
                              error={{}}
                              // error={error?.landing_gear }
                              item={item}
                              itemIndex={index}
                              updateIndexedAssembly={this.updateIndexedAssembly}
                              updateErrorField={(key)=>this.updateErrorField('landing_gear', key, index)}
                            />
                            <LgEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={editPermission && !halfLifeResult.lock_status}
                              crudData={halfLifeCrud}
                              error={{}}
                              // error={error?.landing_gear && error?.landing_gear[index]}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('landing_gear', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  </div>
                  <div style={{borderTop:"1px solid #dbdada", paddingLeft:'8px'}}>
                   <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    required
                    disabled={mode === 'view' || !editPermission ? true:false}
                    inputVariant="outlined"
                    margin="normal"
                    id="base_value_date"
                    placeholder="DD-MM-YYYY"
                    label="Value as Of"
                    format={fieldDateFormat}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    maxDate={moment().add(1, 'years')}
                    minDate={moment().subtract(1, 'years')}
                    onChange={(data, value) => this.onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat) : data, 'generic')}
                    value={halfLifeCrud?.generic?.report_date}
                    error={error.base_value_date ? true : false}
                    helperText={error.base_value_date ? error.base_value_date : ''}
                    onFocus={() => this.updateErrorField('base_value_date', '')}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              </div>
                </Paper>
              }
            </div>
            </Grid>
            <Grid item md={4} xs={12}>
            <div style={{maxHeight:'85vh', overflow:'scroll'}}>
              { skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={540} /> :
                <Fragment>
                  { Object.keys(halfLifeResult).length ? <HlaOutputwidget halfLifeResult={halfLifeResult} halfLifeCrud={halfLifeCrud} />:null}
                </Fragment>
              }
              </div>
            </Grid>
          </Grid>
        </div>
        { pageLoader ? <PageLoader />:null }
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
  apuTypes: state.shareReducer.apuTypes,
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({constant_types:['seating_configuration']})),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(HlaOutput))

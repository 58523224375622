import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { TableRow, TableCell, Tooltip } from '@material-ui/core';
import { TableRowActions } from '../../../shared_elements';
import { getLocalStorageInfo } from '../../../utils';
import { displayDateFormatShort } from '../../../constants';
import baseValueIcon from '../../../assets/images/bv_icon.svg';
import futureValueIcon from '../../../assets/images/fv_icon.svg';
import hlaIcon from '../../../assets/images/hla_icon.svg';
import leaseEncumbIcon from '../../../assets/images/lease_encumbered_value.svg';
import lockIcon from '../../../assets/images/NewLockIcon.png';
import unlockIcon from '../../../assets/images/unlockicon.png';
import leaseRentalIcon from '../../../assets/images/lease_rental_icon.svg'
import InfoIcon from '@material-ui/icons/Info';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
const ValuationLog = ({ item, history, updateLockfn, props }) => {
  const redirectToApplication = () => {
    if (item.utility_type == 'Half Life Adjustment') {
      history.push(`/hla/output/${item.slug}`)
    }
    if (item.utility_type == "Lease Encumbered Value") {
      history.push(`/lev/${item.slug}`)
    }
    if (['Base Value', 'Future Value'].includes(item.utility_type)) {
      history.push(`/logs/${item.slug}`)
    }
    if (["BLR/MLR"].includes(item.utility_type)) {
      history.push(`/blr/${item.slug}`)
    }
  }
  return (
    <TableRow hover tabIndex={-1} style={{ cursor: 'pointer' }}>
      <TableCell onClick={() => redirectToApplication()}>
        <Tooltip arrow title={item.asset_type && item.asset_type === 2 ? 'Engine':'Aircraft'}>
            <span>{item.asset_type && item.asset_type === 2 ? 'E-':'A-'}{item.id}</span>
      </Tooltip>

      </TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.serial_number ? item.serial_number : '--'}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.aircraft_type?item.aircraft_type:'--'}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.date_of_manufacture ? moment(item.date_of_manufacture).format(displayDateFormatShort):'--'}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>{item.engine_type?item.engine_type:'--'}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>
        { item.mtow ?
          <div className="flex-centered">
          {`${item.mtow.toFixed(4)} Lbs`}
          <Tooltip arrow title={`${(item.mtow * 0.4535970244).toFixed(4)} Kgs`}>
            <InfoIcon color="default" style={{ fontSize: '13px', paddingLeft: '4px' }} />
          </Tooltip>
        </div>:'--'
        }
      </TableCell>
      <TableCell onClick={() => redirectToApplication()}> {item.created_by.name} <br />{moment(item.created_at).format(displayDateFormatShort)}</TableCell>
      <TableCell onClick={() => redirectToApplication()}>
        <ul className="list-inline valuation-logs-links">
          {['Base Value', 'Future Value'].includes(item.utility_type) ?
            <li className="list-inline-item">
              <Link to={`/logs/${item.id}`}>
                <Tooltip title="Base Value" arrow>
                  <img style={{ width: '20px', opacity: '.75' }} src={baseValueIcon} alt="" />
                </Tooltip>
              </Link>
            </li> : null
          }
          {['Future Value'].includes(item.utility_type) ?
            <li className="list-inline-item">
              <Link to={`/logs/${item.id}`}>
                <Tooltip title="Future Base Values" arrow>
                  <img style={{ width: '20px', opacity: '.75' }} src={futureValueIcon} alt="" />
                </Tooltip>
              </Link>
            </li> : null
          }
          {
            ['Half Life Adjustment'].includes(item.utility_type) ?
              <li className="list-inline-item">
                <Link to={`/hla/output/${item.id}`}>
                  <Tooltip title="Maintenance Adjusted Base Value" arrow>
                    <img style={{ width: '20px', opacity: '.75' }} src={hlaIcon} alt="" />
                  </Tooltip>
                </Link>
              </li> : null
          }
          {
            ["Lease Encumbered Value"].includes(item.utility_type) ?
              <li className="list-inline-item">
                <Link to={`/lev/${item.id}`}>
                  <Tooltip title="Lease Encumbered Value" arrow>
                    <img style={{ width: '20px', opacity: '.75' }} src={leaseEncumbIcon} alt="" />
                  </Tooltip>
                </Link>
              </li> : null
          }
          {
            ["BLR/MLR"].includes(item.utility_type) ?
              <li className="list-inline-item">
                <Link to={`/blr/${item.id}`}>
                  <Tooltip title="Lease Rates" arrow>
                    <img style={{ width: '20px', opacity: '.75' }} src={leaseRentalIcon} alt="" />
                  </Tooltip>
                </Link>
              </li> : null
          }
        </ul>
      </TableCell>
      <TableCell>
        {
          getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('U') != -1 ?
            item.lock_status ?
              <Tooltip title="Click to resume editing." placement="top" arrow>
                <LockIcon color="primary" onClick={() => updateLockfn(props, { log_id: item.slug, is_locked: false })} />
              </Tooltip>
              :
              <Tooltip title="Click to lock editing." placement="top" arrow>
                <LockOpenIcon color="primary" onClick={() => updateLockfn(props, { log_id: item.slug, is_locked: true })} />
              </Tooltip>
            :
            item.lock_status ? <img src={lockIcon} width="30" alt="Icon" style={{ cursor: 'default' }} /> : <img src={unlockIcon} width="35" alt="Icon" style={{ cursor: 'default' }} />
        }
      </TableCell>
    </TableRow>
  )
}
export default ValuationLog;

import React, { Fragment } from 'react';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid, Button, TableRow, TableCell, Typography, Breadcrumbs, IconButton, Tooltip, Paper } from '@material-ui/core';
import { TableListComp, ExportManu } from '../../../shared_elements';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat } from '../../../utils';
import { permissionCheckFn } from '../../../utils';
import { trackActivity } from '../../../utils/mixpanel';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);
const FbvProjection = ({valuationLog, filter_start, filter_end}) => {
  let series = [];
  const getPlots = (projection, returnType) => {
    let plotOptions = {}, plotData = [];
    if(filter_start && filter_end){
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start && key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    }else if (filter_start) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) >= filter_start)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    }else if (filter_end) {
      plotOptions = Object.keys(projection)
        .filter(key => key.slice(0, 4) <= filter_end)
        .reduce((obj, key) => {
          obj[key] = projection[key];
          return obj;
        }, {});
    }else{
      plotOptions = projection
    }
    Object.keys(plotOptions).map(year => {
      let d = [];
      d.push(new Date(year.slice(4, 6) + '/01/' + year.slice(0, 4)).getTime());
      d.push(plotOptions[year]);
      plotData.push(d);
    });
    if(returnType === 'plots'){
      return plotOptions
    }
    // console.log(plotOptions, 'plotOptions')
    // console.log(plotData, 'plotData')
    return plotData
  }
  if(valuationLog.future_value){
    series = [...series,
      {
        name: 'HL BV at 0% Annual Inflation',
        data: getPlots(valuationLog.future_value),
      }
    ]
  }
  if(valuationLog.inflated_future_value){
    series = [...series,
      {
        name: `HL BV at ${valuationLog.escalation_percentage}% Annual Inflation`,
        data: getPlots(valuationLog.inflated_future_value),
      }
    ]
  }
  if(valuationLog.is_full_life_maintenance_condition && valuationLog.full_life_future_values){
    series = [...series,
      {
        name: 'FL BV at 0% Annual Inflation',
        data: getPlots(valuationLog.full_life_future_values),
      }
    ]
  }
  if(valuationLog.is_full_life_maintenance_condition && valuationLog.full_life_inflated_future_value){
    series = [...series,
      {
        name: `FL BV at ${valuationLog.escalation_percentage}% Annual Inflation`,
        data: getPlots(valuationLog.full_life_inflated_future_value),
      }
    ]
  }
  const options = {
    chart: {
      type: 'area',
      marginTop: 60,
      zoomType: 'x',
    },
    exporting: permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'U' }) ? {
      buttons: {
        contextButton: {
          text: 'Export',
          menuItems: [
            {
              text: 'Export as PNG',
              onclick: function () {
                this.exportChart();
                trackActivity('Report Exported', {
                  file_type: 'PNG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as SVG',
              onclick: function () {
                this.exportChart({ type: 'image/svg+xml' });
                trackActivity('Report Exported', {
                  file_type: 'SVG',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            },
            {
              text: 'Export as PDF',
              onclick: function () {
                this.exportChart({ type: 'application/pdf' });
                trackActivity('Report Exported', {
                  file_type: 'PDF',
                  report_type: 'Chart',
                  type: 'Future Value'
                })
              }
            }
          ]
        }
      }
    } : '',
    credits: {
      enabled: false,
      style: {
        fontSize: 12
      },
      position: {
        align: 'left',
        x: 20,
        y: -380
      }
    },
    title: { text: '' },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Projection Year(s)'
      }
    },
    plotOptions: {area: {fillOpacity: 0.3}},
    tooltip: {
      formatter: function () {
        return 'The value as of <b>' + moment(this.x).format('MMM YYYY') +
          '</b> is <b>US$' + this.y.toFixed(3) + ' m</b>';
      }
    },
    yAxis: {
      title: {
        text: 'US$ m'
      },
      labels: {
        formatter: function () {
          return this.value.toFixed(4);
        }
      }
    },
    series: series
  }
  const getFbvTableHd = (valuationLog) => {
    let heads = [{label:'Date', sortOption:false}, {label:'HL BV at 0% Annual Inflation', sortOption:false}];
     if(valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length){
      heads = [...heads, {label:`HL BV at ${valuationLog.escalation_percentage}% Annual Inflation`,sortOption:false}]
    }
    if(valuationLog.full_life_future_values && Object.keys(valuationLog.full_life_future_values).length){
      heads = [...heads, {label:'FL BV at 0% Annual Inflation1',sortOption:false}]
    }

    if(valuationLog.full_life_inflated_future_value && Object.keys(valuationLog.full_life_inflated_future_value).length){
      heads = [...heads, {label:`FL BV at ${valuationLog.escalation_percentage}% Annual Inflation`,sortOption:false}]
    }
    return heads;
  }
  return(
    <Fragment>
      <div style={{ clear: 'both' }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
      <Grid container>
        {valuationLog.future_value && Object.keys(valuationLog.future_value).length ?
          <Grid item xs={12} alignContent="center" alignItems="center" justify="center" md={12} style={{ marginTop: '30px' }}>
            <TableListComp
              heads={getFbvTableHd(valuationLog)}
              data={Object.keys(getPlots(valuationLog.future_value, 'plots')).map((label, index) => {
                let date = new Date(label.slice(0, 4), `${label.slice(4) - 1}`)
                return (
                  <TableRow>
                    <TableCell style={{ width: '140px' }}>{moment(date).format('MMM YYYY')}</TableCell>
                    <TableCell style={{ width: '200px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.future_value[label]}</NumericalLabel>} m</TableCell>
                     {valuationLog && valuationLog.inflated_future_value && Object.keys(valuationLog.inflated_future_value).length?
                      <TableCell style={{ width: '300px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.inflated_future_value[label]}</NumericalLabel>} m
                      </TableCell>
                       : null
                    }
                    { valuationLog && valuationLog.full_life_future_values && Object.keys(valuationLog.full_life_future_values).length?
                      valuationLog.full_life_future_values[label]>0?
                          <TableCell style={{ width: '200px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.full_life_future_values[label]}</NumericalLabel>} m</TableCell>
                          :
                           <TableCell style={{ width: '300px' }} >N/A</TableCell>
                      : null
                    }

                    {valuationLog && valuationLog.full_life_inflated_future_value && Object.keys(valuationLog.full_life_inflated_future_value).length?
                      valuationLog.full_life_inflated_future_value[label]>0?
                          <TableCell style={{ width: '300px' }} >{<NumericalLabel params={getCurrencyFormat({ shortFormat: false, precision: 3 })}>{valuationLog.full_life_inflated_future_value[label]}</NumericalLabel>} m</TableCell>
                          :
                           <TableCell style={{ width: '300px' }} >N/A</TableCell>
                      : null
                    }
                  </TableRow>
                )}
              )}
            />
          </Grid> : null
        }
      </Grid>
    </Fragment>
  )
}
export default FbvProjection;

import React, { Fragment } from 'react';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, TextField, Tooltip, InputAdornment, FormControl, FormLabel, FormControlLabel, FormGroup, Checkbox, RadioGroup, Radio } from '@material-ui/core';
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { checkUserTypeFn } from '../../../utils';
import { regexConstants } from '../../../constants/regEx';
const AirframeEvent = ({error, item, itemIndex, onFieldChange, updateErrorField, crudData, mode, permissionFlag}) => {
  return(
    <Fragment>
      <Grid item md={4} xs={12}>
        <TextField
          id={`tsn_at_lsv_${itemIndex}`}
          label='TSN @ LSV'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.tsn_at_lsv?item.tsn_at_lsv:''}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'tsn_at_lsv', e.target.value, 'airframe', 'maintenance_events', itemIndex):e.preventDefault()}}
          error={error?.tsn_at_lsv ? true : false}
          helperText={error?.tsn_at_lsv ? error?.tsn_at_lsv : ''}
          onFocus={() => updateErrorField('tsn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FH</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextField
          id={`csn_at_lsv_${itemIndex}`}
          label='CSN @ LSV'
          fullWidth
          margin="normal"
          disabled={mode === 'view' || !permissionFlag ? true:false}
          inputProps={{ maxLength: 10 }}
          InputLabelProps={{ shrink: true }}
          value={item.csn_at_lsv?item.csn_at_lsv:''}
          onChange={(e) => {regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'csn_at_lsv', e.target.value, 'airframe', 'maintenance_events', itemIndex):e.preventDefault()}}
          error={error?.csn_at_lsv ? true : false}
          helperText={error?.csn_at_lsv ? error?.csn_at_lsv : ''}
          onFocus={() => updateErrorField('csn_at_lsv', '')}
          InputProps={{
            endAdornment: <InputAdornment position="end">FC</InputAdornment>,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            required
            disabled={mode === 'view' || !permissionFlag ? true:false}
            margin="normal"
            id={`lsv_date_${itemIndex}`}
            label="LSV as of Date"
            placeholder="DD-MM-YYYY"
            format={fieldDateFormat}
            fullWidth
            disableFuture={true}
            InputLabelProps={{ shrink: true }}
            minDate={moment(crudData.airframe.date_of_manufacture)}
            maxDate={crudData?.generic?.report_date ? moment(crudData.generic.report_date) : moment()}
            onChange={(data, value) => onFieldChange(value, 'lsv_date', data ? moment(data).format(backendDateFormat) : data, 'airframe', 'maintenance_events', itemIndex)}
            value={item.lsv_date ? item.lsv_date:null}
            error={error?.lsv_date ? true : false}
            helperText={error?.lsv_date ? error?.lsv_date : ''}
            onFocus={() => updateErrorField('lsv_date', '')}
            inputVariant="outlined"
          />
        </MuiPickersUtilsProvider>
      </Grid>
      { checkUserTypeFn() ?
        <Fragment>
          <Grid item md={4} xs={12}>
            <TextField
              required
              id={`cost_${itemIndex}`}
              label='Event Cost'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true:false}
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{ shrink: true }}
              value={item.cost?item.cost:''}
              onChange={(e) => { regexConstants.numberWithDot.test(e.target.value) ? onFieldChange(e, 'cost', e.target.value, 'airframe', 'maintenance_events', itemIndex):e.preventDefault()}}
              error={error?.cost ? true : false}
              helperText={error?.cost ? error?.cost : ''}
              onFocus={() => updateErrorField('cost', '')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              id={`remarks_${itemIndex}`}
              label='Event Remarks'
              fullWidth
              margin="normal"
              disabled={mode === 'view' || !permissionFlag ? true:false}
              inputProps={{ maxLength: 250 }}
              InputLabelProps={{ shrink: true }}
              value={item.remarks? item.remarks:''}
              onChange={(e) => onFieldChange(e, 'remarks', e.target.value, 'airframe', 'maintenance_events', itemIndex)}
              error={error?.remarks ? true : false}
              helperText={error?.remarks ? error?.remarks : ''}
              onFocus={() => updateErrorField('remarks', '')}
              variant="outlined"
              multiline={true}
            />
          </Grid>
        </Fragment>:null
      }
    </Fragment>
  )
}
export default AirframeEvent;

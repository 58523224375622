import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Button, Paper, Grid, Tooltip, TextField, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { ValuationOutPlaceHolder } from '../../Elements'
import { EventWrapper, AirframeBasic, EngineBasic, PropellarBasic, APUBasic, LgBasic, HlaBreadCrumbs } from '../components';
import { getEngineTypesAc, getAircraftTypesAc, getLesseeListAc, getAPUTypesAc, getPlatformConstantsAc } from '../../../shared_elements/actionCreators'; // shared apis
import { getDefaultUtilsApi, createHlaLogApi } from '../apiServices';
import { getAircraftModsApi, getMntEventsApi } from '../../Elements/apiServices'
import { fieldDateFormat, backendDateFormat } from '../../../constants';
import { permissionCheckFn } from '../../../utils';
import { hlaCrud, engineCrud, propellerCrud, apuCrud, landing_gearCrud, lgTypesObj } from '../'
import { PageLoader } from '../../../shared_elements';
class HalfLifeMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader: false,
      mode: 'add',
      halfLifeCrud: hlaCrud,
      mods: [],
      mntGroups: [],
      seatingConfigList: [],
      airframeError:{},
      apuError:{},
      lgError:[],
      propellerError:[],
      engineError:[],
      accordianState:false,
      redirectToIntermediate:{flag:false},
      openUtilModal:false
    }
    this.createHlaLogApi = createHlaLogApi.bind(this);
    this.getDefaultUtilsApi = getDefaultUtilsApi.bind(this);
    this.getAircraftModsApi = getAircraftModsApi.bind(this);
    this.getMntEventsApi = getMntEventsApi.bind(this);
  }
  componentDidMount() {
    this.props.getAircraftTypes({ valuation: "valuation" })
    this.props.getLesseeList();
    this.props.getPlatformConstants();
  }
  onFieldChange = (e, keyParam, value, objectKey) => {
    const { halfLifeCrud } = this.state;
    this.updateFormFields(keyParam, value, objectKey)
    if (keyParam == 'aircraftType') {
      this.updateFormFields('mods', [], 'generic');
      this.updateFormFields('engineType', null, 'engine');
      this.updateFormFields('no_of_engines', 1, 'engine');
      this.updateFormFields('identical_data', true, 'engine')
      this.updateFormFields('utilization_type', 1, 'airframe')
      this.updateFormFields('planned_fc', 0, 'airframe')
      this.updateFormFields('planned_fh', 0, 'airframe')
      this.updateFormFields('maintenance_program', null, 'airframe')
      this.setState(prevState => ({ ...prevState, halfLifeCrud: { ...prevState.halfLifeCrud, apuOptional: true } }))
      if (value) {
        this.setState(prevState => ({
          ...prevState,
          halfLifeCrud:{
            ...prevState.halfLifeCrud,
            engine:engineCrud,
            apu:apuCrud,
            propeller:propellerCrud,
            landing_gear:landing_gearCrud
          }
        }));

        this.getAircraftModsApi(this.props, { aircraft_type: value.id })
          .then(response => {
            this.setState({ mods: response });
          });
        this.getMntEventsApi(this.props, value.id)
          .then(response => {
            this.setState({ mntGroups: response });
          });
        this.props.getAPUTypes({ 'aircraft-type': value.id });
        this.props.getEngineTypes({ valuation: 'valuation', 'aircraft_type': value.id });
      }else{
        let newHlaCrud = Object.assign({},halfLifeCrud);
        delete newHlaCrud.engine;
        delete newHlaCrud.apu;
        delete newHlaCrud.landing_gear;
        delete newHlaCrud.propeller;
        this.setState({halfLifeCrud:newHlaCrud})
      }
    }
    // if(keyParam =='utilization_as_of_date' && objectKey=='airframe' && moment(value).isValid()){
    //   this.setState({openUtilModal:true})
    // }
    if (keyParam == 'mtow' || keyParam == "mtow_kgs" && value >= 0) {
      if (keyParam == 'mtow_kgs') {
        this.updateFormFields('mtow', (value * 2.2046).toFixed(2), 'generic');
      } else {
        this.updateFormFields('mtow_kgs', (value * 0.4535970244).toFixed(2), 'generic');
      }
    }
    if(keyParam === 'no_of_engines'){
      this.updateAssemblyInfo('no_of_engines', value, 'engine', 'engineInfo');
      if(halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.has_propeller){
        this.updateAssemblyInfo('no_of_engines', value, 'propeller', 'propellerInfo');
      }
    }
    if(keyParam === 'utilization_type'){
      if(value && value === 2){
        this.getDefaultUtilsApi(this.props, { aircraft_type_id:halfLifeCrud.airframe.aircraftType.id})
      }else{
        this.updateFormFields('planned_fh', 0, 'airframe')
        this.updateFormFields('planned_fc', 0, 'airframe')
      }
    }
  }
  updateFormFields = (keyParam, value, objectKey) => {
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        [objectKey]: {
          ...prevState.halfLifeCrud[objectKey],
          [keyParam]: value
        }
      }
    }))
  }
  updateErrorField = (keyParam, message, assemblyType, itemIndex) => {
    if(assemblyType == 'lgError'||assemblyType == 'engineError'){
      this.setState(prevState => ({
        ...prevState,
        [assemblyType]:{
          ...prevState[assemblyType],
          [itemIndex]:{
            ...prevState[assemblyType][itemIndex],
            [keyParam]:''
          }
        }
      }));
    }
    else{
      this.setState(prevState => ({
        ...prevState,
        [assemblyType]:{
          ...prevState[assemblyType],
          [keyParam]:message
        }
      }));
    }
  }
  populateUtilDate = () => {
    const { halfLifeCrud, apuOptional } = this.state
    if (halfLifeCrud?.apuOptional) {
      this.updateFormFields('utilization_as_of_date', halfLifeCrud.airframe.utilization_as_of_date, 'apu')
    }
    this.updateFormFields('utilization_as_of_date', halfLifeCrud.airframe.utilization_as_of_date, 'landing_gear')
    this.autoPopulateAirframeDate(halfLifeCrud.airframe.utilization_as_of_date)
    // this.setState({ propellerUtilFlag: true })
    this.setState({ openUtilModal: false })
  }
  autoPopulateAirframeDate = (value) => {
    const {halfLifeCrud}= this.state
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        engine: {
          ...prevState.halfLifeCrud.engine,
          engineInfo: prevState.halfLifeCrud.engine?.engineInfo.map(item => ({ ...item, utilization_as_of_date: value }))
        },
        landing_gear: {
          ...prevState.halfLifeCrud.landing_gear,
          lgInfo: prevState.halfLifeCrud.landing_gear.lgInfo.map(item => ({ ...item, utilization_as_of_date: value }))
        },
      }
    }))
    if (['ATR'].includes(halfLifeCrud.airframe?.aircraftType?.oem)) {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          propeller: {
            ...prevState.halfLifeCrud.propeller,
            propellerInfo: prevState.halfLifeCrud.propeller.propellerInfo.map(item => ({ ...item, utilization_as_of_date: value }))
          },
          apu: {}
        }
      }))
    }
    if (['Bombardier Aerospace'].includes(halfLifeCrud.airframe?.aircraftType?.oem)) {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          propeller: {
            ...prevState.halfLifeCrud.propeller,
            propellerInfo: prevState.halfLifeCrud.propeller.propellerInfo.map(item => ({ ...item, utilization_as_of_date: value }))
          }
        }
      }))
    }
  }
  updateAssemblyInfo = (keyParam, value, assemblyType,  objectKey) => {
    const { halfLifeCrud } = this.state;
    let engineList = halfLifeCrud[assemblyType][objectKey].length;
    if (engineList < value) {
      let newEgines = [], count = value - engineList;
      for (let i = 0; i < count; i++) {
        newEgines.push({ id: '', tsn: '', csn: '', utilization_as_of_date: null })
      }
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          [assemblyType]: {
            ...prevState.halfLifeCrud[assemblyType],
            [objectKey]: [...prevState.halfLifeCrud[assemblyType][objectKey], ...newEgines]
          }
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          [assemblyType]: {
            ...prevState.halfLifeCrud[assemblyType],
            [objectKey]: prevState.halfLifeCrud[assemblyType][objectKey].slice(0, value)
          }
        }
      }))
    }
  }
  updateLgType = (flag) => {
    const { halfLifeCrud } = this.state;
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        landing_gear: {
          ...prevState.halfLifeCrud.landing_gear,
          lgInfo: flag ? prevState.halfLifeCrud.landing_gear.lgInfo.slice(0, 1) : lgTypesObj[halfLifeCrud.airframe.aircraftType.no_of_landing_gears]
        }
      }
    }))
  }
  updateIndexedAssembly = (e, keyParam, value, assembly, objectKey, index) => {
    this.setState(prevState => ({
      ...prevState,
      halfLifeCrud: {
        ...prevState.halfLifeCrud,
        [assembly]: {
          ...prevState.halfLifeCrud[assembly],
          [objectKey]: prevState.halfLifeCrud[assembly][objectKey].map((item, assemblyIndex) => assemblyIndex === index ? {
            ...item, [keyParam]: value
          } : item)
        }
      }
    }))
  }
  render() {
    const { halfLifeCrud, mods, mntGroups, airframeError, apuError, lgError, propellerError, openUtilModal, engineError, mode, accordianState, redirectToIntermediate, pageLoader } = this.state;
    const { aircraftTypes, engineTypes, apuTypes, lessee, pltConstants } = this.props;
    const createPermission = permissionCheckFn({secondaryKey:'hthl', keyIndex:'C'})
    return (
      <section className="valuation-app-section">
        {pageLoader ? <PageLoader />: null}
        <HlaBreadCrumbs
          logResult={halfLifeCrud}
          mode={mode}
        />
        <div className="hla-section">
          <Grid container spacing={1}>
            <Grid item md={8} xs={12}>
              <Paper elevation={1} className="assembly-sections">
                <Paper elevation={2} className="assembly-card">
                  <EventWrapper flag={true} label="Airframe Details">
                    <Grid container spacing={1}>
                      <AirframeBasic
                        lessee={lessee}
                        aircraftTypes={aircraftTypes}
                        mods={mods}
                        openUtilModal={openUtilModal}
                        mntGroups={mntGroups}
                        seatConfigs={pltConstants.filter(item => item.type === 'seating_configuration')}
                        mode={mode}
                        permissionFlag={createPermission}
                        crudData={halfLifeCrud}
                        error={airframeError}
                        populateUtilDate={this.populateUtilDate}
                        openUtilModalFn={()=>moment(halfLifeCrud.airframe.utilization_as_of_date).isValid() ?this.setState({openUtilModal:true}): null}
                        closeUtilModal={()=>this.setState({openUtilModal:false})}
                        onFieldChange={this.onFieldChange}
                        openUtilModalDatePicker={()=>this.setState({openUtilModal:true})}
                        updateErrorField={this.updateErrorField}
                      />
                    </Grid>
                  </EventWrapper>
                </Paper>
                {halfLifeCrud.airframe && halfLifeCrud.airframe.aircraftType && halfLifeCrud.engine ?
                  <Paper elevation={2} className="assembly-card">
                    <EventWrapper multiEvents={true} flag={accordianState?accordianState:false} label="Engine Details">
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <Grid container spacing={1}>
                            <Grid item md={4} xs={12}>
                              <Autocomplete
                                options={engineTypes}
                                getOptionLabel={option => option.name}
                                id="engineType"
                                value={halfLifeCrud.engine.engineType ? halfLifeCrud.engine.engineType : null}
                                onChange={(e, value) => this.onFieldChange(e, 'engineType', value, 'engine')}
                                renderInput={params => <TextField required error={airframeError.engineType ? true : false} helperText={airframeError.engineType ? airframeError.engineType : ''} onFocus={() => this.updateErrorField('engineType', '', 'airframeError')} {...params} label="Engine Type" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant="outlined" />}
                              />
                            </Grid>
                            {halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.no_of_engines && halfLifeCrud.engine.engineType ?
                              <Grid item md={4} xs={12}>
                                <FormControl component="fieldset">
                                  <FormLabel component="legend">No. Of Engines*</FormLabel>
                                  <RadioGroup row aria-label="position" name="no_of_engines">
                                    {Array(halfLifeCrud.airframe.aircraftType.no_of_engines).fill(1).map((engine, index) =>
                                      <FormControlLabel disabled={mode === 'view' ? true : false} value="no_of_engines" control={<Radio color="primary" onChange={(e) => this.onFieldChange(e, 'no_of_engines', index + 1, 'engine')} checked={halfLifeCrud.engine.no_of_engines === index + 1 ? true : false} />} label={index + 1} />
                                    )}
                                  </RadioGroup>
                                </FormControl>
                              </Grid> : null
                            }
                            {halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.no_of_engines && halfLifeCrud.engine.no_of_engines >1 ?
                              <Grid item md={4} xs={12}>
                                <FormControl component="fieldset">
                                  <FormLabel component="legend">Do all Engines have same utilization?</FormLabel>
                                  <RadioGroup row aria-label="position" name="identical_data">
                                    <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.onFieldChange(e, 'identical_data', true, 'engine')} checked={halfLifeCrud.engine.identical_data} />} label='Yes' />
                                    <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.onFieldChange(e, 'identical_data', false, 'engine')} checked={!halfLifeCrud.engine.identical_data} />} label='No' />
                                  </RadioGroup>
                                </FormControl>
                              </Grid> : null
                            }
                          </Grid>
                        </Grid>
                        { halfLifeCrud.engine.engineType && halfLifeCrud.engine.engineInfo.slice(0, halfLifeCrud.engine.identical_data ? 1 : halfLifeCrud.engine.no_of_engines).map((item, index) =>
                          <Grid item md={12} xs={12}>
                            {!halfLifeCrud.engine.identical_data ? <h5>{`Engine #${index + 1}`}</h5> : null}
                            <Grid container spacing={1}>
                              <EngineBasic
                                engineTypes={engineTypes}
                                mode={mode}
                                permissionFlag={createPermission}
                                crudData={halfLifeCrud}
                                error={engineError}
                                item={item}
                                itemIndex={index}
                                updateIndexedAssembly={this.updateIndexedAssembly}
                                updateErrorField={this.updateErrorField}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </EventWrapper >
                  </Paper > : null
                }
                { halfLifeCrud.airframe && halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.has_propeller && halfLifeCrud.propeller ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper flag={accordianState?accordianState :false} label="Propellar Details">

                        <Grid container spacing={1}>
                          {halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.no_of_engines && halfLifeCrud.engine.engineType ?
                            <Grid item md={12} xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">Do all Propellars have same utilization?</FormLabel>
                                <RadioGroup row aria-label="position" name="identical_data">
                                  <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.onFieldChange(e, 'identical_data', true, 'propeller')} checked={halfLifeCrud.propeller.identical_data} />} label='Yes' />
                                  <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.onFieldChange(e, 'identical_data', false, 'propeller')} checked={!halfLifeCrud.propeller.identical_data} />} label='No' />
                                </RadioGroup>
                              </FormControl>
                            </Grid> : null
                          }
                          {halfLifeCrud.propeller.propellerInfo.slice(0,halfLifeCrud.propeller.identical_data ? 1:halfLifeCrud.engine.no_of_engines).map((item, index) =>
                            <Grid item md={12} xs={12}>
                              { !halfLifeCrud.propeller.identical_data ? <h5>{`Propellar #${index + 1}`}</h5>:null}
                              <Grid container spacing={1}>
                                <PropellarBasic
                                  mode={mode}
                                  permissionFlag={createPermission}
                                  crudData={halfLifeCrud}
                                  error={propellerError}
                                  item={item}
                                  itemIndex={index}
                                  updateIndexedAssembly={this.updateIndexedAssembly}
                                  updateErrorField={this.updateErrorField}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </EventWrapper>
                    </Paper> : null
                }
                { halfLifeCrud.airframe && halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.has_apu && halfLifeCrud.apu ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper flag={accordianState?accordianState :false} label="APU Details">
                        <Grid container spacing={1}>
                          {halfLifeCrud.airframe && halfLifeCrud.airframe.aircraftType && halfLifeCrud.airframe.aircraftType.has_optional_apu ?
                            <Grid item md={12} xs={12}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">APU Status?</FormLabel>
                                <RadioGroup row aria-label="position" name="identical_data">
                                  <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.setState(prevState => ({ ...prevState, halfLifeCrud: { ...prevState.halfLifeCrud, apuOptional: true } }))} checked={halfLifeCrud.apuOptional} />} label='Enable' />
                                  <FormControlLabel value="identical_data" control={<Radio size="small" color="primary" onChange={(e) => this.setState(prevState => ({ ...prevState, halfLifeCrud: { ...prevState.halfLifeCrud, apuOptional: false } }))} checked={!halfLifeCrud.apuOptional} />} label='Disable' />
                                </RadioGroup>
                              </FormControl>
                            </Grid> : null
                          }
                          {halfLifeCrud.apuOptional ?
                            <APUBasic
                              apuTypes={apuTypes}
                              mode={mode}
                              permissionFlag={createPermission}
                              crudData={halfLifeCrud}
                              error={apuError}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={this.updateErrorField}
                            /> : null
                          }

                        </Grid>
                      </EventWrapper>
                    </Paper> : null
                }
                {
                  halfLifeCrud.airframe && halfLifeCrud.airframe.aircraftType && halfLifeCrud.landing_gear ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper flag={accordianState?accordianState :false} label="Landing Gear Details">
                        <Grid container spacing={1}>
                          <Grid item md={12} xs={12}>
                            <FormControl component="fieldset">
                              <FormLabel component="legend">Do all Landing Gear have same utilization?</FormLabel>
                              <RadioGroup row aria-label="position" name="lg_utilization_flag">
                                <FormControlLabel value="lg_utilization_flag" control={<Radio size="small" color="primary" onChange={(e) => { this.onFieldChange(e, 'lg_utilization_flag', true, 'landing_gear'); this.updateLgType(true) }} checked={halfLifeCrud.landing_gear.lg_utilization_flag} />} label='Yes' />
                                <FormControlLabel value="lg_utilization_flag" control={<Radio size="small" color="primary" onChange={(e) => { this.onFieldChange(e, 'lg_utilization_flag', false, 'landing_gear'); this.updateLgType(false) }} checked={!halfLifeCrud.landing_gear.lg_utilization_flag} />} label='No' />
                              </RadioGroup>
                            </FormControl>
                          </Grid>
                          {halfLifeCrud.landing_gear.lgInfo.map((item, index) =>
                            <Grid item md={12} xs={12}>
                              {!halfLifeCrud.landing_gear.lg_utilization_flag ? <h5>{item.label}</h5> : null}
                              <Grid container spacing={1}>
                                <LgBasic
                                  mode={mode}
                                  permissionFlag={createPermission}
                                  crudData={halfLifeCrud}
                                  error={lgError}
                                  item={item}
                                  itemIndex={index}
                                  updateIndexedAssembly={this.updateIndexedAssembly}
                                  updateErrorField={this.updateErrorField}
                                />
                              </Grid>
                            </Grid >
                          )
                          }
                        </Grid >
                      </EventWrapper >
                    </Paper > : null
                }
                <div className="hla-submit-cta">
                  <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Grid item md={6} xs={12}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          required
                          margin="normal"
                          id="report_date"
                          label="Value as Of"
                          placeholder="DD-MM-YYYY"
                          format={fieldDateFormat}
                          fullWidth
                          minDate={moment().subtract(1, 'years')}
                          maxDate={moment().add(1, 'years')}
                          InputLabelProps={{ shrink: true }}
                          onChange={(data, value) => this.onFieldChange(value, 'report_date', data ? moment(data).format(backendDateFormat) : data, 'generic')}
                          value={halfLifeCrud.generic.report_date}
                          error={airframeError.report_date ? true : false}
                          helperText={airframeError.report_date ? airframeError.report_date : ''}
                          onFocus={() => this.updateErrorField('report_date', '', 'airframeError')}
                          inputVariant="outlined"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <Button onClick={() => this.createHlaLogApi(this.props, halfLifeCrud)} color="primary" variant="contained" size="small">Next</Button>
                        </li>
                        <li className="list-inline-item">
                          <Button onClick={() => this.props.history.push('/logs')} color="primary" variant="outlined" size="small">Cancel</Button>
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </Paper>
            </Grid>
            <Grid item md={4} xs={12}>
              <ValuationOutPlaceHolder />
            </Grid>
          </Grid>
        </div>
        <Dialog open={redirectToIntermediate?.flag}>
          <DialogContent dividers={true}>
            <div style={{ height: '100px' }}>
              Do you wish to update the Shop Visit details now?
            </div>
          </DialogContent>
          <DialogActions dividers={true}>
            <Button onClick={() => {this.props.history.push(`/hla/event/${redirectToIntermediate.data.slug}`) }} color="primary" variant="contained" size="small">Yes</Button>
            <Button onClick={() => {this.props.history.push(`/hla/output/${redirectToIntermediate.data.slug}`)  }} color="primary" variant="outlined" size="small">No</Button>
          </DialogActions>
        </Dialog>
      </section>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
  aircraftTypes: state.shareReducer.aircraftTypes,
  lessee: state.shareReducer.lessee,
  apuTypes: state.shareReducer.apuTypes,
  pltConstants: state.shareReducer.pltConstants,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
    getAircraftTypes: (query) => dispatch(getAircraftTypesAc(ownProps, query)),
    getLesseeList: (query) => dispatch(getLesseeListAc(query)),
    getAPUTypes: (query) => dispatch(getAPUTypesAc(query)),
    getPlatformConstants: () => dispatch(getPlatformConstantsAc({ constant_types: ['seating_configuration'] })),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(HalfLifeMaintenance))

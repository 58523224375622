import { globalGetService, globalPostService, globalPutService, globalExportService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, removeEmptyKey, permissionCheckFn } from '../../utils';
import moment from 'moment';
import { errorCode, airframeErrorCode, apuErrorCode, hlaErrorCode } from '.'
import { fieldValidation } from '../../utils/formValidation';
import { trackActivity } from '../../utils/mixpanel';
import { displayDateFormatShort, backendDateFormat } from '../../constants';
export function getDefaultUtilsApi(props, query){
  globalGetService(`valuation/annual-utilization/`,query)
  .then(response => {
    if(checkApiStatus(response)){
      let payout = response.data.data;
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          airframe: {
            ...prevState.halfLifeCrud.airframe,
            planned_fh:payout.annual_fh_cost?payout.annual_fh_cost:'',
            planned_fc:payout.annual_fc_cost?payout.annual_fc_cost:''
          }
        }
      }))
    }
  })
}

export function createHlaLogApi(props, data){
  let lgError = [], lgErrorFlag=true, propellerError = [], propellerErrorFlag=true, engineError = [], engineErrorFlag=true , apuErrorFlag=true;
  let validationInputs = {
    aircraftType: airframeErrorCode['aircraftType'][fieldValidation({ ...airframeErrorCode['aircraftTypeObj'], fieldval: data.airframe.aircraftType })],
    maintenance_program: airframeErrorCode['maintenance_program'][fieldValidation({ ...airframeErrorCode['maintenance_programObj'], fieldval: data.airframe.maintenance_program })],
    date_of_manufacture: airframeErrorCode['date_of_manufacture'][fieldValidation({ ...airframeErrorCode['date_of_manufactureObj'], fieldval: data.generic.date_of_manufacture })],
    report_date: airframeErrorCode['report_date'][fieldValidation({ ...airframeErrorCode['report_dateObj'], fieldval: data.generic.report_date })],
    mtow: airframeErrorCode['mtow'][fieldValidation({ ...airframeErrorCode['mtowObj'], fieldval: data.generic.mtow })],
    utilization_type: airframeErrorCode['utilization_type'][fieldValidation({ ...airframeErrorCode['utilization_typeObj'], fieldval: data.airframe.utilization_type })],
    utilization_as_of_date: airframeErrorCode['utilization_as_of_date'][fieldValidation({ ...airframeErrorCode['utilization_as_of_dateObj'], fieldval: data.airframe.utilization_as_of_date })],
    tsn:data.airframe.tsn === '' ? 'Please enter TSN':'',
    csn:data.airframe.csn === '' ? 'Please enter CSN':'',
    engineType:data.airframe.aircraftType ? airframeErrorCode['engineType'][fieldValidation({ ...airframeErrorCode['engineTypeObj'], fieldval: data.engine?.engineType })]  :''
  }
  if(data.airframe.utilization_type && data.airframe.utilization_type === 2){
    validationInputs = {
      ...validationInputs,
      planned_fh:data.airframe?.planned_fh.trim().length ? '':'Please enter Planned FH',
      planned_fc:data.airframe?.planned_fc.trim().length ? '':'Please enter Planned FC',
    }
  }
  if(data.airframe.aircraftType){
    // Check for Engine Validations
    engineError = data.engine.engineInfo.slice(0,data.engine.identical_data ? 1:data.engine.engineInfo.length).map(item => {
    return {
      tsn: item.tsn == '' ? 'Please enter TSN':'',
      csn: item.csn == '' ? 'Please enter CSN':'',
      as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
    }});
    engineError.map(data => {
      if(Object.keys(data).every((k) => { return data[k] === ''})){
        engineErrorFlag = false
        this.setState({engineError:engineError})
      }
      else{
        engineErrorFlag=true
        this.setState({engineError:engineError})
      }
    });

    // Check for Landing Gear Validations
    lgError = data.landing_gear.lgInfo.slice(0,data.landing_gear.identical_data ? 1:data.landing_gear.lgInfo.length).map(item => {
    return {
      lgcsn: item.csn === '' ? 'Please enter CSN':'',
      utilization_as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
    }});
    lgError.map(data => {
      if(Object.keys(data).every((k) => { return data[k] === ''})){
        lgErrorFlag = false
        this.setState({lgError:lgError})
      }
      else{
        this.setState({lgError:lgError})
      }
    });

    // Check for APU Validations
    if(data.airframe.aircraftType.has_apu && data.apuOptional){
      let apuValidations = {
        apuType: apuErrorCode['apuType'][fieldValidation({ ...apuErrorCode['apuTypeObj'], fieldval: data.apu.apuType })],
        hours: apuErrorCode['hours'][fieldValidation({ ...apuErrorCode['hoursObj'], fieldval: data.apu.hours })],
        utilization_as_of_date: apuErrorCode['utilization_as_of_date'][fieldValidation({ ...apuErrorCode['utilization_as_of_dateObj'], fieldval: data.apu.utilization_as_of_date })],
      }
      if(Object.keys(apuValidations).every((k) => { return apuValidations[k] === '' })) {
       apuErrorFlag = false
       this.setState({apuError:apuValidations})
      }else{
        this.setState({apuError:apuValidations})
      }
    }
    // Check for Propellar Validations
    if(data.airframe.aircraftType.has_propeller){
      propellerError = data.propeller.propellerInfo.slice(0,data.propeller.identical_data ? 1:data.propeller.propellerInfo.length).map(item => {
      return {
        proptsn: item.tsn === '' ? 'Please enter TSN':'',
        utilization_as_of_date: item.utilization_as_of_date ? '':'Please enter Utilization Date',
      }});
      propellerError.map(data => {
        if(Object.keys(data).every((k) => { return data[k] === ''})){
          propellerErrorFlag = false
          this.setState({propellerError:propellerError})
        }
        else{
          this.setState({propellerError:propellerError})
        }
      });
    }
  }
  if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })  && !lgErrorFlag && !engineErrorFlag && !apuErrorFlag) {
    let payload = Object.assign({}, data)
    // airframe
    if (payload.airframe) {
      payload = {
        ...payload,
        airframe: {
          ...payload.airframe,
          maintenance_program: payload.airframe.maintenance_program ? payload.airframe.maintenance_program.value : '',
          tsn: payload.airframe.tsn ? parseFloat(payload.airframe.tsn) : 0,
          csn: payload.airframe.csn ? parseInt(payload.airframe.csn) : 0,
        },
      }
      if (data.airframe.utilization_type == 2) {
        payload = {
          ...payload,
          airframe: {
            ...payload.airframe,
            planned_fc: parseInt(data.airframe.planned_fc),
            planned_fh: parseFloat(data.airframe.planned_fh)
          }
        }
      }
      if (data.airframe.utilization_type == 1) {
        payload = {
          ...payload,
          airframe: {
            ...payload.airframe,
            planned_fc: null,
            planned_fh: null
          }
        }
      }
      if (payload.airframe.seating_configuration) {
        payload = {
          ...payload,
          airframe: {
            ...payload.airframe,
            seating_configuration: payload.airframe.seating_configuration.value
          }
        }
      }
    }
    // generic
    if (payload.generic) {
      payload = {
        ...payload,
        generic: {
          ...payload.generic,
          mods: payload.generic.mods && payload.generic.mods.length ? payload.generic.mods.map(item => item.id) : [],
          mtow: payload.generic.mtow ? parseFloat(payload.generic.mtow) : '',
        }
      }
      if (payload.generic.lessee_id) {
        payload = {
          ...payload,
          generic: {
            ...payload.generic,
            lessee_id: payload.generic.lessee_id.id
          }
        }
      }
      delete payload['generic'].mtow_kgs
    }
    // engine
    if (payload.engine) {
      if (payload.engine.identical_data) {
        payload = {
          ...payload,
          engine: {
            ...payload.engine,
            engineInfo: payload.engine.engineInfo.filter(item => item.id == 1 ? {
              ...item,
              tsn: parseFloat(item.tsn),
              csn: parseInt(item.csn),
            } : item)
          }
        }
      }
       else {
        payload = {
          ...payload,
          engine: {
            ...payload.engine,
            engineInfo: payload.engine.engineInfo.filter(item => item.id <= payload.engine?.engineInfo?.length ? { ...item, tsn: parseFloat(item.tsn), csn: parseInt(item.csn) } : item)
          }
        }
      }
    }
    // apu
    if (Object.keys(payload.apu).length) {
      payload = {
        ...payload,
        apu: {
          ...payload.apu,
          hours: payload.apu.hours ? parseFloat(payload.apu.hours) : 0
        }
      }
    }
    if (!data.airframe.aircraftType.has_propeller) {
      delete payload.propeller
    }
    this.setState({pageLoader:true});
    globalPostService(`/valuation/half-life-adjustment/initiate/`, payload)
    .then(response => {
      this.setState({pageLoader:false});
      if (checkApiStatus(response)) {
        this.setState({ redirectToIntermediate: {flag:true, data:response.data.data} })
        // props.history.push(`/half-life/intermediate/${response.data.data.id}`)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
  }else{
    this.setState({airframeError:validationInputs, accordianState:true})
  }
}

export function updateHlaEventsApi(props, data){
  let apuError={}, airframeError=[], lgError=[], propellerError=[], engineError=[];
  let ovPermission = permissionCheckFn({secondaryKey:'hthl', keyIndex:'OV'});
  // Check for Airframe Validations
  if(ovPermission){
    airframeError = data.airframe.maintenance_events.map(item => {
    return {
      cost: item.cost ? '':'Please enter Cost',
      remarks: item.remarks ?'':'Please enter Remarks',
    }});
  }
  // Check for APU Validations
  if(data.airframe.aircraftType && data.airframe.has_apu){
    if(data.apu.hours_at_lsv || data.apu.cycles_at_lsv || data.apu.lsv_date){
      apuError = {
        ...apuError,
        hours_at_lsv:data.apu.hours_at_lsv?'':'Please enter Hours',
      }
    }
    if(ovPermission){
      apuError = {
        ...apuError,
        interval_fh:data.apu.interval_fh?'':'Please enter Interval',
        cost:data.apu.cost?'':'Please enter Cost',
        remarks:data.apu.remarks?'':'Please enter Remarks',
      }
    }
  }

  // Check for Landing Gear Validations
  lgError = data.landing_gear.map(item => {
    return {
      interval_fc: ovPermission?item.interval_fc ? '':'Please enter FC':'',
      interval_mo: ovPermission? item.interval_mo ? '':'Please enter MO':'',
      cost: ovPermission? item.cost ? '':'Please enter Cost':'',
      remarks: ovPermission ?item.remarks ?'':'Please enter Remarks':'',
      csn_at_lsv:(item.csn_at_lsv || item.lsv_date) ?item.csn_at_lsv === '' ? 'Please enter CSN @ LSV':'':'',
      lsv_date: (item.csn_at_lsv || item.lsv_date) ? item.lsv_date ? '':'Plese enter LSV Date':''
    }
  });

  propellerError = data.propeller.map(item => {
    return {
      interval_fh: ovPermission?item.interval_fc ? '':'Please enter FC':'',
      interval_mo: ovPermission? item.interval_mo ? '':'Please enter MO':'',
      cost: ovPermission? item.cost ? '':'Please enter Cost':'',
      remarks: ovPermission ?item.remarks ?'':'Please enter Remarks':'',
      tsn_at_lsv:(item.tsn_at_lsv || item.lsv_date) ?item.tsn_at_lsv === '' ? 'Please enter CSN @ LSV':'':'',
      lsv_date: (item.tsn_at_lsv || item.lsv_date) ? item.lsv_date ? '':'Plese enter LSV Date':''
    }
  });
}

export function getValuationHlaApi(props) {
  this.setState({pageLoader:true})
  globalGetService(`valuation/half-life-adjustment/logs/${props.match.params.slug}/`)
  .then(response => {
    this.setState({pageLoader:false})
    if (checkApiStatus(response)) {
      this.setState({halfLifeCrud:{...response.data.data, generic: {...response.data.data.generic,   mtow_kgs: (response.data.data.generic.mtow * 0.4535970244).toFixed(2)}}})
    }
  })
}
export function getValuationHlaResultApi(props) {
  return new Promise(function(resolve,reject){
    globalGetService(`valuation/half-life-adjustment/result/${props.match.params.slug}/`)
    .then(response => {
      resolve(response)
    })
  });
}
export function lockUnlockHlaApi(props, data){
  this.setState({pageLoader:true});
  globalPutService(`valuation/update-lock/`, data)
  .then(response => {
    this.setState({pageLoader:false});
    if(checkApiStatus(response)){
      this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      window.location.reload(false);
    }else{
      this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  })
}
export function exportHlaReportApi(props, query = {}) {
  this.setState({ pageLoader: true })
  globalExportService(`valuation/half-life-adjustment/result/${props.match.params.id}/`, { download: query.extension })
    .then(response => {
      trackActivity('Exported Result', {
        type: "Half Life Adjustment",
        log_id: props.match.params.id,
        extension: query.extension
      })
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`LogId_${this.props.match.params.id}_Maintenance_Adjusted_Base_Value_${moment().format(displayDateFormatShort)}`), query.extension);
    })
}
export function getEngineLLpListApi(props, engine, engineIndex, component, loaderLLpUpdates = false) {
  if (loaderLLpUpdates) {
    this.setState({ checkBoxLoader: true })
    this.setState({ pageLoader: true })
  }
  this.setState({ pageLoader: true })
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}`)
    .then(response => {
      this.setState({ pageLoader: false })
      this.setState({ checkBoxLoader: false })
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          llpModal: true,
          logDetailCopy: prevState.halfLifeCrud,
          secondaryLLpEnableFlag: response.data.data.secondary && response.data.data.secondary.length && response.data.data.secondary.every(llpItem => !llpItem.status) ? false : true,
          engineLLP: {
            ...response.data.data,
            primary:
              component == "overall" ?
                response.data.data.primary && response.data.data.primary.length ?
                  !engine.llp_csn_flag ?
                    response.data.data.primary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false }))
                    : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, status: response.data.data.is_wide_body ? true : llpItem.status })) : []
                :
                response.data.data.primary && response.data.data.primary.length ?
                  !engine.llp_csn_flag ?
                    response.data.data.primary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false }))
                    : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, status: response.data.data.is_wide_body ? true : llpItem.status })) : [],
            secondary:
              component == "overall" ?
                response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : []
                : response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
            static:
              component == "overall" ?
                response.data.data.static && response.data.data.static.length ? response.data.data.static.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv && engine.csn_at_lsv > engine.csn ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false })) : []
                : response.data.data.static && response.data.data.static.length ? response.data.data.static.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_hsi > engine.csn || engine.csn_at_lsv > engine.csn ? engine.csn_at_hsi > engine.csn_at_lsv ? engine.csn_at_hsi : engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false })) : []
          },
          engineLlpList: response.data.data.primary && response.data.data.primary.length ? !engine.llp_csn_flag ? response.data.data.primary.map(llpItem => ({ ...llpItem, csn: engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, llpCostFlag: false })) : response.data.data.primary.map(llpItem => ({ ...llpItem, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
          // halfLifeCrud: {
          //   ...prevState.halfLifeCrud,
          //   engine: {
          //     ...prevState.halfLifeCrud.engine,
          //     engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) =>
          //       component == "overall" ?
          //         index === engineIndex && !item.llp_csn_flag ?
          //           {
          //             ...item,
          //             tsn_as_of_llp: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.tsn_at_lsv : item.tsn,
          //             csn_as_of_llp: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.csn_at_lsv : item.csn,
          //             llp_status_as_of_date: item.csn_at_lsv && item.csn_at_lsv > item.csn ? item.last_event_date : item.utilization_as_of_date,
          //           } : item
          //         :
          //         {
          //           ...item,
          //           tsn_as_of_llp: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.tsn_at_lsv : item.tsn_at_hsi : item.tsn,
          //           csn_as_of_llp: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.csn_at_lsv : item.csn_at_hsi : item.csn,
          //           llp_status_as_of_date: (item.csn_at_lsv > item.csn || item.csn_at_hsi > item.csn) ? item.csn_at_lsv > item.csn_at_hsi ? item.last_event_date : item.last_hsi_event_date : item.utilization_as_of_date,
          //         }
          //     )
          //   }
          // }
        }))
      }
    })
}
export function engineIntermediateValidateFn(item, dom = null) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    serial_number_engine: hlaErrorCode['serial_number'][fieldValidation({ ...hlaErrorCode['serial_numberObj'], fieldval: item.serial_number })],
    tsnEngine: item.tsn === 0 ? '' : hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: item.tsn })],
    csnEngine: item.csn === 0 ? '' : hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: item.csn })],
    utilization_as_of_date_engine: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],
    tsn_at_prsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_prsv'][fieldValidation({ ...hlaErrorCode['tsn_at_prsvObj'], fieldval: item.tsn_at_lsv })],
    last_prsv_event_date: hlaErrorCode['last_prsv_event_date'][fieldValidation({ ...hlaErrorCode['last_prsv_event_dateObj'], fieldval: item.last_event_date })],

  }
  if (item.csn_at_lsv || item.last_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_prsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_prsv'][fieldValidation({ ...hlaErrorCode['tsn_at_prsvObj'], fieldval: item.tsn_at_lsv })],
    }
  }
  if (permissionCheckFn({secondaryKey:'hthl', keyIndex:'OV'})) {
    validationInputs = {
      ...validationInputs,
      interval_engine: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh })],
      cost_engine: hlaErrorCode['cost'][fieldValidation({ ...hlaErrorCode['costObj'], fieldval: item.cost })],
    }
    if (item.engineRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }

    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function updateEngineLogApi(props = {}, engine = {}, dom = null, validationFlag = false) {
   
  let validationInputs = {}
  if (!validationFlag) {
    engine.engineInfo.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: engineIntermediateValidateFn(item, dom),
      }
    })
  }
  let payload = {
    log_id: parseInt(props.match.params.id),
    engine: engine.engineInfo
  }
  payload = {
    ...payload,
    engine: engine.engineInfo.map((item, index) => ({
      ...item,
      tsn: item.tsn ? parseFloat(item.tsn) : 0,
      tsn_at_lsv: item.tsn_at_lsv ? parseFloat(item.tsn_at_lsv) : 0,
      csn: item.csn ? parseInt(item.csn) : 0,
      csn_at_lsv: item.csn_at_lsv ? parseInt(item.csn_at_lsv) : 0,
      last_performed_sv: item.last_performed_sv ? item.last_performed_sv.id : null,
      cost: item.cost ? parseFloat(item.cost) : 0,
      cost_hsi: item.cost_hsi ? parseFloat(item.cost_hsi) : 0,
      interval_fh: item.interval_fh ? parseFloat(item.interval_fh) : 0,
      interval_fh_hsi: item.interval_fh_hsi ? parseFloat(item.interval_fh_hsi) : 0,
      csn_as_of_llp: item.csn_as_of_llp ? parseInt(item.csn_as_of_llp) : 0,
      llp: null
    }))
  }
  if (Object.keys(removeEmptyKey(validationInputs)).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/engine/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          // this.getHlaLogApi(props)
          trackActivity('Engine Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          if (!validationFlag) {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          }
          this.setState({ enginesEdit: false, engineTabColor: 'primary' })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      engineTabColor: 'secondary',
      error: {
        ...prevState.error,
        engine: validationInputs,
      }
    }))
    if (validationInputs && validationInputs[0] && Object.keys(validationInputs[0]).length) {
      this.setState({ tabIndex: 0 })
    }
    if (validationInputs && validationInputs[1] && Object.keys(validationInputs[1]).length) {
      this.setState({ tabIndex: 1 })
    }
  }
}
export function updateEngineHsiLogApi(props = {}, engine = {}, dom = null, validationFlag = false) {
  let validationInputs = {}
  if (!validationFlag) {
    engine.engineInfo.map((item, index) => {
      validationInputs = {
        ...validationInputs,
        [index]: engineHsiIntermediateValidateFn(item, dom),
      }
    })
  }
  let payload = {
    log_id: parseInt(props.match.params.id),
    engine: engine.engineInfo
  }
  payload = {
    ...payload,
    engine: engine.engineInfo.map((item, index) => ({
      ...item,
      tsn: item.tsn ? parseFloat(item.tsn) : 0,
      csn: item.csn ? parseInt(item.csn) : 0,
      tsn_at_hsi: item.tsn_at_hsi ? parseFloat(item.tsn_at_hsi) : 0,
      csn_at_hsi: item.csn_at_hsi ? parseInt(item.csn_at_hsi) : 0,
      last_performed_hsi: item.last_performed_hsi ? item.last_performed_hsi.id : null,
      interval_fh_hsi: item.interval_fh_hsi ? item.interval_fh_hsi : null,
      cost_hsi: item.cost_hsi ? parseFloat(item.cost_hsi) : 0,
      tsn_at_lsv: item.tsn_at_lsv ? parseFloat(item.tsn_at_lsv) : 0,
      csn_at_lsv: item.csn_at_lsv ? parseInt(item.csn_at_lsv) : 0,
      last_performed_sv: item.last_performed_sv ? item.last_performed_sv.id : null,
      interval_fh: item.interval_fh ? parseFloat(item.interval_fh) : 0,
      cost: item.cost ? parseFloat(item.cost) : 0,
      csn_as_of_llp: item.csn_as_of_llp ? parseInt(item.csn_as_of_llp) : 0,
      llp: null,
    }))
  }
  if (Object.keys(removeEmptyKey(validationInputs)).every((k) => { return validationInputs[k] === '' })) {
    this.setState({ pageLoader: true })
    globalPutService('valuation/half-life-adjustment/engine/', payload)
      .then(response => {
        this.setState({ pageLoader: false })
        if (checkApiStatus(response)) {
          // this.getHlaLogApi(props)
          trackActivity('Engine HSI Specs Updated', {
            type: 'Half Life Adjustment',
            log_id: props.match.params.id
          })
          if (!validationFlag) {
            this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
          }
          this.setState({ enginesEdit: false, engineTabColor: 'primary' })
        } else {
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
      })
  } else {
    this.setState(prevState => ({
      ...prevState,
      engineTabColor: 'secondary',
      error: {
        ...prevState.error,
        engine: validationInputs,
      }
    }))
    if (validationInputs && validationInputs[0] && Object.keys(validationInputs[0]).length) {
      this.setState({ tabIndex: 0 })
    }
    if (validationInputs && validationInputs[1] && Object.keys(validationInputs[1]).length) {
      this.setState({ tabIndex: 1 })
    }
  }
}
export function engineHsiIntermediateValidateFn(item, dom = null) {
  let validationInputs = {}
  validationInputs = {
    ...validationInputs,
    serial_number_engine: hlaErrorCode['serial_number'][fieldValidation({ ...hlaErrorCode['serial_numberObj'], fieldval: item.serial_number })],
    tsnEngine: item.tsn === 0 ? '' : hlaErrorCode['tsn'][fieldValidation({ ...hlaErrorCode['tsnObj'], fieldval: item.tsn })],
    csnEngine: item.csn === 0 ? '' : hlaErrorCode['csn'][fieldValidation({ ...hlaErrorCode['csnObj'], fieldval: item.csn })],
    utilization_as_of_date_engine: hlaErrorCode['utilization_as_of_date'][fieldValidation({ ...hlaErrorCode['utilization_as_of_dateObj'], fieldval: item.utilization_as_of_date })],
  }
  if (item.csn_at_hsi || item.last_hsi_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_hsi: item.tsn_at_hsi === 0 ? '' : hlaErrorCode['tsn_at_hsi'][fieldValidation({ ...hlaErrorCode['tsn_at_hsiObj'], fieldval: item.tsn_at_hsi })],
    }
  }
  if (item.csn_at_lsv || item.last_event_date) {
    validationInputs = {
      ...validationInputs,
      tsn_at_lsv: item.tsn_at_lsv === 0 ? '' : hlaErrorCode['tsn_at_ovh'][fieldValidation({ ...hlaErrorCode['tsn_at_ovhObj'], fieldval: item.tsn_at_lsv })],
    }
  }
  if (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission['valuation'] && getLocalStorageInfo().user.permission['valuation']['hthl'] && getLocalStorageInfo().user.permission['valuation']['hthl'].indexOf('OV') != -1) {
    validationInputs = {
      ...validationInputs,
      interval_fh_hsi: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh_hsi })],
      cost_engine_hsi: hlaErrorCode['cost_hsi'][fieldValidation({ ...hlaErrorCode['cost_hsiObj'], fieldval: item.cost_hsi })],
      interval_fh: hlaErrorCode['interval'][fieldValidation({ ...hlaErrorCode['intervalObj'], fieldval: item.interval_fh })],
      cost_engine: hlaErrorCode['cost_hsi'][fieldValidation({ ...hlaErrorCode['cost_hsiObj'], fieldval: item.cost })],
    }
    if (item.engineRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks })],
      }
    }
    if (item.engineHsiRemarksFlag) {
      validationInputs = {
        ...validationInputs,
        remarks_engine_hsi: hlaErrorCode['remarks'][fieldValidation({ ...hlaErrorCode['remarksObj'], fieldval: item.remarks_hsi })]
      }
    }
  }
  if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
    return null
  } else {
    return validationInputs
  }
}
export function updateLLPApi(props, engine, llps, hlaLogDetail) {
  let payload = {}
  payload = {
    engineLLP: {
      ...llps,
      eng_log_id: engine.id,
      primary: llps.primary.map(llp => ({ ...llp, cycles_limit: parseInt(llp.cycles_limit), csn: parseInt(llp.csn), cost: parseFloat(llp.cost) }))
    }
  }
  this.setState({ pageLoader: true })
  globalPutService('valuation/half-life-adjustment/engine/llps/', payload)
    .then(response => {
       
      this.setState({ pageLoader: false })
      if (checkApiStatus(response)) {
        // this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState(prevState => ({
          ...prevState,
          llpTabIndex: 0
        }))
        this.setState({ llpModal: false })
        // window.location.reload();
        if (hlaLogDetail.generic.propeller_aircraft) {
          this.updateEngineHsiLogApi(props?.props, hlaLogDetail.engine, null, true)
        } else {
          this.updateEngineLogApi(props.props , hlaLogDetail.engine, null, true)
        }

      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
export function getSecondaryLLpListApi(props, engine, engineIndex, component) {
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}&llp=3`)
    .then(response => {
      if (checkApiStatus(response)) {
        this.setState(prevState => ({
          ...prevState,
          secondaryLLpEnableFlag: response.data.data.secondary && response.data.data.secondary.length && response.data.data.secondary.every(llpItem => !llpItem.status) ? false : true,
          engineLLP: {
            ...prevState.engineLLP,
            secondary: response.data.data.secondary && response.data.data.secondary.length ? response.data.data.secondary.map(llpItem => ({ ...llpItem, csn: llpItem.csn ? llpItem.csn : engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false })) : [],
          }
        }))
      }
    })
}
// get Static LLP List
export function getStaticLLpListApi(props, engine, engineIndex, component, llpId) {
  globalGetService(`valuation/half-life-adjustment/engine/llps/?engine-log=${engine.id}&llp=2`)
    .then(response => {
      if (checkApiStatus(response)) {
        response.data.data.static.map((stat, index) => {
          if (stat.id == llpId) {
            this.setState(prevState => ({
              ...prevState,
              engineLLP: {
                ...prevState.engineLLP,
                static: prevState.engineLLP.static && prevState.engineLLP.static.length ? prevState.engineLLP.static.map(llpItem => llpItem.id == llpId ? ({ ...stat, csn: stat.csn ? stat.csn : engine.csn_at_lsv ? engine.csn_at_lsv : engine.csn, llpCsnValFlag: false, lifeLimitvalFlag: false, llpCostFlag: false, llpChecked: false, checkBoxDisableFlag: false, checkBoxLoader: false }) : llpItem) : []
              }
            }))
          }
        })
      }
    })
}
// Update secondaryLLP
export function secondaryllpUpdateApi(props, engine, engineIndex, component, data) {
  this.setState({checkBoxLoader:true})
  globalPutService(`valuation/half-life-adjustment/engine/llp_status/`, data)
  .then(response => {
    if (checkApiStatus(response)) {
        this.setState({checkBoxLoader:false})
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.setState({ secondaryLLpEnableFlag: data.secondary })
        this.getSecondaryLLpListApi(props, engine, engineIndex, component)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
// Update Static LLP
export function staticLLpUpdateApi(props, engine, engineIndex, component, data) {
  globalPutService(`valuation/half-life-adjustment/engine/llp_status/`, data)
    .then(response => {
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        this.getStaticLLpListApi(props, engine, engineIndex, component, data.static_id)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
      }
    })
}
// Export LLP
export function exportLLPApi(props = {}, engine, query = {}) {
  this.setState({ pageLoader: true })
  globalExportService(`valuation/half-life-adjustment/engine/${engine.id}/llp/export/`, { download: query.extension })
    .then(response => {
      this.setState({ pageLoader: false });
      downloadFileType(response.data, (`LOG_ID_${props.match.params.id}_LLP_${moment().format(backendDateFormat)}`), query.extension);
      trackActivity('Exported Engine LLP', {
        type: 'Engine LLP',
        log_id: props.match.params.id
      })
    })
}
export function importLLPApi(file, engine, engineIndex, props, component) {
  let formData = new FormData()
  formData.append('file', file[0])
  this.setState({ pageLoader: true });
  globalPostService(`valuation/half-life-adjustment/engine/${engine.id}/llp/import/`, formData)
    .then(response => {
      this.setState({ pageLoader: false });
      if (checkApiStatus(response)) {
        this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ confirmationModal: false })
        this.getEngineLLpListApi(props, engine, engineIndex, component)
      } else {
        this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        this.setState({ confirmationModal: false })
      }
    })
}
import { errorMsg1, regexConstants } from "../../constants/regEx";

export const lgTypesObj = {
  3:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'LH MLG', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'RH MLG', position:3, tsn:'',csn:'',utilization_as_of_date:null},
  ],
  4:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'Centre', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'LH MLG', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'RH MLG', position:4, tsn:'',csn:'',utilization_as_of_date:null}
  ],
  5:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WLH', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BLH', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WRH', position:4, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BRH', position:5, tsn:'',csn:'',utilization_as_of_date:null}
  ],
  6:[
    {label:'NLG', position:1, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'Centre', position:2, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WLH', position:3, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BLH', position:4, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'WRH', position:5, tsn:'',csn:'',utilization_as_of_date:null},
    {label:'BRH', position:6, tsn:'',csn:'',utilization_as_of_date:null}
  ]
}
export const staticLLpHeads = [
  { label: 'Click to Enable or Disable', sortOption: false, editable:true },
  { label: 'Module', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Life Limit', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: 'Cycles Since New', sortOption: false },
  { label: 'LLP Cost', sortOption: false },
  { label: 'Revision History', sortOption: false }
]
export const llpHlaHeads = [
  { label: 'Module', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Life Limit', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: 'Cycles Since New', sortOption: false },
  { label: 'LLP Cost', sortOption: false },
  { label: 'Revision History', sortOption: false }
]
export const secondaryLLpHeads = [
  { label: 'Module', sortOption: false },
  { label: 'Description', sortOption: false },
  { label: 'Life Limit', sortOption: false },
  { label: 'Cycles Remaining', sortOption: false },
  { label: 'Cycles Since New', sortOption: false },
  { label: 'LLP Cost', sortOption: false },
  { label: 'Revision History', sortOption: false }
]
export const componentBreakdownHd = [
  {label:'Component', sortOption:false},
  {label:'Event', sortOption:false},
  {label:'Next Due', sortOption:false},
  {label:'Full Life Remaining', sortOption:false},
  {label:'Half Life Remaining', sortOption:false},
  {label:'Half Life Maintenance Adjustment', sortOption:false},
  {label:'Final Values in Million', sortOption:false},
];
export const componentBreakdownHd1 = [
  {label:'Component', sortOption:false},
  {label:'Event', sortOption:false},
  {label:'Last Event Before Forecast', sortOption:false},
  {label:'Full Life Remaining', sortOption:false},
  {label:'Half Life Remaining', sortOption:false},
  {label:'Half Life Maintenance Adjustment', sortOption:false},
  {label:'Final Values in Million', sortOption:false},
  {label:'Next Event after Forecast Date', sortOption:false}
];
export const engineCrud = {
  engineType:null,
  identical_data:true,
  no_of_engines:1,
  engineInfo:[
    {id:1, tsn:'', csn:'', utilization_as_of_date:null}
  ]
}
export const propellerCrud = {
  identical_data:true,
  propellerInfo:[
    {id:'', position:'', tsn:'', csn:'',utilization_as_of_date:null}
  ]
}
export const apuCrud = {
  apuType:null,
  hours:'',
  apu_hours:'',
  utilization_as_of_date:null
}
export const landing_gearCrud = {
  lg_utilization_flag:true,
  mlg_utilization_flag:false,
  lgInfo:[
    {id:'',tsn:'',csn:'',utilization_as_of_date:null,position:1}
  ]
}
export const hlaErrorCode = {
  serial_number: {
    0: '',
    4: errorMsg1
  },
  serial_numberObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  aircraft_type: {
    0: "",
    1: 'Please select Aircraft Type'
  },
  aircraft_typeObj: {
    required: true
  },
  maintenance_program: {
    0: '',
    1: 'Please select Maintenance Program'
  },
  maintenance_programObj: {
    required: true
  },
  date_of_manufacture: {
    0: '',
    1: 'Please enter Date of Manufacture',
    5: 'Please enter Valid Date'
  },
  date_of_manufactureObj: {
    required: true,
    datePattern: true
  },
  report_date: {
    0: '',
    1: 'Please enter Report Date',
    5: 'Please enter Valid Date'
  },
  report_dateObj: {
    required: true,
    datePattern: true
  },
  mtow: {
    0: '',
    1: 'Please enter MTOW'
  },
  mtowObj: {
    required: true
  },
  mtow_kgs: {
    0: '',
    1: 'Please enter MTOW in Kg'
  },
  mtow_kgsObj: {
    required: true
  },
  utilization_as_of_date: {
    0: '',
    1: 'Please enter Utilization as of Date',
    5: 'Please enter Valid Date'
  },
  utilization_as_of_dateObj: {
    required: true,
    datePattern: true
  },
  tsn: {
    0: '',
    1: 'Please enter TSN'
  },
  tsnObj: {
    required: true
  },
  csn: {
    0: '',
    1: 'Please enter CSN'
  },
  csnObj: {
    required: true
  },
  engine_type: {
    0: '',
    1: 'Please select Engine Type'
  },
  engine_typeObj: {
    required: true
  },
  region_of_operation: {
    0: '',
    1: 'Please select Region of Operation'
  },
  region_of_operationObj: {
    required: true
  },
  apu_type: {
    0: '',
    1: 'Please select APU Type'
  },
  apu_typeObj: {
    required: true
  },
  apu_hours: {
    0: '',
    1: 'Please enter TSN'
  },
  apu_hoursObj: {
    required: true
  },
  planned_fc: {
    0: '',
    1: 'Please enter Annual Planned FC'
  },
  planned_fcObj: {
    required: true
  },
  planned_fh: {
    0: '',
    1: 'Please enter Annual Planned FH'
  },
  planned_fhObj: {
    required: true
  },
  tsn_at_prsv: {
    0: '',
    1: 'Please enter TSN at Last PRSV'
  },
  tsn_at_prsvObj: {
    required: true
  },
  csn_at_prsv: {
    0: '',
    1: 'Please enter CSN at Last PRSV'
  },
  csn_at_prsvObj: {
    required: true
  },
  last_prsv_event_date: {
    0: '',
    1: 'Please enter Last PRSV Event Date',
    5: 'Please enter Valid Date'
  },
  last_prsv_event_dateObj: {
    datePattern: true
  },
  apu_hours_lsv: {
    0: '',
    1: 'Please enter APU Hours at LSV'
  },
  apu_hours_lsvObj: {
    required: true
  },
  apu_cycles_lsv: {
    0: '',
    1: 'Please enter APU Cycles at LSV'
  },
  apu_cycles_lsvObj: {
    required: true
  },
  apu_lsv_date: {
    0: '',
    1: 'Please enter LSV Date',
    5: 'Please enter Valid Date'
  },
  apu_lsv_dateObj: {
    required: true,
    datePattern: true
  },
  tsn_at_lsv: {
    0: '',
    1: 'Please enter TSN at LSV'
  },
  tsn_at_lsvObj: {
    required: true
  },
  csn_at_lsv: {
    0: '',
    1: 'Please enter CSN at LSV'
  },
  csn_at_lsvObj: {
    required: true
  },
  airframe_lsv_date: {
    0: '',
    1: 'Please enter LSV Date',
    5: 'Please enter Valid Date'
  },
  airframe_lsv_dateObj: {
    datePattern: true
  },
  cost: {
    0: '',
    1: 'Please enter Event Cost'
  },
  costObj: {
    required: true
  },
  remarks: {
    0: '',
    1: 'Please enter Remarks'
  },
  remarksObj: {
    required: true
  },
  interval: {
    0: '',
    1: 'Please enter Interval'
  },
  intervalObj: {
    required: true
  },
  interval_fc: {
    0: '',
    1: 'Please enter Interval FC'
  },
  interval_fcObj: {
    required: true
  },
  interval_mo: {
    0: '',
    1: 'Please enter Interval MO'
  },
  interval_moObj: {
    required: true
  },
  interval_fh: {
    0: '',
    1: 'Please enter interval FH'
  },
  interval_fhObj: {
    required: true
  },
  last_hsi_event_date: {
    0: '',
    1: 'Please enter HSI Event Date',
    5: 'Please enter Valid Date'
  },
  last_hsi_event_dateObj: {
    required: true,
    datePattern: true
  },
  last_event_date: {
    0: '',
    1:'Please enter Last Event Date',
    5: 'Please enter Valid Date'
  },
  last_event_dateObj: {
    datePattern: true
  },
  tsn_at_hsi: {
    0: '',
    1: 'Please enter TSN at Last HSI'
  },
  tsn_at_hsiObj: {
    required: true
  },
  tsn_at_ovh: {
    0: '',
    1: 'Please enter TSN at Last OVH'
  },
  tsn_at_ovhObj: {
    required: true
  },
  cost_hsi: {
    0: '',
    1: 'Please enter Cost'
  },
  cost_hsiObj: {
    required: true
  },
  forecast_date: {
    0: '',
    1: 'Please enter Forecast Date',
    5: 'Please enter Valid Date'
  },
  forecast_dateObj: {
    required: true,
    datePattern: true
  }

}
export const hlaCrud = {
  apuOptional:true,
  airframe: {
    aircrafType: null,
    maintenance_program:null,
    seating_configuration:null,
    utilization_as_of_date:null,
    tsn:0,
    csn:0,
    serial_number:'',
    utilization_type:1,
    planned_fc:0,
    planned_fh:0
  },
  generic:{
    asset_type:1,
    date_of_manufacture:null,
    lessee_id:null,
    mods:[],
    mtow:'',
    mtow_kgs:'',
    report_date:null
  }
}
export const airframeErrorCode = {
  aircraftType:{
    0:"",
    1:'Please select Aircraft Type'
  },
  aircraftTypeObj:{
    required:true
  },
  engineType:{
    0:"",
    1:'Please select Engine Type'
  },
  engineTypeObj:{
    required:true
  },
  maintenance_program:{
    0:"",
    1:'Please select Maintenance Program'
  },
  maintenance_programObj:{
    required:true
  },
  date_of_manufacture:{
    0:"",
    1:'Please enter Date of Manufacture',
    5:'Please enter valid Date of Manufacture'

  },
  date_of_manufactureObj:{
    required:true,
    datePattern:true
  },
  report_date:{
    0:"",
    1:'Please enter Report Date',
    1:'Please enter valid Report Date'
  },
  report_dateObj:{
    required:true,
    datePattern:true
  },
  mtow:{
    0:"",
    1:'Please enter MTOW'
  },
  mtowObj:{
    required:true
  },
  utilization_type:{
    0:"",
    1:'Please select Utilization Type'
  },
  utilization_typeObj:{
    required:true
  },
  planned_fh:{
    0:"",
    1:'Please enter Planned FH'
  },
  planned_fhObj:{
    required:true
  },
  planned_fc:{
    0:"",
    1:'Please enter Planned FC'
  },
  planned_fcObj:{
    required:true
  },
  tsn:{
    0:"",
    1:'Please enter TSN'
  },
  tsnObj:{
    required:true
  },
  csn:{
    0:"",
    1:'Please enter CSN'
  },
  csnObj:{
    required:true
  },
  utilization_as_of_date:{
    0:"",
    1:'Please enter Utilization Date',
    5:'Please enter valid Utilization Date'
  },
  utilization_as_of_dateObj:{
    required:true,
    datePattern:true
  },
}
export const apuErrorCode = {
  apuType:{
    0:"",
    1:'Please select APU Type'
  },
  apuTypeObj:{
    required:true
  },
  hours:{
    0:"",
    1:'Please select APU Hours'
  },
  hoursObj:{
    required:true
  },
  utilization_as_of_date:{
    0:"",
    1:'Please enter Utilization Date',
    5:'Please enter valid Utilization Date'
  },
  utilization_as_of_dateObj:{
    required:true,
    datePattern:true
  },
}

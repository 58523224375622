import React, { Component, Fragment } from 'react'
import { Tabs, Tab, Paper, Grid, TextField, Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip, InputAdornment, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Divider, Checkbox, FormGroup } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { withSnackbar } from 'notistack';
import { LabelValueCard, TableListComp, EmptyCollection, PageLoader } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import ConfirmationModal from './ConfirmationModal';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { regexConstants } from '../../../constants/regEx';
import { fieldDateFormat, backendDateFormat, displayDateFormatShort } from '../../../constants';
import NumericalLabel from 'react-pretty-numbers';
import { getCurrencyFormat, getLocalStorageInfo, permissionCheckFn } from '../../../utils'
import { exportLLPApi, getEngineLLpListApi, updateLLPApi, importLLPApi, updateEngineLogApi, updateEngineHsiLogApi, secondaryllpUpdateApi, getSecondaryLLpListApi, staticLLpUpdateApi, getStaticLLpListApi } from '../apiServices';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import ImportIcon from '@material-ui/icons/Publish';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import EngineLLPList from './EngineLLPList';
import LLPTabs from './LLPTabs';
import SecondaryLLPList from './SecondaryLLPList';
import StaticLLPList from './StaticLLPList';
import { llpHlaHeads, secondaryLLpHeads, staticLLpHeads } from '..';
class LlpPopUpModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            llpTabName: 'PRIMARY LLP',
            llpTabIndex:0,
            logDetailCopy:{},
            pageLoader:false,
            llpSecondaryCostSum:0,
            llpStaticCostSum:0,
            secondaryFilterHeads:[],
            staticLLpHead:[],
        }
        this.exportLLPApi = exportLLPApi.bind(this);
        this.getEngineLLpListApi = getEngineLLpListApi.bind(this);
        this.updateLLPApi = updateLLPApi.bind(this);
        this.importLLPApi = importLLPApi.bind(this);
        this.updateEngineLogApi = updateEngineLogApi.bind(this);
        this.updateEngineHsiLogApi = updateEngineHsiLogApi.bind(this);
        this.secondaryllpUpdateApi = secondaryllpUpdateApi.bind(this);
        this.getSecondaryLLpListApi = getSecondaryLLpListApi.bind(this);
        this.staticLLpUpdateApi = staticLLpUpdateApi.bind(this)
        this.getStaticLLpListApi = getStaticLLpListApi.bind(this)
    }
    componentDidMount() {
        const { hlaLogDetail, engineIndex, component } = this.props
        this.getEngineLLpListApi(this.props, hlaLogDetail.engine.engineInfo[engineIndex], engineIndex, component)
    }
    changeLLPTabs = (event, newValue, tabLabel) => {
        const { engineLLP, llpTabIndex, staticLLpHead } = this.state
        this.setState(prevState => ({
            ...prevState,
            llpTabIndex: newValue,
            llpTabName: tabLabel
        }))
        if (newValue == 1) {
            const llpSecondaryArr = engineLLP?.secondary?.length ? engineLLP?.secondary?.map(item => item.status && item.cost ? parseFloat(item.cost) : 0) : []
            this.setState({ llpSecondaryCostSum: llpSecondaryArr.length ? llpSecondaryArr.reduce((a, b) => a + b, 0).toFixed(2) : 0 })
            this.setState({ secondaryFilterHeads:secondaryLLpHeads.filter(item => !['LLP Cost'].includes(item.label))})
        }
        if(newValue ==2){  
            if( !this.props.enginesEdit){
                this.setState({staticLLpHead: staticLLpHeads.map((item,index)=> item?.editable    ? {label:''}: item )})
            } 
            else{
                this.setState({staticLLpHead: staticLLpHeads})
            }   
            const llpStaticArr = engineLLP?.static?.length ? engineLLP?.static?.map(item => item.status && item.cost ? parseFloat(item.cost) : 0) : []
            this.setState({ llpStaticCostSum: llpStaticArr.length ? llpStaticArr.reduce((a, b) => a + b, 0).toFixed(2) : 0})
            this.setState({staticLLpFilterHeads:staticLLpHead.filter(item => !['LLP Cost'].includes(item.label))})
        }
    }
    handleLLpImport = (e) => {
        this.setState(prevState => ({
            ...prevState,
            files: e.target.files,
            confirmationModal: true
        }))
        setTimeout(() => this.handleEventValue(e), 6000);
    }
    updateSecondaryLLp =(props, engine, engineIndex, component, data) => {
        this.secondaryllpUpdateApi(props, engine, engineIndex, component, data) 
    }
    handleEventValue = (e) => {
        let a = ""
        e.target.value = a
    }
    updateLlpCsn = (e, value, engineIndex) => {
        this.props.updateEngineField(e, 'csn_as_of_llp', value, engineIndex)
        this.setState(prevState => ({
            ...prevState,
            llpCsnValidationFlag: !value ? true : false,
            engineLLP: {
                ...prevState.engineLLP,
                primary: prevState.engineLLP.primary.map(item => ({ ...item, csn: value, llpCsnValFlag: !value ? true : false })),
                secondary: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.secondary.length ? prevState.engineLLP.secondary.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
                static: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.static.length ? prevState.engineLLP.static.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
            },
        }))
    }
    onChangeLLP = (event, keyParam, value, llpIndex, engineIndex, llpSection) => {
        this.setState(prevState => ({
            ...prevState,
            llpCsnValidationFlag: llpSection == "primary" && !value ? true : false,
            llpSecondaryValidationFlag: llpSection == "secondary" && !value ? true : false,
            llpStaticValidationFlag: llpSection == "static" && !value ? true : false,
            engineLLP: {
                ...prevState.engineLLP,
                [llpSection]: prevState.engineLLP[llpSection].map((llpItem, index) => index == llpIndex ? ({
                    ...llpItem,
                    [keyParam]: value,
                    llpCsnValFlag: keyParam != 'cost' ? !value ? true : false : false,
                    llpCostFlag: keyParam == "cost" ? !value ? true : false : false,
                    lifeLimitvalFlag: keyParam == "cycles_limit" && !value ? true : false,
                }) : llpItem),
            },
        }))
    }
    onSave =()=>{
        const {engineLLP}= this.state
        const {engine, hlaLogDetail}= this.props
            if(engineLLP?.static?.map(item => parseInt(item.cycles_limit) - parseInt(item.csn) < 0)?.includes(true) ||engineLLP?.secondary?.map(item => parseInt(item.cycles_limit) - parseInt(item.csn) < 0)?.includes(true)){
                this.props.enqueueSnackbar('Please re-check the values', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            }
            else{
                this.updateLLPApi(this.props, engine, engineLLP, hlaLogDetail);
            }  
    }
    render() {
        const { engineLLP, llpSecondaryValidationFlag, llpCsnValidationFlag, llpStaticValidationFlag, llpTabIndex, llpTabName, pageLoader, checkBoxLoader, files, confirmationModal, secondaryLLpEnableFlag, llpSecondaryCostSum, llpStaticCostSum, secondaryFilterHeads, staticLLpHead } = this.state
        const { closeLLLpModal, hlaLogDetail, engine, llpModal, tabIndex, engineLlpList, enginesEdit, skeletonLoader, secondaryLlpList, staticLlpList, error, engineIndex, updateEngineField, secondaryllpUpdate, staticLLpUpdate, selectedStaticLLps, updateEngineLog, props } = this.props
        // primary llp sum
        const llpcostArr = engineLLP?.primary?.map(item => parseFloat(item.cost))
        const llpCostSum = llpcostArr?.reduce((a, b) => a + b, 0).toFixed(2)
        let flagCheckArry = engineLLP?.primary?.map(item => parseInt(item.cycles_limit) - parseInt(item.csn) < 0)      
        let filteredHeads = llpHlaHeads.filter(item => !['LLP Cost'].includes(item.label))
        return (
            <div>
                <Dialog
                    aria-labelledby="scroll-dialog-title"
                    open={true}
                    fullWidth
                    fullScreen
                >
                    <DialogTitle id="scroll-dialog-title" style={{ padding: '0px' }}>
                        <Grid container spacing={isMobile ? 1 : 3} style={{ padding: '12px 24px' }}>
                            <Grid item xs={1} style={{paddingBottom:'0px'}}>
                                {tabIndex == 0 ? <h4 style={{marginTop:"18px"}}>Engine #1</h4> : <h4>Engine #2</h4>}
                            </Grid>
                            {!enginesEdit ?
                                <Fragment>
                                    <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='Serial Number' value={engine.serial_number ? engine.serial_number : '--'} />
                                    <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='TSN as of LLP Status (FH)' value={engine.tsn_as_of_llp ? `${engine.tsn_as_of_llp} FH` : '--'} />
                                    <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='CSN as of LLP Status (FC)' value={engine.csn_as_of_llp ? `${engine.csn_as_of_llp} FC` : '--'} />
                                    <LabelValueCard skeletonLoader={skeletonLoader} xs={12} md={2} label='LLP Status as of Date' value={engine.llp_status_as_of_date ? moment(engine.llp_status_as_of_date).format(displayDateFormatShort) : '--'} />
                                </Fragment> :
                                <Fragment>
                                    <LabelValueCard classes={'margin-top5'} skeletonLoader={skeletonLoader} xs={12} md={2} label='Serial Number' value={engine.serial_number ? engine.serial_number : '--'} />
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            id="tsn_as_of_llp"
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                            label={
                                                <p>TSN as of LLP Status
                                                    <Tooltip
                                                        title="Time Since New at LLP Status"
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                                                    </Tooltip>
                                                </p>
                                            }
                                            fullWidth
                                            margin="normal"
                                            value={engine.tsn_as_of_llp}
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value) ? updateEngineField(e, 'tsn_as_of_llp', e.target.value, engineIndex) : e.preventDefault}
                                            error={error && error.serial_number ? true : false}
                                            onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                            helperText={error && error.serial_number ? error.serial_number : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">FH</InputAdornment>,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <TextField
                                            id="csn_as_of_llp"
                                            label={
                                                <p>CSN as of LLP Status
                                                    <Tooltip
                                                        title="Cycles Since New at LLP Status"
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <InfoIcon style={{ fontSize: '18px', marginLeft: '3px' }} />
                                                    </Tooltip>
                                                </p>
                                            }
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{ shrink: true, style: { pointerEvents: "auto" } }}
                                            value={engine.csn_as_of_llp}
                                            onChange={(e) => regexConstants.onlyNumeric.test(e.target.value) ? this.updateLlpCsn(e, e.target.value, engineIndex)  : e.preventDefault()}
                                            error={error && error.serial_number ? true : false}
                                            onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                            helperText={error && error.serial_number ? error.serial_number : ''}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">FC</InputAdornment>,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                margin="normal"
                                                id="llp_as_of_date"
                                                label="LLP Status as of Date"
                                                placeholder="DD-MM-YYYY"
                                                fullWidth
                                                minDate={moment(hlaLogDetail.airframe?.date_of_manufacture)}
                                                maxDate={hlaLogDetail.generic?.report_date ? moment(hlaLogDetail.generic.report_date) : moment()}
                                                format={fieldDateFormat}
                                                InputLabelProps={{ shrink: true }}
                                                onBlur={(e) => updateEngineField(e, 'llp_csn_flag', true, engineIndex)}
                                                value={engine.llp_status_as_of_date ? engine.llp_status_as_of_date : null}
                                                onChange={(data, value) => updateEngineField(value, 'llp_status_as_of_date', data ? moment(data).format(backendDateFormat) : data, engineIndex)}
                                                inputVariant="outlined"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Fragment>
                            }
                        </Grid>
                        <Divider varient='fullWidth' />
                        <Grid container spacing={0} style={{ padding: '4px 24px 0px 24px' }}>
                            <Grid item xs={12} md={8}>
                                <LLPTabs llpTabIndex={llpTabIndex} changeTabs={this.changeLLPTabs} SecondaryLLPList={secondaryLlpList} staticLlpList={staticLlpList} />
                            </Grid>
                            {
                                permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'EXP_PDF' }) ?
                                    <Grid item xs={12} md={4}>
                                        <ul className="list-inline import-export-mobile" style={{ float: 'right', position: 'relative', top: '5px' }}>
                                            {enginesEdit ?
                                                <li className="list-inline-item" style={{ marginRight: '10px' }}>
                                                    <Button color="primary" variant="contained" style={{ position: 'relative' }} size="small"><ImportIcon />Import
                                                        <input type="file" id="file" className="custom-file-input" accept='.xls, .xlsx, .csv' style={{ position: 'absolute', padding: '10px 0px', cursor: 'pointer', opacity: '0' }} onChange={(e) => { this.handleLLpImport(e); }} />
                                                    </Button>
                                                </li> : null}
                                            <li className="list-inline-item">
                                                <Button onClick={() => this.exportLLPApi(props, hlaLogDetail.engine.engineInfo[engineIndex], { extension: 'xlsx' })} color="primary" variant="contained" size="small" ><SaveAltIcon /> Export</Button>
                                            </li>
                                        </ul>
                                    </Grid> : null
                            }
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        {pageLoader ? <STableLoader count={6} /> :
                            llpTabIndex == 0 ?
                                <TableListComp
                                    heads={(permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ||
                                        [13].includes(getLocalStorageInfo().defaultLessor.id)) ? llpHlaHeads : filteredHeads}
                                    data={engineLLP?.primary?.map((item, index) =>
                                        <EngineLLPList
                                            item={item}
                                            key={index}
                                            enginesEdit={enginesEdit}
                                            onChangeLLP={this.onChangeLLP}
                                            llpIndex={index}
                                            engineIndex={engineIndex}
                                            updateEngineField={updateEngineField}
                                        />)}
                                /> : null
                        }
                        {pageLoader ? <STableLoader count={6} /> :
                            llpTabName?.includes('SECONDARY LLP') && llpTabIndex != 0 ?
                                <Fragment>
                                    {enginesEdit && engineLLP?.secondary?.length ?
                                        <div className="checkbox-block">
                                            <FormGroup row>
                                                <FormControlLabel
                                                    control={
                                                        checkBoxLoader ? <CircularProgress color="primary" size={32} style={{ margin: '5px' }} /> :
                                                            <Checkbox
                                                                name="checkedA"
                                                                color="primary"
                                                                onChange={(event) => this.updateSecondaryLLp(props, engine, engineIndex, 'overall', { engine_log: engine.id, secondary: event.target.checked })}
                                                                checked={secondaryLLpEnableFlag}
                                                            />
                                                    }
                                                    label={checkBoxLoader ? 'Loading..' : engineLLP?.secondary?.every(llpItem => llpItem.status) ? "Disable All" : 'Enable All'}
                                                />
                                            </FormGroup>
                                        </div> : null}
                                    <TableListComp
                                        heads={(permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ||
                                            [13].includes(getLocalStorageInfo().defaultLessor.id)) ? secondaryLLpHeads : secondaryFilterHeads}
                                        data={engineLLP?.secondary?.map((item, index) =>
                                            <SecondaryLLPList
                                                item={item}
                                                key={index}
                                                enginesEdit={enginesEdit}
                                                onChangeLLP={this.onChangeLLP}
                                                llpIndex={index}
                                                engineIndex={engineIndex}
                                                // cyclesRemaining={cyclesRemaining}
                                            />)}
                                        noRecord={engineLLP?.secondary?.length ? null :
                                            <EmptyCollection
                                                title="No records found"
                                            />
                                        }
                                    /> </Fragment> : null
                        }
                        {pageLoader ? <STableLoader count={6} /> :
                            llpTabName.includes('STATIC LLP') && llpTabIndex != 0 ?
                                <TableListComp
                                    heads={(permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) || [13].includes(getLocalStorageInfo().defaultLessor.id)) ? staticLLpHead : this.state?.staticLLpFilterHeads}
                                    data={engineLLP?.static?.map((item, index) =>
                                        <StaticLLPList
                                            item={item}
                                            key={index}
                                            toggleBulkOps={(llpId, event) => this.staticLLpUpdateApi(this.props, engine, engineIndex, 'overall', { engine_log: engine.id, static_id: llpId, status: event.target.checked })}
                                            // toggleBulkOps={(llpId, event) => staticLLpUpdate(llpId, event, engine, engineIndex, 'overall')}
                                            enginesEdit={enginesEdit}
                                            onChangeLLP={this.onChangeLLP}
                                            llpIndex={index}
                                            engineIndex={engineIndex}
                                            // cyclesRemaining={cyclesRemaining}
                                            engine={engine}
                                            checkBoxLoader={checkBoxLoader}
                                        />)}
                                    noRecord={engineLLP?.static?.length ? null :
                                        <EmptyCollection
                                            title="No records found"
                                        />
                                    }
                                /> : null
                        }
                    </DialogContent>
                    <DialogActions>
                        {
                            (permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ||
                                [13].includes(getLocalStorageInfo().defaultLessor.id)) && llpTabIndex == 0 ?
                                <p style={{ marginRight: '15px' }}>Total Primary LLP Cost: <span style={{ fontWeight: 'bold' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{llpCostSum ? llpCostSum:0}</NumericalLabel></span></p>
                                : null
                        }
                        {
                            (permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ||
                                [13].includes(getLocalStorageInfo().defaultLessor.id)) && llpTabIndex == 1 ?
                                <p style={{ marginRight: '15px' }}>Total Secondary LLP Cost: <span style={{ fontWeight: 'bold' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{llpSecondaryCostSum? llpSecondaryCostSum:0}</NumericalLabel></span></p> : null
                        }
                        {
                            (permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'OV' }) ||
                                [13].includes(getLocalStorageInfo().defaultLessor.id)) && llpTabIndex == 2 ?
                                <p style={{ marginRight: '15px' }}>Total Static LLP Cost: <span style={{ fontWeight: 'bold' }}><NumericalLabel params={getCurrencyFormat({ shortFormat: false })}>{llpStaticCostSum? llpStaticCostSum:0}</NumericalLabel></span></p> : null
                        }
                        <div>
                            {
                                enginesEdit ?
                                    <Button className="btn-save" variant="contained" disabled={(enginesEdit && (flagCheckArry?.includes(true) )) || llpCsnValidationFlag || llpStaticValidationFlag || llpSecondaryValidationFlag} style={(enginesEdit && flagCheckArry?.includes(true)) ? { display: 'inline-block', marginLeft: '28px' } : {}} color="primary" onClick={() => {this.onSave() }}>Save</Button>
                                    // <Button className="btn-save" variant="contained" disabled={(enginesEdit && (flagCheckArry?.includes(true) || flagSecondaryCheckArry?.includes(true) || flagStaticCheckArry?.includes(true))) || llpCsnValidationFlag || llpStaticValidationFlag || llpSecondaryValidationFlag} style={(enginesEdit && flagCheckArry?.includes(true)) ? { display: 'inline-block', marginLeft: '28px' } : {}} color="primary" onClick={() => updateLLP(engine)}>Save</Button>
                                    : null
                            }
                            <Button variant="outlined" color="primary" onClick={closeLLLpModal} style={{ marginLeft: '5px' }}>Close</Button>
                            {(enginesEdit && (flagCheckArry?.includes(true) )) ? <span style={{ fontSize: '10px', clear: 'both', display: 'block', }}>Please enter valid CSN to close</span> : null}
                        </div>
                    </DialogActions>
                </Dialog>
                {pageLoader ? <PageLoader/> :null}
                <ConfirmationModal
                    modal={confirmationModal}
                    toggleModalFn={()=>this.setState({ confirmationModal: false })}
                    importLLP={() => this.importLLPApi(files, engine, engineIndex, this.props, 'overall')}
                />
            </div>
        )
    }
}
export default withSnackbar(LlpPopUpModal)
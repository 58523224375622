import React, { Fragment, useEffect, useState } from 'react';
import { Button, FormControl, FormLabel, FormControlLabel, FormGroup, RadioGroup, Radio } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const EventWrapper = ({children, label, identical_data, assemblyText, multiEvents, onFieldChange, flag}) => {
  const [modal, setModal] = useState(flag);
  useEffect(()=>{
    setModal(flag)
  },[flag])
  return(
    <div>
      <div className="event-wrapper flex-centered" onClick={() => setModal(!modal)}>
        <div>
          <h4>{label}</h4>
        </div>
        <span onClick={() => setModal(!modal)}>
          { modal ?
            <ExpandMoreIcon color="primary" fontSize="small" />:
            <ChevronRightIcon color="primary" fontSize="small" />
          }
        </span>
      </div>
      { modal ?
        <div className="assembly-fields-blk">
          {children}
        </div>:null
      }
    </div>
  )
}

export default EventWrapper

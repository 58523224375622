import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Tabs, Tab, Paper, Grid, Tooltip } from '@material-ui/core';
import { ValuationOutPlaceHolder } from '../../Elements'
import { EventWrapper, EventsTab, AirframeEvent, ApuEvent, EngineEvent, EngineHSIEvent, PropellarEvent, LgEvent, HlaIntermOutput, HlaBreadCrumbs, AirframeBasic } from '../components';
import { checkApiStatus, permissionCheckFn } from '../../../utils';
import { getValuationHlaApi , getEngineLLpListApi, updateLLPApi, secondaryllpUpdateApi, updateEngineHsiLogApi, updateEngineLogApi, importLLPApi} from '../apiServices';
import Edit from '@material-ui/icons/Edit';
import { hlaErrorCode } from '..';
import { fieldValidation } from '../../../utils/formValidation';
import { withSnackbar } from 'notistack';
import { globalPutService } from '../../../utils/globalApiServices';
import { PageLoader } from '../../../shared_elements';
class HlaEvents extends Component{
  constructor(props){
    super(props);
    this.state = {
      mode:'edit',
      pageLoader:false,
      airframeIndex:0,
      engineIndex:0,
      propellerIndex:0,
      lgIndex:0,
      halfLifeCrud:{},
      error:{},
      llpModal:false,
      engineLLP:{},
      llpTabIndex:0,
      logDetailCopy:{},
      flag:{
        lg:false,
        engine:false,
        propeller:false
      },
      llpTabName: 'PRIMARY LLP',
    };
    this.getValuationHlaApi = getValuationHlaApi.bind(this);
    this.updateEngineHsiLogApi = updateEngineHsiLogApi.bind(this);
    this.updateEngineLogApi = updateEngineLogApi.bind(this);
  }
  componentDidMount(){
    this.getValuationHlaApi(this.props)
  }
  toggleLLpModal = (engineIndex, component) => {
     
    const { halfLifeCrud } = this.props
    this.setState({llpModal:true})
    // this.getEngineLLpListApi(this.props, halfLifeCrud.engine.engineInfo[engineIndex], engineIndex, component)
}
  handleTabChange = (event, newValue, tabType) => {
    this.setState({[tabType]:newValue});
  }
  changeLLPTabs = (event, newValue, tabLabel) => {
    this.setState(prevState => ({
      ...prevState,
      llpTabIndex: newValue,
      llpTabName: tabLabel
    }))
  }
  onFieldChange = (event, keyParam, value, assembly, objectKey, index) => {

    if(assembly === 'airframe' || assembly === 'engine'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          [assembly]:{
            ...prevState.halfLifeCrud[assembly],
            [objectKey]: prevState.halfLifeCrud[assembly][objectKey].map((item, itemIndex) => itemIndex === index ? {
              ...item,
              [keyParam]:value
            } :item )
          }
        }
      }))
    }
    if(assembly === 'propeller' || assembly === 'landing_gear'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          [assembly]:prevState.halfLifeCrud[assembly].map((item, itemIndex) => index===itemIndex ? {
            ...item,[keyParam]:value
          } :item)
        }
      }))
    }
    if(assembly === 'apu'){
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud:{
          ...prevState.halfLifeCrud,
          apu:{
            ...prevState.halfLifeCrud.apu,
            [keyParam]:value
          }
        }
      }))
    }
  }
  updateEngineField = (event, keyParam, value, engineIndex, prIndex) => {
     
    const { halfLifeCrud } = this.state
    if (keyParam == "last_event_date" && value == halfLifeCrud.airframe.date_of_manufacture) {
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          engine: {
            ...prevState.halfLifeCrud.engine,
            engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
              {
                ...item,
                last_event_date: value,
                tsn_at_lsv: 0,
                csn_at_lsv: 0
              } : item
            )
          }
        }
      }))
    }
    if ((keyParam == 'last_performed_sv' && value)) {
      if (value == "None") {
      } else if (value && value.event) {
        let data = value
        data = {
          ...data,
          name: value.event
        }
        if (value.event == "Mature Run") {
          this.setState(prevState => ({
            ...prevState,
            halfLifeCrud: {
              ...prevState.halfLifeCrud,
              engine: {
                ...prevState.halfLifeCrud.engine,
                engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: data.interval,
                    cost: data.cost,
                  } : item
                )
              }
            }
          }))
        } else {
          this.setState(prevState => ({
            ...prevState,
            halfLifeCrud: {
              ...prevState.halfLifeCrud,
              engine: {
                ...prevState.halfLifeCrud.engine,
                engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ?
                  {
                    ...item,
                    [keyParam]: data,
                    interval_fh: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    // interval_fh_hsi: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].interval,
                    cost: prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].cost,
                    // cost_hsi:prevState.halfLifeCrud.engine.engineInfo[index].engine_prsv[prIndex + 1].cost
                  } : item)
              }
            }
          }))
        }
      }
    } else {
      // if (keyParam == "csn_as_of_llp") {
      //   this.updateLlpCsn(value, engineIndex)
      // }
      this.setState(prevState => ({
        ...prevState,
        halfLifeCrud: {
          ...prevState.halfLifeCrud,
          engine: {
            ...prevState.halfLifeCrud.engine,
            engineInfo: prevState.halfLifeCrud.engine.engineInfo.map((item, index) => index === engineIndex ? { ...item, [keyParam]: value } : item)
          }
        }
      }))
    }
  }
  updateLlpCsn = (value, engineIndex) => {
     
    this.setState(prevState => ({
      ...prevState,
      llpCsnValidationFlag: !value ? true : false,
      engineLLP: {
        ...prevState.engineLLP,
        primary: prevState.engineLLP.primary.map(item => ({ ...item, csn: value, llpCsnValFlag: !value ? true : false })),
        secondary: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.secondary.length ? prevState.engineLLP.secondary.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
        static: prevState.engineLLP && prevState.engineLLP.secondary && prevState.engineLLP.static.length ? prevState.engineLLP.static.map(item => ({ ...item, csn: item.status ? value : item.csn, llpCsnValFlag: !value ? true : false })) : [],
      },
    }))
  }
  updateHlaEvents= ()=>{
    let data = this.state.halfLifeCrud
    let validationInput={}
     
    validationInput={
      ...validationInput,
      airframe:{
        maintenance_events: data.airframe.maintenance_events.map((item,index)=>({
          lsv_date: hlaErrorCode['airframe_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.lsv_date })],
          cost: item?.cost ? '' : "Please enter Cost"
        }))
      },
      engine: data?.engine?.engineInfo.map((item, index)=>({
        last_event_date: hlaErrorCode['last_event_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.last_event_date })],
        tsn_at_lsv: item?.tsn_at_lsv ? '': "Please enter TSN at LSV",
        csn_at_lsv: item?.csn_at_lsv ? '': "Please enter CSN at LSV",
        interval_fh: item?.interval_fh ?'': "Please Enter Interval",
        cost: item?.cost ?'': "Please Enter Cost",
      }))
      ,
      landing_gear: data.landing_gear.map((item, index )=>({
        csn_at_lsv: item?.csn_at_lsv ? '' : 'Please enter CSN at LSV',
        lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.lsv_date })],
      })),  
    }
    if (data?.propeller && Object.keys(data?.propeller).length) {
      validationInput={
        ...validationInput,
        engine: data?.engine?.engineInfo.map((item, index)=>({
          last_event_date: hlaErrorCode['last_event_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item.last_event_date })],
          tsn_at_lsv: item?.tsn_at_lsv ? '': "Please enter TSN at LSV",
          csn_at_lsv: item?.csn_at_lsv ? '': "Please enter CSN at LSV",
          interval_fh: item?.interval_fh ?'': "Please Enter Interval",
          cost: item?.cost ?'': "Please Enter Cost",     
          interval_fh_hsi: item?.interval_fh_hsi ?'': 'Please Enter HSI Interval',
          cost_hsi: item?.cost_hsi ?'': 'Please Enter HSI Cost',
          last_hsi_event_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.last_hsi_event_date })],
        }))
      }
    }
    if (data?.apu && Object.keys(data?.apu).length) {
      validationInput = {
        ...validationInput,
        apu:{
          interval_fh: data?.apu?.interval_fh? '': "Please enter Interval ",
          lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: data?.apu?.lsv_date })],
        }
        }
    }
    
    if (data?.propeller && Object.keys(data?.propeller).length) {
      validationInput = {
        ...validationInput,
        propeller: data?.propeller.map((item, index) => ({
          interval_fh: item?.interval_fh ? '' : "Please enter Interval",
          interval_mo: item?.interval_mo ? '' : 'Please enter Interval',
          cost: item?.cost ? '' : 'Please enter Cost',
          lsv_date: hlaErrorCode['apu_lsv_date'][fieldValidation({ ...hlaErrorCode['apu_lsv_dateObj'], fieldval: item?.lsv_date })],
        }))
      }
    }
    let checkAirframeError = validationInput?.airframe?.maintenance_events.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''})).includes(false) ? false : true
    let checkEngineError = validationInput?.engine.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) ).includes(false) ? false : true
    let checkApuError = data?.apu && Object.keys(data?.apu).length ? Object.keys(validationInput?.apu).every((k) => { return validationInput?.apu[k] === '' }) : true;
    let checkLgError = validationInput?.landing_gear.map((item, index) => Object.keys(item).every((k) => { return item[k] === '' })).includes(false) ? false : true
    let checkPropError = data?.propeller && Object.keys(data?.propeller).length ? validationInput?.propeller.map((item, index) => Object.keys(item).every((k) => { return item[k] === '' })).includes(false) ? false : true : true

    if ([checkApuError, checkEngineError, checkLgError, checkAirframeError, checkPropError].includes(false) && (this.state.handleClick != 1)) {
      this.setState({ error: validationInput, handleClick: 1 })
      this.props.enqueueSnackbar('Some mandatory Shopvisit data is missing, if you wish to update them later then Click on Save again.', { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
      if (checkAirframeError == false) {
        let errorIndex = validationInput?.airframe?.maintenance_events.map((item, index) => Object.keys(item).every((k) => { return item[k] === '' }))
        this.handleTabChange('event', errorIndex.indexOf(false), 'airframeIndex')
      }
      if (checkEngineError == false) {
        let errorIndex = validationInput?.engine.map((item, index) => Object.keys(item).every((k) => { return item[k] === '' }))
        this.setState(prevState => ({ ...prevState, flag: { ...prevState.flag, engine: true } }))
        this.handleTabChange('event', errorIndex.indexOf(false), 'engineIndex')
      }
      if (checkPropError == false) {
        let errorIndex = validationInput?.propeller.map((item, index) => Object.keys(item).every((k) => { return item[k] === '' }))
        this.setState(prevState => ({ ...prevState, flag: { ...prevState.flag, propeller: true } }))
        this.handleTabChange('event', errorIndex.indexOf(false), 'propellerIndex')  
      }
      if(checkLgError == false){
        let errorIndex =validationInput?.landing_gear.map((item,index)=>  Object.keys(item).every((k)=> {return item[k] ===''}) )
        this.setState(prevState=>({...prevState,flag:{...prevState.flag, lg:true }}))
      this.handleTabChange('event', errorIndex.indexOf(false), 'lgIndex')  
      }
      if(checkApuError == false){
        this.setState(prevState=>({...prevState,flag:{...prevState.flag, apu:true }}))
      }
    }
    else{
      let payload = Object.assign({},data)
      payload={
        ...payload,
        engine:payload?.engine?.engineInfo 
      }
      globalPutService(`valuation/v2/half-life-adjustment/${this.props.match.params.slug}/`,payload)
      .then(response=>{
        if(checkApiStatus(response)){
          this.props.history.push(`/hla/output/${this.props.match.params.id}`)
          this.props.enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
        else{
          this.props.enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      })
    }

  }
  updateErrorField = (module, key, tabIndex) => {
    const {error}= this.state
    if(Object.keys(error).length){
    if(module == 'engine'){
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          engine:{
            ...prevState.error.engine,
            [tabIndex]:{
              ...prevState.error.engine[tabIndex],
              [key]:''
            }
          }
        }
      }))
    }
    else if(module == 'airframe'){
        this.setState(prevState=>({
          ...prevState,
          error:{
            ...prevState.error,
            airframe:{
              ...prevState.error.airframe,
              maintenance_events:{
                ...prevState.error.airframe.maintenance_events,
                [tabIndex]:{
                  ...prevState.error.airframe.maintenance_events[tabIndex],
                  [key]:''
                }
              }
            }
          }
        }))
    }
    else if(module == 'propeller'){
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          propeller:{
            ...prevState.error.propeller,
            [tabIndex]:{
              ...prevState.error.propeller[tabIndex],
              [key]:''
            }
          }
        }
      }))
    }
    else if(module == 'landing_gear'){
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          landing_gear:{
            ...prevState.error.landing_gear,
            [tabIndex]:{
              ...prevState.error.landing_gear[tabIndex],
              [key]:''
            }
          }
        }
      }))
    }
    else{
      this.setState(prevState=>({
        ...prevState,
        error:{
          ...prevState.error,
          apu:{
            ...prevState.error.apu,
            [key]:''
          }
        }
      }))
    }
    }
  }
  render(){
    const {llpStaticValidationFlag, llpCsnValidationFlag, logDetailCopy, llpSecondaryValidationFlag, pageLoader, mode, airframeIndex, engineIndex, propellerIndex, lgIndex, halfLifeCrud, error, engineLLP, llpTabName, llpTabIndex, files, flag } = this.state;
    if(permissionCheckFn({secondaryKey:'hthl', keyIndex:'C'}) || permissionCheckFn({secondaryKey:'hthl', keyIndex:'U'})){
      return(
        <section className="valuation-app-section">
          <HlaBreadCrumbs
            logResult={halfLifeCrud}
            changeLogStatus={() => console.log('Lck')}
            mode={'add'}
            exportValuationLog={()=>console.log('Export')}
          />
          <div className="hla-section">
            <Grid container spacing={1}>
              <Grid item md={8} xs={12}>
                <Paper elevation={1} className="assembly-sections" style={{paddingTop:"10px"}}>
                  <div style={{ textAlign: 'right', marginBottom:'10px' }}>
                    {
                      mode == 'edit' ?
                        <>
                          <Button style={{ marginRight: "10px" }} variant='outlined' color='primary' size='small' onClick={() => this.setState({ mode: 'view', error:{} })} > Cancel </Button>
                          <Button variant='contained' color='primary' size='small' onClick={() => this.updateHlaEvents()} > Save </Button>
                        </> :
                        <Button variant='contained' color='primary' size='small' onClick={() => this.setState({ mode: 'edit', error:{} })} > Edit </Button>
                    }
                  </div>
                  { halfLifeCrud.airframe && Object.keys(halfLifeCrud.airframe).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Airframe Details" flag={true}>
                        <EventsTab
                          tabIndex={airframeIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'airframeIndex')}
                          tabs={halfLifeCrud.airframe.maintenance_events.map(item => `${item.event} Year Check`)}
                        />
                        {halfLifeCrud.airframe.maintenance_events.map((mntProgram, index) =>
                          index === airframeIndex ?
                          <Grid container spacing={1}>
                            <AirframeEvent
                              item={mntProgram}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={halfLifeCrud}
                              error={error?.airframe?.maintenance_events[index]}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('airframe', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.engine && Object.keys(halfLifeCrud.engine).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Engine Details" flag={flag?.engine}>
                        <EventsTab
                          tabIndex={engineIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'engineIndex')}
                          tabs={halfLifeCrud.engine.engineInfo.map((engine, index) => `Engine #${index+1}`)}
                        />

                        { halfLifeCrud.engine.engineInfo.map((item, index) =>
                          index === engineIndex ?
                          <Fragment>
                            { item.engine_hsi && halfLifeCrud.airframe.aircraftType.has_propeller ?
                                <Grid container spacing={1}>
                                  <EngineHSIEvent
                                    updateEngineLog={() => this.updateEngineHsiLogApi(this.props, halfLifeCrud?.engine, halfLifeCrud?.airframe.date_of_manufacture)}
                                    updateLLP={(data) => this.updateLLPApi(this.props, data, engineLLP, halfLifeCrud)}
                                    llpStaticValidationFlag={llpStaticValidationFlag}
                                    llpCsnValidationFlag={llpCsnValidationFlag}
                                    llpSecondaryValidationFlag={llpSecondaryValidationFlag}
                                    closeLLLpModal={() => this.setState({ llpModal: false, halfLifeCrud: logDetailCopy, engineLLP: { primary: [], secondary: [], static: [] }, checkedA: false, checkedB: false })}
                                    onChangeLLP={this.onChangeLLP}
                                    pageLoader={pageLoader}
                                    changeLLPTabs={this.changeLLPTabs}
                                    llpTabName={llpTabName}
                                    llpTabIndex={llpTabIndex}
                                    engineLLP={engineLLP}
                                    tabIndex={engineIndex}
                                    llpModal={this.state.llpModal}
                                    toggleLLpModal={this.toggleLLpModal}
                                    item={item}
                                    itemIndex={index}
                                    mode={mode}
                                    permissionFlag={true}
                                    crudData={{}}
                                    error={error?.engine && error?.engine[index]}
                                    onFieldChange={this.onFieldChange}
                                    updateErrorField={(key)=>this.updateErrorField('engine', key, index)}
                                  />
                                </Grid> : null
                            }
                              <Grid container spacing={1}>
                                <EngineEvent
                                  updateEngineField={this.updateEngineField}
                                  props={this.props}
                                  // updateLLP={(data) => this.updateLLPApi(this.props, data, engineLLP, halfLifeCrud)}
                                  closeLLLpModal={() => this.setState({ llpModal: false, engineLLP: { primary: [], secondary: [], static: [] }, checkedA: false, checkedB: false })}
                                  pageLoader={pageLoader}
                                  tabIndex={engineIndex}
                                  toggleLLpModal={this.toggleLLpModal}
                                  item={item}
                                  itemIndex={index}
                                  mode={mode}
                                  llpModal={this.state.llpModal}
                                  permissionFlag={true}
                                  // importLLP={(engine, engineIndex, props, component) => this.importLLPApi(files, engine, engineIndex, this.props, component)}
                                  crudData={halfLifeCrud}
                                  error={error?.engine && error?.engine[index]}
                                  onFieldChange={this.onFieldChange}
                                  updateErrorField={(key)=>this.updateErrorField('engine', key, index)}
                                  updateEngineLog={() => this.updateEngineLogApi(this.props, halfLifeCrud?.engine, halfLifeCrud?.airframe?.date_of_manufacture)}
                                />
                              </Grid>
                          </Fragment>
                          :null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.propeller && Object.keys(halfLifeCrud.propeller).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Propellar Details" flag={flag?.propeller}>
                        <EventsTab
                          tabIndex={propellerIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'propellerIndex')}
                          tabs={halfLifeCrud.propeller.map((propeller) => `Propellar #${propeller.position}`)}
                        />
                        { halfLifeCrud.propeller.map((item, index) =>
                          propellerIndex === index ?
                          <Grid container spacing={1}>
                            <PropellarEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={{}}
                              error={error?.propeller && error?.propeller[index]}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('propeller', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.apu && Object.keys(halfLifeCrud.apu).length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="APU Details" flag={flag?.apu}>
                        <Grid container spacing={1}>
                          <ApuEvent
                            item={halfLifeCrud.apu}
                            mode={mode}
                            permissionFlag={true}
                            crudData={{}}
                            error={error?.apu}
                            onFieldChange={this.onFieldChange}
                            updateErrorField={(key)=>this.updateErrorField('apu', key)}
                          />
                        </Grid>
                      </EventWrapper>
                    </Paper>:null
                  }
                  { halfLifeCrud.landing_gear && halfLifeCrud.landing_gear.length ?
                    <Paper elevation={2} className="assembly-card">
                      <EventWrapper label="Landing Gear Details" flag={flag?.lg}>
                        <EventsTab
                          tabIndex={lgIndex}
                          handleTabChange={(event, newValue) => this.handleTabChange(event, newValue, 'lgIndex')}
                          tabs={halfLifeCrud.landing_gear.map((item) => item.position == 1 ? 'NLG' : item.position == 2 ? "LH MLG" : item.position == 3 ? "RH MLG" : "")}
                        />
                        { halfLifeCrud.landing_gear.map((item, index) =>
                          lgIndex === index ?
                          <Grid container spacing={1}>
                            <LgEvent
                              item={item}
                              itemIndex={index}
                              mode={mode}
                              permissionFlag={true}
                              crudData={{}}
                              error={error?.landing_gear && error?.landing_gear[index]}
                              onFieldChange={this.onFieldChange}
                              updateErrorField={(key)=>this.updateErrorField('landing_gear', key, index)}
                            />
                          </Grid>:null
                        )}
                      </EventWrapper>
                    </Paper>:null
                  }

                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                { Object.keys(halfLifeCrud).length ? <HlaIntermOutput halfLifeCrud={halfLifeCrud} />:null}

              </Grid>
            </Grid>
          </div>
          {pageLoader ? <PageLoader/>:null}
        </section>
      )
    }else{
      return <Redirect to={`/hla/output/${this.props.match.params.id}`} />
    }
  }
}
export default withSnackbar(HlaEvents);

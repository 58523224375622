import React, { Component } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { STableLoader } from '../../../shared_elements/loaders';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { getEngineTypesAc } from '../../../shared_elements/actionCreators';
import { valuationFilterOps } from '../';
import { trackActivity } from '../../../utils/mixpanel';
import { Typography, Button } from '@material-ui/core';
import { ValuationLog, AddUtilsBtn } from '../components';
import { TableListComp, EmptyCollection, FilterUIComp, PageLoader, DeploymentMessage } from '../../../shared_elements';
import { checkApiStatus, removeEmptyKey, convertFilterObject, getGlobalCookie, permissionCheckFn } from '../../../utils';
import { getValuationLogsApi, getValuationAircraftsApi, exportLogListApi, updateLockApi, getLogsSummaryApi } from '../apiServices';
// import DeploymentMessage from '../../../shared_elements/components/DeploymentMessage';
class ValuationLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackDialog: false,
      skeletonLoader: true,
      videoDialog: false,
      pageLoader: false,
      aircraftTypes: [],
      utilityTypes: [{ "id": 1, "name": "BV and CMV" }, { "id": 2, "name": "Future Base Value" }, { "id": 5, "name": "Base Lease Rates" }, { "id": 4, "name": "Lease Encumbered Value (LEV)" }, { "id": 3, "name": "Maint. Adj Base Value" }],
      rentalTypes: [{ "id": 1, "name": "Fixed" }, { "id": 2, "name": "Stepped" }, { "id": 3, "name": "Quarterly" }],
      valuationLogs: {
        logs: [],
        pagination: {}
      },
      filter: {},
      applyingFilter: {},
      logsSummaryDetail: {},
      sort: 'id',
      sort_by: 'desc',
      showRentalType: false,
      showFutureValue: false,
      checkboxValue: false,
      showDropdownRental: true,
    }
    this.getValuationLogsApi = getValuationLogsApi.bind(this);
    this.getValuationAircraftsApi = getValuationAircraftsApi.bind(this);
    this.exportLogListApi = exportLogListApi.bind(this);
    this.updateLockApi = updateLockApi.bind(this);
    this.getLogsSummaryApi = getLogsSummaryApi.bind(this)
  }
  componentDidMount() {
    this.getValuationLogsApi(this.props, { sort: 'id', sort_by: 'desc' }, 'skeletonLoader');
    this.getLogsSummaryApi()
    this.props.getEngineTypes()
    this.getValuationAircraftsApi()
      .then(response => {
        if (checkApiStatus(response)) {
          this.setState({ aircraftTypes: response.data.data.aircraftTypes });
        }
      })
    trackActivity('Page Visited', {
      page_title: 'Logs'
    })
    if (getGlobalCookie('promoVideo') === undefined || getGlobalCookie('promoVideo') === null) {
      this.setState({ videoDialog: true })
    }
  }


  removeFilter = (keyParam) => {
    const { filter, applyingFilter, valuationLogs, sort, sort_by } = this.state;
    let query = Object.assign({}, filter);
    delete query[keyParam];
    if (keyParam == "utility") {
      delete query['forecast']
      delete query['lev_rental_type']
    }
    this.getValuationLogsApi(this.props, removeEmptyKey({ ...query, sort: sort, sort_by: sort_by, per_page: valuationLogs.pagination.per_page }), 'pageLoader');
    this.setState({ applyingFilter: { ...applyingFilter, [keyParam]: null }, })
    if (keyParam == "utility") {
      this.setState({
        checkboxValue: false,
        showDropdownRental: false,
      })

    }
  }
  applyFilter = (keyParam, value) => {
    if (keyParam == "utility") {
      if (value.length) {
        if (value.find(item => item.name == 'Lease Encumbered Value')) {
          this.setState({ showRentalType: true, showDropdownRental: true })
          if (value.find(item => item.name == 'Maintenance Adjusted Base Value')) {
            this.setState({ showFutureValue: true })
          } else {
            this.setState({ showFutureValue: false })
          }
        } else if (value.find(item => item.name == 'Maintenance Adjusted Base Value')) {
          this.setState({ showFutureValue: true })
          if (value.find(item => item.name == 'Lease Encumbered Value')) {
            this.setState({ showRentalType: true, showDropdownRental: true })
          } else {
            this.setState({ showRentalType: false, showDropdownRental: false })
          }
        } else {
          this.setState(prevState => ({
            ...prevState,
            showRentalType: false,
            showFutureValue: false,
            applyingFilter: {
              ...prevState.applyingFilter,
              lev_rental_type: null,
            }
          }))
        }
      } else {
        this.setState(prevState => ({
          ...prevState,
          showRentalType: false,
          showFutureValue: false,
          applyingFilter: {
            ...prevState.applyingFilter,
            lev_rental_type: []
          }
        }))
      }
    }
    this.setState(prevState => ({
      ...prevState,
      applyingFilter: {
        ...prevState.applyingFilter,
        [keyParam]: value
      }
    }))

  }
  applyCheckbox = (value) => {
    this.setState(prevState => ({
      ...prevState,
      checkboxValue: value,
      applyingFilter: {
        ...prevState.applyingFilter,
        forecast: value
      }
    }))
  }
  createSortHandler = (sortField) => {
    const { sort, sort_by, filter, valuationLogs } = this.state;
    this.setState({
      sort: sortField,
      sort_by: (sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    });
    if (sortField === sort) {
      if (sort_by === 'asc') {
        this.getValuationLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'desc', per_page: valuationLogs.pagination.per_page }, 'pageLoader');
      } else {
        this.getValuationLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page: valuationLogs.pagination.per_page }, 'pageLoader');
      }
    } else {
      this.getValuationLogsApi(this.props, { ...filter, sort: sortField, sort_by: 'asc', per_page: valuationLogs.pagination.per_page }, 'pageLoader');
    }
  }
  render() {
    const { aircraftTypes, filter, applyingFilter, sort, sort_by, valuationLogs, skeletonLoader, pageLoader, utilityTypes, rentalTypes, showRentalType, showFutureValue, checkboxValue, showDropdownRental, logsSummaryDetail } = this.state;
    const { engineTypes } = this.props;
    let filterOptions = valuationFilterOps;
    filterOptions = {
      ...filterOptions,
      aircraft_type: {
        ...filterOptions.aircraft_type,
        options: aircraftTypes
      },
      engine_type: {
        ...filterOptions.engine_type,
        options: engineTypes
      },
      utility: {
        ...filterOptions.utility,
        options: utilityTypes
      }
    }
    if (showRentalType) {
      filterOptions = {
        ...filterOptions,
        lev_rental_type: {
          inputType: 'dropdown',
          placeholder: 'Select Rental Type',
          options: rentalTypes,
          labelKey: 'name',
          valueKey: 'id',
          title: 'Rental Type',
          multiple: true,
          showDropdown: showDropdownRental
        },
      }
    }
    if (showFutureValue) {
      filterOptions = {
        ...filterOptions,
        forecast: {
          inputType: 'checkbox',
          placeholder: 'Future Value',
          title: 'Future Maintenance Adjusted Base Value',
          labelKey: true,
          showFutureValue: showFutureValue
        }
      }
    }
    return (
      <div className="valuation-sections">
        <DeploymentMessage />
        <div className="flex-centered" style={{ marginBottom: '15px' }}>
          <div className="page-description">
            <Typography variant="h2" className="page-title">Logs</Typography>
            {!isMobile ? <p className="page-para">Offers a list view of all Logs created for Valuation in SPARTA.</p> : null}
          </div>
          <span style={{ marginLeft: 'auto' }}>
            {skeletonLoader ? <Skeleton variant="rect" width={140} height={36} style={{ marginTop: '45px' }} /> :
              permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'future_value', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'hthl', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'lev', keyIndex: 'C' }) ||
                permissionCheckFn({ secondaryKey: 'blr_mlr', keyIndex: 'C' }) ?
                <AddUtilsBtn props={this.props} /> : null
            }
          </span>
          <span style={{ marginLeft: '10px' }}>
            {skeletonLoader ? <Skeleton variant="rect" width={140} height={36} style={{ marginTop: '45px' }} /> :
              permissionCheckFn({ secondaryKey: 'base_value', keyIndex: 'EXP_PDF' }) ?
                <Button onClick={() => this.exportLogListApi(this.props, { extension: 'xlsx' })} color="primary" variant="outlined"><SaveAltIcon size="small" /> Export</Button> : null
            }
          </span>
        </div>
        {skeletonLoader ? <STableLoader count={5} /> :
          <div style={{ marginBottom: '30px' }}>
            <FilterUIComp
              filter={filter}
              applyingFilter={applyingFilter}
              removeFilter={this.removeFilter}
              removeAllFilter={() => { this.setState({ applyingFilter: {} }); this.getValuationLogsApi(this.props, removeEmptyKey({ per_page: valuationLogs.pagination.per_page, sort: sort, sort_by: sort_by }), 'pageLoader') }}
              submitFilter={() => this.getValuationLogsApi(this.props, { ...convertFilterObject(removeEmptyKey(applyingFilter), filterOptions) }, 'pageLoader')}
              applyFilter={this.applyFilter}
              filterMenu={filterOptions}
              levRentalType={utilityTypes && utilityTypes[3].id == 4}
              applyCheckbox={this.applyCheckbox}
              checkboxValue={checkboxValue}
            />
            <TableListComp
              sort={sort}
              sort_by={sort_by}
              heads={[
                { id: 'id', label: 'Asset / Log ID', sortOption: false },
                { id: "serial_number", label: 'Serial Number', sortOption: true },
                { id: 'aircraft_type', label: 'Aircraft Type', sortOption: true },
                { label: 'Date Of Manufacture', sortOption: false },
                { id: 'engine_type', label: 'Engine Type', sortOption: true },
                { label: 'Max Take Off Weight', sortOption: false },
                { label: 'Created By', sortOption: false },
                { label: 'Asset Value Options', sortOption: false },
                { label: 'Lock Status', sortOption: false }
              ]}
              data={valuationLogs.logs.map((item, index) =>
                <ValuationLog
                  key={index}
                  item={item}
                  history={this.props.history}
                  updateLockfn={this.updateLockApi}
                  props={this.props}
                />
              )}
              noRecord={valuationLogs.logs.length ? null :
                <EmptyCollection
                  title="No records found"
                />
              }
              pagination={valuationLogs.pagination}
              onChangePage={(event, newPage) => this.getValuationLogsApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: newPage + 1, per_page: valuationLogs.pagination.per_page }), 'pageLoader')}
              onChangeRowsPerPage={(event) => this.getValuationLogsApi(this.props, removeEmptyKey({ ...filter, sort: sort, sort_by: sort_by, page: 1, per_page: event.target.value }), 'pageLoader')}
              createSortHandler={this.createSortHandler}
            />
          </div>
        }
        {pageLoader ? <PageLoader /> : null}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  engineTypes: state.shareReducer.engineTypes,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getEngineTypes: (query) => dispatch(getEngineTypesAc(query)),
  }
}
export default withSnackbar(connect(mapStateToProps, mapDispatchToProps)(ValuationLogs))
